import React from 'react';
import { DragDropContext, Droppable } from "react-beautiful-dnd"
import TableBodyBase from '@material-ui/core/TableBody';

const DroppableComponent = (onDragEnd) => (props) =>
{
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={'1'} direction="vertical">
        {(provided) => {
          return (
            <TableBodyBase ref={provided.innerRef} {...provided.droppableProps} {...props}>
                {props.children}
                {provided.placeholder}
            </TableBodyBase>
          )
        }}
      </Droppable>
    </DragDropContext>
  )
}

export default function TableBody({ reorder = false, onOrderChanged = () => {}, ...props }) {
  if (reorder) {
    return (
      <TableBodyBase component={DroppableComponent(onOrderChanged)} {...props} />
    )
  }
  return <TableBodyBase {...props} />
}