import keyMirror from 'keymirror';

export default {
	REDUCER : {
		...keyMirror({
			SET_AUTH_DATA: true,
			SET_USER: true,
			SET_USER_GROUPS: true,

			SET_CURRENT_USER: true,

			// SHOP ADMIN
			SET_CURRENT_SHOP: true,
			CLEAR_CURRENT_SHOP: true,

			// DISPLAY
			DISPLAY_LOGIN: true,
			DISPLAY_LOADING: true,
			DISPLAY_ERROR: true,
			DISPLAY_MOBILE_MENU: true,


			CONTEXT_SET_TEAM: true,
			CONTEXT_CLEAR_TEAM: true

		})
	}
}