

import ADMIN from '../Apps/Admin/config'

const HOSTNAMES_TO_CONFIG_NAME = {
    // LOCAL
    'localhost': 'ADMIN',
    'admin.i-wantit-dev.com': 'ADMIN',
    'shop-admin.i-wantit-dev.com': 'ADMIN',
}


export const APP_CONFIGS = {
    ADMIN
}


export const getAppNameFromHost = () => {
    const { hostname } = window.location;

    return HOSTNAMES_TO_CONFIG_NAME[hostname] || HOSTNAMES_TO_CONFIG_NAME['localhost'];
}