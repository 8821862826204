import React, { useState } from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import Table from 'Apps/Admin/Components/ProductList';

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import ProductSearchForm from 'Apps/Admin/Components/SearchForms/Products'
import { useCurrentShop } from "AppCore/react-hooks/useCurrentShop";
import useAdminType, { TYPE_ADMIN_SHOP } from "AppCore/react-hooks/useAdminType";

const CORE_PRODUCTS_LIST_FIELDS = gql`
    fragment CoreProductsListFields on ProductList{
        pageInfo {
            hasNextPage
            endCursor
        }
        edges {
            cursor
            node {
                id
                title
                image
                market_url
                market_id
                master_category
                wantsCount
                price
                status
                category {key, names}
            }
        }
    }
    `

const GET_ALL_PRODUCTS = gql`
    ${CORE_PRODUCTS_LIST_FIELDS}
    query getProducts($after: String, $first: Int, $master_category: String, $shop_uuid: String) {
        products(after: $after, first: $first, master_category: $master_category, shop_uuid: $shop_uuid) {
            ...CoreProductsListFields
        }
    }
`;

const SEARCH_PRODUCTS = gql`
    ${CORE_PRODUCTS_LIST_FIELDS}
    query searchProducts($after: String, $first: Int, $query: String!) {
        products: searchProducts(after: $after, first: $first,query: $query) {
            ...CoreProductsListFields
        }
    }
`;

const ProductList = ({
    data, columns, actions, fetchMore
}) => {

    if(!data){
        return <p>Waiting for data...</p>
    }

    const { products }  = data;

    const {
        pageInfo,
        edges
    } = products ;

    return (
        <>
            <Divider my={6} />

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={edges.map(edge => edge.node)}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            {pageInfo.hasNextPage && 
                <Button onClick={() => {
                    fetchMore({
                        variables: {
                            after: pageInfo.endCursor,
                            first: 100
                        }
                    })
                }}>Load More</Button>
            }

        </>
    )
}

export default function ProductView({
    actions = [],
    columns = ['image', 'id', 'title', 'market_url', 'category']
}) {

    const adminType = useAdminType()
    const { currentShop } = useCurrentShop()

    const [queryField, setQueryField] = useState(null)

    let QUERY = queryField  ? SEARCH_PRODUCTS : GET_ALL_PRODUCTS

    const {
        loading,
        data,
        fetchMore
    } = useQuery(QUERY, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: "cache-first",
        variables: {
            after: "",
            first: 100,
            ...(queryField ? {query: queryField}: {}),
            ...(
                adminType === TYPE_ADMIN_SHOP ? {
                    shop_uuid: currentShop.uuid
                } : {}
            )
        },
    });

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <ProductSearchForm
                onChangeSearchQuery={console.log}
                onSubmit={setQueryField}
                onUse={()=>{}}
                loading={loading} />
            
            {loading ? <div>Loading...</div> : (
                <ProductList
                    data={data}
                    columns={columns}
                    actions={actions}
                    fetchMore={fetchMore}
                />
            )}
            

        </div>
    );
}