import React from 'react';
import { Draggable } from "react-beautiful-dnd"
import TableRowBase from '@material-ui/core/TableRow';

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
      width: '100%',
      background: "rgb(235,235,235)"
  })
})

const DraggableComponent = (id, index) => ({ style, ...props}) => {
  return (
      <Draggable draggableId={String(id)} index={index}>
          {(provided, snapshot) => (
              <TableRowBase
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  style={{
                    ...getItemStyle(snapshot.isDragging, provided.draggableProps.style),
                    width: '100%'
                  }}

                  {...props}
              >
                  {props.children}
              </TableRowBase>
          )}
      </Draggable>
  )
}

export default function TableRow({ reorder = false, index, elementKey, ...props }) {
  if (reorder) {
    return (
      <TableRowBase component={DraggableComponent(elementKey, index)} {...props}/>
    )
  }
  return <TableRowBase {...props} />
}