import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import Table from 'Apps/Admin/Components/ProductsListList';

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import SearchForm from 'Apps/Admin/Components/SearchForms/ProductsLists'

const GET_ALL_PRODUCTSLISTS = gql`
        query productsLists($after: String, $first: Int, $shop_uuid: String) {
          productsLists(after: $after, first: $first, shop_uuid: $shop_uuid) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    cursor
                    node {
                      id,
                      uuid, title, image,
                      position_in_shop,
                      product_ids,
                      products {
                        id, title
                      }
                    }
                }
            }
        }
    `;

const List = ({
    filterIds = [],
    data, columns, actions, fetchMore
}) => {

    const {
      productsLists
    } = data;

    const {
        pageInfo,
        edges
    } = productsLists

    return (
        <>
            <Divider my={6} />

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={edges.map(edge => edge.node).filter(el => {
                            return filterIds.indexOf(el.id) < 0;
                        })}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            <Button onClick={() => {
                fetchMore({
                    variables: {
                        after: pageInfo.endCursor,
                        first: 100
                    }
                })
            }}>Load More</Button>
        </>
    )
}

export default function ProductView({
    filterIds = [],
    searchFormData = {},
    actions = [],
    columns = ['image', 'id', 'title', 'market_url', 'category']
}) {

    const {
        loading,
        data,
        fetchMore
    } = useQuery(GET_ALL_PRODUCTSLISTS, {
        nextFetchPolicy: "cache-first",
        fetchPolicy: 'cache-and-network',
        variables: {
            after: "",
            first: 100
        },
    });

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <SearchForm
                searchFormData={searchFormData}
                onChangeSearchQuery={console.log}
                onChangeForm={console.log}
                loading={loading}
            />
            
            {loading ? <div>Loading...</div> : (
                <List
                    data={data}
                    filterIds={filterIds}
                    columns={columns}
                    actions={actions}
                    fetchMore={fetchMore}
                />
            )}
            

        </div>
    );
}