export class ModelObject {
  setRawData(data: any) {
    const objectProperties = Object.getOwnPropertyNames(this);
    Object.keys(data).forEach(key => {

      if (key === 'object') {
        return true;
      }

      if (objectProperties.indexOf(key) !== -1) {
        Object.assign(this, {[key]: data[key]});
      }
    })
  }
}