import Constants from "../../Constants";

const { REDUCER: C } = Constants;

export default (state = null, action) => {
    switch (action.type) {

        case C.SET_CURRENT_SHOP:
            return {
                ...state,
                shop: action.shop
            };
        case C.CLEAR_CURRENT_SHOP:
            return null;
        default:
            return state;
    }
}