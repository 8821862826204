import React from "react";
import { Link } from 'react-router-dom'

import DataTable from 'AppCore/Components/DataTable';

export default function ShopsOffersList({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = () => {}
}: any) {

    console.log(actions)

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: 'id', key: 'id' },
                { title: 'Shop uuid', key: 'uuid' },
                { title: 'Seller', key: 'seller' }
            ],
            actionsLabel: "",
            actions: actions,
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(item: any, attr_name: string) => {

            if (attr_name === 'key') {
                // return (
                //     <Link to={`/flexible-page-system/page/${item.key}`}>{item.key}</Link>
                // )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}