import React, { FormEvent } from 'react';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import useForm from 'AppCore/Components/Form/useForm';
import { useNavigate } from 'react-router-dom';

export default function InfosForm({
  update,
  productsList
}: {
  update: (data: any) => Promise<any>,
  productsList: any
}) {

  const navigate = useNavigate();

  const {
    getMaterialFieldProps,
    getData: getFormData,
    errors,
    hasError,
    hasErrorAttr,
    getErrorMessagesAttr,
    getErrorMessages
  } = useForm({
    config: {
      title: {
        type: 'text',
        validators: ['not_empty']
      }
    },
    initialData: {
      title: productsList.title
    }
  });

  if (hasError()) {
    console.log(Object.keys(errors).map((attr_name: string) => {
      if (!errors[attr_name].hasError) {
        return "";
      }
      return getErrorMessages(errors[attr_name].errors)
    }));
  }

  const onSubmitGeneral = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const renderError = (attr_name: string) => {
    return hasErrorAttr(attr_name) ? (
      <Alert severity="error">{getErrorMessagesAttr(attr_name).map(error_message => (
        <div key={error_message}>{error_message}</div>
      ))}</Alert>
    ) : "";
  }

  const onClickUpdate = async () => {
    const data = getFormData();
    console.log("UPDATE PRODUCTSLIST", data);
    await update(data);
    navigate('/product-lists')
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      subheader="Informations sur la liste"
      title="Général"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={onClickUpdate}
        >
          Sauvegarder
        </Button>
      )}
    >
      <form onSubmit={onSubmitGeneral}>
      {renderError('title')}
      <TextField
        fullWidth
        label="Nom de la liste"
        margin="normal"
        variant="outlined"
        {...getMaterialFieldProps('title')}
      />
      </form>
    </CardSection>
  )
}