import React from "react";
import { Link } from 'react-router-dom'
import { Image as ImageIcon } from '@material-ui/icons'

import DataTable from 'AppCore/Components/DataTable';
import Image from 'AppCore/Components/FirebaseImage'
import styled from "styled-components";
const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`

export default ({
    data = [],
    columns
}) => {

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: '', key: 'image', width: 90 },
                { title: 'User', key: 'user' },
                { title: 'Product', key: 'product' },
                { title: 'URL', key: 'link' },
                { title: 'Prix', key: 'price' }
            ],
        }}
        getCell={(item, attr_name) => {

            if (attr_name === "image") {
                return (
                    <ImageContainer>
                        {!item.product.image &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.product.image &&
                            <Image storageRef={item.product.image} style={{width: '100%'}} />
                        }
                    </ImageContainer>
                )
            }

            if (attr_name === 'user') {
                return item.user ? <Link to={`/user/${item.user.id}`}>{item.user.full_name || item.user.email}</Link> : ''
            }

            if (attr_name === 'product') {
                return item.product ? <Link to={`/product/${item.product.id}`}>{item.product.title || `product ${item.product.id}`}</Link> : ''
            }

            if (attr_name === 'link') {
                return (
                    <a href={item.product.market_url} rel="noopener noreferrer" target="_blank">{item.product.market_url}</a>
                )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}