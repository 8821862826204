import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import { Image as ImageIcon } from '@material-ui/icons'
import { Chip } from "@material-ui/core";

import DataTable from 'AppCore/Components/DataTable';
import Image from "AppCore/Components/Image";

const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`



export default ({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = (result) => {}
}) => {

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: 'Wants (période)', key: 'customWantsCount' },
                { title: '', key: 'image', width: 90 },
                { title: 'id', key: 'id' },
                { title: 'Titre', key: 'title' },
                { title: 'Wants', key: 'wantsCount' },
                { title: 'Prix (€)', key: 'price' },
                { title: 'Market id', key: 'market_id' },
                { title: 'URL', key: 'market_url' },
                { title: 'Marque', key: 'brand' },
                { title: 'Catégorie', key: 'category' },
                { title: 'Etat', key:'status'},
                { title: 'Groupe', key: 'product_group' }
            ],
            actionsLabel: "",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ],
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'id') {
                return (
                    <Link to={`/product/${item.id}`}>{item.id}</Link>
                )
            }

            if (attr_name === 'category') {
                const cat_names = JSON.parse(item.category?.names || "{}");
                return cat_names.fr || cat_names.en || item.category?.key || "";
            }

            if (attr_name === "image") {
                return (
                    <ImageContainer>
                        {!item.image &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.image &&
                            <Image
                                filename={item.image}
                                style={{width: '100%'}}
                            />
                        }
                    </ImageContainer>
                )
            }

            if (attr_name === 'market_url') {
                let url = {}
                try {
                    url = new URL(item?.market_url)
                } catch(e) {
                    console.error("Invalid url", e)
                    url["hostname"] = 'Undefined'
                }
                return (
                    <a href={item.market_url} rel="noopener noreferrer" target="_blank">{url?.hostname}</a>
                )
            }

            if (attr_name === 'status'){
                if(item.status === 'DRAFT'){
                    return <Chip label="Brouillon" size="small" color="primary"/>
                }
                if(item.status === 'PUBLISHED'){
                    return <Chip label="Publié" size="small" color="secondary" />
                }
                if(item.status === 'ARCHIVED'){
                    return <Chip label="Archivé" size="small" />
                }  
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}