import { gql, useQuery, useMutation } from '@apollo/client';

const GET_ALL_CATS = filter => gql`
        query getCategories($after: String, $first: Int) {
            categories(after: $after, first: $first) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    cursor
                    node {
                        ${filter}
                    }
                }
            }
        }
    `;

const GET_CATEGORY = gql`
        query getCategory($key: String) {
            category(key: $key) {
                key,
                names,
                image,
                color
            }
        }
    `;

const UPDATE_CATEGORY = gql`
  mutation UpdateCategory($key: String!, $category: CategoryUpdate!) {
    updateCategory(key: $key, category: $category) {
      key,
      names,
      image
    }
  }
`;


const CREATE_CATEGORY = gql`
  mutation CreateCategory($category: CategoryCreate!) {
    createCategory(category: $category) {
      key,
      names
    }
  }
`;

export const useGetCategory = (key) => {
    const {
        loading,
        data: { category = {} } = {}
    } = useQuery(GET_CATEGORY, {
        variables: {
            key
        },
    });

    return { loading, category };
}

export const useCreateCategory = () => {
    const [createCategory] = useMutation(CREATE_CATEGORY, {
        awaitRefetchQueries: true,
        refetchQueries: () => ([
            {
                query: GET_ALL_CATS`key, names, image`,
                variables: {
                    after: "0",
                    first: 1000
                }
            }
        ])
    });

    return createCategory;
}

export const useUpdateCategory = () => {

    const [updateCategory] = useMutation(UPDATE_CATEGORY, {
        awaitRefetchQueries: true,
        refetchQueries: ({ data: { updateCategory: { key } } }) => ([
            {
                query: GET_CATEGORY,
                variables: {
                    key
                }
            },
            {
                query: GET_ALL_CATS`key, names, image`,
                variables: {
                    after: "0",
                    first: 1000
                }
            }
        ])
    });

    return updateCategory;
}

export default ({
    filter = 'key, names, image',
    variables = {
        after: "0",
        first: 1000
    }
} = {}) => {
    const { data: { categories: graphqlCategories = [] } = {}, ...rest } = useQuery(GET_ALL_CATS(filter), {
        variables
    });

    const categories = (graphqlCategories && graphqlCategories.edges) ? 
        graphqlCategories.edges.map(edge => edge.node) :
        [];

    return {
        ...rest,
        categories
    }
}