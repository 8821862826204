import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Button from 'AppCore/Components/Button';
import useForm from 'AppCore/Components/Form/useForm';
import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page';
import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox';
import { gql, useMutation } from '@apollo/client';
import { FormEvent } from 'hoist-non-react-statics/node_modules/@types/react';
import React from 'react';
import { FpsSection, FPS_SECTION_TYPES_DETAILS } from 'AppCore/Types/FpsSection';


const CREATE_MUTATION = gql`
  mutation CreatePageSection($fpsSection: FpsSectionCreate!) {
    createFpsSection(fpsSection: $fpsSection) {
      uuid
      type
      title
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateSection($uuid: String!, $fpsSection: FpsSectionUpdate!) {
    updateFpsSection(uuid: $uuid, fpsSection: $fpsSection) {
      id
      visible
      position_in_page
      uuid
      title
      type
    }
  }
`;


export default function FpsSectionEditForm({
  children,
  fpsPageKey,
  fpsSection,
  onSaved = () => {}
}: {
  fpsSection?: FpsSection,
  onSaved?: any,
  fpsPageKey: string,
  children?: any
}) {

  const [ createFpsSection ] = useMutation(CREATE_MUTATION);
  const [ updateFpsSection ] = useMutation(UPDATE_MUTATION);

  const {
    getMaterialFieldProps,
    // getFieldProps,
    // getFormFieldProps,
    getData: getFormData,
    errors,
    hasError,
    getErrorMessages
  } = useForm({
    config: {
      title: {
        type: 'text',
        validators: ['not_empty']
      },
      type: {
        type: 'text'
      }
    },
    initialData: {
      title: fpsSection?.title,
      type: fpsSection?.type
    }
  });

  const formData: any = getFormData();

  const titles = {
    title: "Titre",
    type: "Type"
  } as any;

  let errorMessages: Array<string> = [];
  if (hasError()) {
    errorMessages = (Object.keys(errors).map((attr_name: string) => {
      if (!errors[attr_name].hasError) {
        return "";
      }
      return titles[attr_name] + " : " + getErrorMessages(errors[attr_name].errors)
    }));
  }

  const onSubmitGeneral = async (e: FormEvent) => {
    console.log("ON SUBMIT", );
    e.stopPropagation();
    e.preventDefault();

    const formData: any = getFormData();
    const fpsSectionData = {
      title: formData.title,
      type: formData.type || null,
    };
    
    if (fpsSection) {
      const fpsSectionUpdated = await updateFpsSection({
        variables: {
          uuid: fpsSection.uuid,
          fpsSection: {
            ...fpsSectionData
          }
        }
      });

      onSaved(fpsSectionUpdated.data.updateFpsSection);
    } else {
      const fpsSectionCreated = await createFpsSection({
        variables: {
          fpsSection: {
            ...fpsSectionData,
            page_key: fpsPageKey
          }
        }
      });

      onSaved(fpsSectionCreated.data.createFpsSection);
    }
      
  }

  return (

    <form onSubmit={onSubmitGeneral}>
      {errorMessages.length > 0 && (
        <Alert severity="error">{errorMessages.map(error_message => (
          <div key={error_message}>{error_message}</div>
        ))}</Alert>
      )}

      <TextField
        fullWidth
        label="Titre"
        margin="normal"
        variant="outlined"
        {...getMaterialFieldProps('title')}
      />

      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Type de section</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          label="Type"
          variant="outlined"
          {...getMaterialFieldProps('type')}
        >
          {FPS_SECTION_TYPES_DETAILS.map((sectionDetails) => (
            <MenuItem key={sectionDetails.key} value={sectionDetails.key}>{sectionDetails.label}</MenuItem>
          ))}
          
        </Select>
      </FormControl>

      {formData.type !== "" && (
        <Alert severity='info'>
          {FPS_SECTION_TYPES_DETAILS.find(s => s.key === formData.type)?.description}
        </Alert>
      )}

      <Divider />
      <FlexRowSpaceBetween>
        <div></div>
        <Button
          variant='contained'
          color='primary'
          type='submit'
        >{fpsSection ? 'Sauvegarder' : 'Créer'}</Button>
      </FlexRowSpaceBetween>
    </form>
  )
}