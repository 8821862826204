import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import useForm from "AppCore/Components/Form/useForm";
import FormField from "AppCore/Components/Form/formField";

import Logo from 'Apps/Admin/Components/Logo';
import { Paper } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useCreateShop } from 'AppCore/react-hooks/useShopList';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://i-wantit.com/">
                I Want It
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    backContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

export default function SignIn() {
    const classes = useStyles();

    const navigate = useNavigate();
    const createShop = useCreateShop();
    const { setCurrentShop } = useCurrentShop();

    const [loading, setLoading] = useState(false);

    const {
        getFormFieldProps,
        getData: getFormData
    } = useForm({
        config: {
            name: {
                type: 'text',
                validators: ['not_empty']
            }
        },
        initialData: {
            name: ""
        }
    });

    const onSubmit = async e => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        try {
            const rs = await createShop({
                variables: {
                    shop: getFormData()
                }
            });

            const { data: { createShop: shop = null } = {} } = rs;
            console.log("rsrsrs", rs);

            if (!shop) {
                throw new Error("Une erreur est survenue")
            }

            setCurrentShop(shop);

        } catch (e) {
            console.dir(e);
            window.alert(e.message);
        }
        setLoading(false);
    }

    const onClickBack = () => {
        navigate('../', { replace: true })
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper className={classes.paper}>
                <div className={classes.backContainer}>
                    <IconButton onClick={onClickBack}>
                        <ArrowBack />
                    </IconButton>
                </div>
                <Logo dark />
                <Typography component="h1" variant="h5">
                    Créer une boutique
                </Typography>
                <br/>
                <form className={classes.form} noValidate onSubmit={onSubmit}>

                    <FormField {...getFormFieldProps('name')} disabled={loading} />
                    <br/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {loading ? '...' : 'Créer'}
                    </Button>
                </form>
            </Paper>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}