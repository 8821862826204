import _get from 'lodash.get'
import { useEffect, useState } from 'react'

import * as validatorsFct from './validators';
import type { ValidatorOptions } from './validators'
import type { TransformersOptions } from './transformers'


export type Attributes = {
	[key: string]: {
		validators?: ValidatorOptions,
		transformers?: TransformersOptions,
	}
}


const getErrorMessages = (errors : Array<string>) =>
    errors
        .map((error: string) : any => ((validatorsFct as any)[error] && (validatorsFct as any)[error].error_msg) || '');



const checkErrors = ({
    config, value, attr_name
}: {
    config: any,
    value: any,
    attr_name: any
}) => {

    const { validators = [] } = config[attr_name];


    const errors: Array<string> = [];
    let hasError = false;

    console.log("validators", validators);
    validators.forEach((validator: string) => {
        // @ts-ignore
        if (!validatorsFct[validator] || validatorsFct[validator](value)) return;
        hasError = true;
        errors.push(validator);
    });

    return {
        hasError,
        errors
    }
}

// @ts-ignore
const getDefaultForType = ({ config, attr_name }) => {

    const { type, defaultValue } = config[attr_name];

    if (defaultValue !== undefined) {
        return defaultValue;
    }

    switch (type) {
        case 'text':
            return "";
        default:
            return "";
    }
}

export default ({
    config, initialData: _initialData , onChange = () => {}
}: {
    config?: any,
    initialData?: any,
    onChange?: () => any
} = {}) => {

    const [ initialData, setInitialData ] = useState(_initialData)
    const [ data, _setData ] = useState(_initialData || {})
    const [ errors, _setErrors ] = useState<{[key: string]: any}>({})

    // @ts-ignore
    const checkAttrs = attr_names => {
        const new_errors = {};
        let has_error = false;
        // @ts-ignore
        attr_names.forEach(attr_name => {
            const error = checkErrors({
                config, attr_name, value: getDataAttr(attr_name)
            })

            // @ts-ignore
            new_errors[attr_name] = error;
            // @ts-ignore
            has_error = has_error || new_errors[attr_name].hasError
        })
        _setErrors({
            ...errors,
            ...new_errors
        })

        return has_error;
    }

    // @ts-ignore
    const setData = data => {
        _setData(data || {});
        if (!initialData) {
            setInitialData(data || {});
        } else {
            onChange();
        }
    }
    const getData = () => {
        const returnData = {};
        Object.keys(config).forEach(attr_name => {
            // @ts-ignore
            returnData[attr_name] = data[attr_name];
        })
        return returnData;
    };

    // @ts-ignore
    const getDataAttr = (attr_name, _default?) => {
        const value = _get(data, attr_name, _default || getDefaultForType({ attr_name, config }));
        const { type } = config[attr_name];
        if (!value && type === 'text') {
            return _default || getDefaultForType({ attr_name, config })
        }
        return value;
    }

    const setDataAttr = (attr_name: string, value: any) => {
        setData({
            ...data,
            [attr_name]: value
        })
    }

    const getErrorAttr = (attr_name: string) => {
        return _get(errors, attr_name, { hasError: false, errors: [] });
    }
    const hasErrorAttr = (attr_name: string) => {
        const errors = getErrorAttr(attr_name)
        return errors.hasError;
    }

    const getErrorMessagesAttr = (attr_name: string) => {
        const errors = getErrorAttr(attr_name)
        if (!errors.hasError) {
            return [];
        }
        return getErrorMessages(errors.errors)
    }

    const hasError = () => Object.keys(errors).some((error_key: string) => {
        return errors[error_key].hasError;
    })

    const checkAllErrors = () => {
        return checkAttrs(Object.keys(config));
    }

    useEffect(() => {
        if (hasError()) {
            checkAttrs(Object.keys(errors));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ data ]);


    return {
        data,
        errors,
        setData,
        getData,
        getDataAttr,
        setDataAttr,
        getErrorAttr,
        getErrorMessagesAttr,
        hasErrorAttr,
        checkAttr: (attr_name: string) => checkAttrs([ attr_name ]),

        onChangeText: (attr_name: string) => ({ target: { value }}: any) => {
            setDataAttr(attr_name, value)
        },

        hasError, checkAllErrors,
        getErrorMessages,

        getMaterialFieldProps: (attr_name: string) => ({
            value: getDataAttr(attr_name),
            error: hasErrorAttr(attr_name),
            onChange: ({ target: { value } }: any) => {
                setDataAttr(attr_name, value)
            },
            onBlur: () => checkAttrs([ attr_name ]),
            name: attr_name
        }),

        getFieldProps: (attr_name: string) => ({
            value: getDataAttr(attr_name),
            onChange: ({ target: { value } }: any) => {
                setDataAttr(attr_name, value)
            },
            onBlur: () => checkAttrs([ attr_name ]),
            name: attr_name
        }),

        getFormFieldProps: (attr_name: string) => {
            return ({
                value: getDataAttr(attr_name),
                onChange: (value: any) => {
                    setDataAttr(attr_name, value)
                },
                onBlur: () => checkAttrs([ attr_name ]),
                hasError: hasErrorAttr(attr_name),
                errorMsg: getErrorMessages(getErrorAttr(attr_name).errors),
                name: attr_name,
                config: _get(config, attr_name)
            })
        }
    }
}