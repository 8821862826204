import Api from 'Meth/api/request';
import { checkParam } from './helpers';

export default {

    getProduct: ({ id }) => {
        checkParam(id, 'number', 'id', true);

        return Api.call({
			uri : `products/${id}`,
			request_method: Api.request_method.GET
		})
    },

    getAllProducts: ({ index = 0, limit = 0 } = {}) => {

		checkParam(index, 'number', 'index', false)
		checkParam(limit, 'number', 'limit', false)

		return Api.call({
			uri : `products`,
			request_method: Api.request_method.GET,
			params: {
				index,
				limit
			}
		})
	},

}