import React from "react";
import styled from "styled-components";
import { TextField, Paper } from "@material-ui/core";

import Button from 'AppCore/Components/Button'

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import useForm from "AppCore/Components/Form/useForm";


const SearchContainer = styled(Paper)`
    padding: 20px;
`

export default ({
  loading = false,
  searchFormData: searchFormDataInitialData = {},
  onChangeSearchQuery = () => {},
  onChangeForm = () => {}
}) => {

    const {
        data: formData,
        getFieldProps,
        //getData: getSearchFormData,
        setData: setSearchFormData,
    } = useForm({
        config: {
            name: {
                type: 'text'
            }
        },
        initialData: searchFormDataInitialData
    });

    React.useEffect(() => {
        onChangeForm(formData)
    }, [formData, onChangeForm])

    const onClickSearch = async (e) => {
        onChangeSearchQuery(formData);
    }
    const onClickReset = async () => {
        setSearchFormData({});
        onChangeSearchQuery(undefined);
    }

    return (
        <SearchContainer>
            <TextField {...getFieldProps('name')} disabled={loading} placeholder="Nom de la boutique" size="small" variant="outlined" />
            
            <Divider my={6} />

            <Button variant="contained" color="primary" disabled={loading} loading={loading} onClick={onClickSearch}>Search</Button>
            <Button variant="contained" color="primary" disabled={loading} loading={loading} onClick={onClickReset}>Reset</Button>
        </SearchContainer>
    )
}