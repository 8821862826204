import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import CropMedia, { getCroppedImg } from './CropMedia'
import { clone as cloneMedia } from './index';
import { isVideo } from './helpers'
import type { MediaType } from './index'

import { calculateMustContain } from './cropHelpers'

type Props = {
    close: (e: any) => any,
    media: Object,
    aspect?: number,
    initialCropConf?: Object,
    onSaveFile?: (e: {
        media: MediaType,
        file?: File | Blob,
        cropConf?: Object,
        unchanged?: boolean
    }) => Promise<any>,
    onCancel?: (e: any) => any
}

const CropMediaModal = ({
    close, media, aspect, initialCropConf = {},
    onSaveFile = async (e: any) => {},
    onCancel = () => {}
}: Props) => {

    const [ loading, setLoading ] = useState<boolean>(false);
    const [ cropConf, setCropConf ] = useState(initialCropConf);

    const onClickSave = async (e: any) => {

		if (!cropConf) {
			throw new Error("Missing cropconf");
		}

        // @ts-ignore
		if (isVideo(media.type)) {
            onSaveFile({
                // @ts-ignore
                media,
                cropConf,
                unchanged: true
            })
            // @ts-ignore
            close();
			return;
		}

        // @ts-ignore
		if (!media || !media.type || !media.height || !media.width || !media.src) {
			throw new Error("Need width and height !")
		}

        setLoading(true);

		const { blob, media: croppedMedia } = await getCroppedImg({
            // @ts-ignore
			src: media.src,
            // @ts-ignore
			height: media.height,
            // @ts-ignore
			width: media.width,
            // @ts-ignore
			type: media.type
		}, cropConf)

		const newMedia: MediaType = cloneMedia(croppedMedia);

        onSaveFile({
            media: newMedia,
            file: blob
        })
        // @ts-ignore
        close();
	}

    const onCloseWithoutSave = () => {
        // @ts-ignore
        onCancel();
        // @ts-ignore
        close()
    }

    const containerWidthHeight = calculateMustContain({
        // @ts-ignore
        contentWidth: media.width,
        // @ts-ignore
        contentHeight: media.height,
        containerWidth: 600,
        containerHeight: 600
    })

    return (
        <MuiDialog
            fullWidth
            maxWidth='md'
            open
            onClose={!loading ? onCloseWithoutSave : () => {}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Resize image</DialogTitle>
            <DialogContent style={{position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{position: 'relative', width: containerWidthHeight.width + "px", height: containerWidthHeight.height + "px"}}>
                    <CropMedia
                        // @ts-ignore
                        media={media}
                        aspect={aspect}
                        initialCropConf={initialCropConf}
                        onCropChange={setCropConf}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                {loading ?
                    <ActivityIndicator />:
                    <>
                        <Button onClick={onCloseWithoutSave}>Annuler</Button>
                        <Button disabled={false} onClick={onClickSave}>Valider</Button>
                    </>
                }

            </DialogActions>
        </MuiDialog>

    )
}

export const useOpenModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({
        media,
        aspect,
        initialCropConf,
        ...props
    }: {
        media: any,
        aspect?: number,
        initialCropConf?: any
    }) => openDialogComponent(
        // @ts-ignore
        ({ close }) => (
            <CropMediaModal {...props} aspect={aspect} initialCropConf={initialCropConf} close={close} media={media} />
        )
    )
}