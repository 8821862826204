import React, {useState} from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import Table from 'Apps/Admin/Components/UserList';

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import UserSearchForm from 'Apps/Admin/Components/SearchForms/Users'

const CORE_USERS_LIST_FIELDS = gql `
fragment CoreUsersListFields on UserList{
    pageInfo {
        hasNextPage
        endCursor
    }
    edges {
        cursor
        node {
            id,
            email,
            full_name,
            lastname, 
            firstname, 
            avatar
        }
    }
}`;

const GET_ALL_USERS = gql`
    ${CORE_USERS_LIST_FIELDS}
    query getUsers($after: String, $first: Int) {
        users(after: $after, first: $first) {
            ...CoreUsersListFields
        }
    }
`;

const SEARCH_USERS = gql`
    ${CORE_USERS_LIST_FIELDS}
    query searchUsers($after: String, $first: Int, $query: String!){
        searchUsers(after: $after, first: $first,query: $query){
            ...CoreUsersListFields
        }
    }    
`;



const List = ({
    filterIds = [],
    data, columns, actions, fetchMore
}) => {

    if(!data){
        return <p>Waiting for data...</p>
    }

    const {
        users = null,
        searchUsers = null,
    } = data;

    const {
        pageInfo,
        edges
    } = users ? users : searchUsers;

    return (
        <>
            <Divider my={6} />

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={edges.map(edge => edge.node).filter(el => {
                            return filterIds.indexOf(el.id) < 0;
                        })}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            {
                pageInfo.hasNextPage &&
                    <Button onClick={() => {
                    fetchMore({
                        variables: {
                            after: pageInfo.endCursor,
                            first: 100
                        }
                    })
                    }}>Load More</Button>
            }
        </>
    )
}

export default function UsersView({
    filterIds = [],
    actions = [],
    columns = ['image', 'full_name', 'email']
}) {

    const [searchFormData, setSearchForm] = useState({});

    const {
        loading,
        data,
        fetchMore
    } = useQuery(searchFormData?.full_name ? SEARCH_USERS : GET_ALL_USERS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: "cache-first",
        variables: {
            after: "",
            first: 100,
            ...(searchFormData?.full_name ? {query: searchFormData?.full_name} : {})
        },
    });

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <UserSearchForm
                onChangeSearchQuery={setSearchForm}
                onChangeForm={console.log}
                loading={loading}
            />
            
            {loading ? <div>Loading...</div> : (
                <List
                    data={data}
                    filterIds={filterIds}
                    columns={columns}
                    actions={actions}
                    fetchMore={fetchMore}
                />
            )}
            

        </div>
    );
}