import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import useCategories from 'AppCore/react-hooks/useCategories';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


export default ({ value, onChange, ...props }) => {

    const { categories } = useCategories()
    return (
        <FormControl fullWidth>
            <InputLabel id="category-select-label">Catégorie</InputLabel>
            <Select 
            labelId="category-select-label"
            label="Catégory" 
            value={value || ""} 
            onChange={(e) => {
                console.log(e.target.value);
                onChange(e);
            }} displayEmpty {...props}>
                <MenuItem value={""}></MenuItem>
                {categories.map(category => {
                    const names = JSON.parse(category.names);
                    return (
                        <MenuItem key={category.key} value={category.key}>{names.fr || names.en || category.key}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>

    )
}