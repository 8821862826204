import Settings from '../settings';


function trimSlashes(s: string): string {
    return s.trim().split('/').map(p => p.trim()).filter(p => p !== "").join("/");
}


function isUUIDFilename(filename: string): boolean {
    if (/^([a-z0-9]){8}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){4}-([a-z0-9]){12}\.([a-z0-9])+$/g.test(filename)) {
        return true;
    }
    return false;
}


const getImageUrl = (urlOrPath: string): string => {

  if (urlOrPath.startsWith("https://www.i-wantit.com//web") || urlOrPath.startsWith("https://www.i-wantit.com/web")) {
    return urlOrPath;
  }

  let url: URL;
  let pathName: string;
  try {
    url = new URL(urlOrPath)
    pathName = trimSlashes(url.pathname)
  } catch (e) {
    pathName = trimSlashes(urlOrPath)
  }

  if (isUUIDFilename(pathName)) {
    const pathNameSplitted = pathName.split('-');
    pathName = pathNameSplitted[0] + "/" + pathNameSplitted[1] + "/" + pathName;
  }

  return Settings.image_url_v2 + '/' + pathName;
}

export default getImageUrl;
export { getImageUrl, trimSlashes, isUUIDFilename };