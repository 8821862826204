import React from 'react';
import { Helmet } from 'react-helmet';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import { ShopModel } from 'AppCore/Types/Shop';
import GeneralShopSettings from 'Apps/Admin/Components/GeneralShopSettings'
import { Divider } from '../../Pages/Utils/layout';

export default function ShopSettings(){

  const { currentShop } = useCurrentShop() as { currentShop: ShopModel };

  return(
    <>
      <Helmet>
        <title>Paramètres | {currentShop.name}</title>
      </Helmet>
      <h1>Paramètre de la boutique iwantit</h1>
      <Divider style={{
        marginBottom: '10px'
      }} />

      <GeneralShopSettings />
    </>
  )
}