import React from "react";

import LayoutDashboard from '../Shared/Layouts/Dashboard'
import LayoutAuth from '../Shared/Layouts/Auth'

import Common from './Layouts/Common'

import SidebarHeaderComponent from './Layouts/Components/Sidebar/Header';
import SidebarFooter from './Layouts/Components/Sidebar/Footer'

// EXPERIMENTS
import AuthLogin from './Pages/Auth/Login'

import {
  ShoppingCart,
  Package,
  List
} from "react-feather";
import Wants from "./Pages/Wants";
import Products from "./Pages/Products";
import ProductLists from "./Pages/ProductLists";
import ChooseShop from './Pages/ChooseShop';
import CreateShop from './Pages/ChooseShop/createShop';
import Product from "./Pages/Products/EditCreate/Edit";
import ProductCreate from "./Pages/Products/EditCreate/Create";
import ShopChooserContainer from "./Pages/ChooseShop/LayoutContainer";
import { Home, Settings } from "@material-ui/icons";
import Dashboard from "./Pages/Dashboard";
import ShopSettings from "./Pages/ShopSettings/index.tsx";
import UploadMediaExperiment from "./Pages/Experiments/UploadMedia";
import EditProductsListPage from "./Pages/ProductLists/EditCreate/Edit";
import CreateProductsListPage from './Pages/ProductLists/EditCreate/Create';


const NotFound = () => {
    return (<div>NOT FOUND</div>)
}

export default {
    config: {
        redirectSigninPath: '/auth/login',
        redirectHomePath: '/',
        redirectForbiddenPath: '/not-authorized'
    },
    sidebar: [
        { title: 'Dashboard', path: '/', icon: <Home /> },
        { title: 'Paramètre boutique', path: '/settings', icon: <Settings /> },
        { title: 'Mes Produits', path: '/products', icon: <Package /> },
        { title: 'Mes Listes de produits', path: '/product-lists', icon: <List /> },
        { title: 'Wants - statistiques', path: '/wants', icon: <ShoppingCart /> }
    ],

    sidebarConfig: {
        SidebarHeaderComponent,
        SidebarFooterComponent: SidebarFooter
    },

    routes: [

        {
            path: "/",
            element: Common,
            routes: [

                {
                    path: "/auth",
                    element: LayoutAuth,
                    routes : [
                        { path: "", redirectSignin: true },
                        { path: "login", needLogout: true, element: AuthLogin }
                    ]
                },

                {
                    path: "/not-authorized",
                    element: LayoutAuth,
                    routes : [
                        {
                            path: "",
                            element: () => (<div>NOT AUTHORIZED !</div>)
                        }
                    ]
                },

                {
                    path: "/shop-choose",
                    needUser: true,
                    element: ShopChooserContainer,
                    routes : [
                        {
                            path: "",
                            element: ChooseShop
                        },
                        {
                            path: "create",
                            element: CreateShop
                        }
                    ]
                },

                {
                    path: "/",
                    element: LayoutDashboard,
                    needUser: true,
                    needShop: {
                        redirectTo: '/shop-choose'
                    },
                    routes : [

                        { path: '/', element: Dashboard, needUser: true },
                        { path: '/settings', element: ShopSettings, needUser: true },
                        { path: '/products', element: Products, needUser: true },
                        { path: '/product-lists', element: ProductLists, needUser: true },
                        { path: '/wants', element: Wants, needUser: true },

                        { path: '/upload-experiment', element: UploadMediaExperiment, needUser: true },

                        {
                            path: '/create-product', element: ProductCreate
                        },
                        {
                            path: '/product/:id', element: Product
                        },
                        {
                            path: '/productslist/:uuid', element: EditProductsListPage
                        },
                        {
                            path: '/create-productslist', element: CreateProductsListPage
                        }

                    ]
                }
            ]
        },

        {
            path: "*", element: NotFound
        }
    ]
}