import React from 'react';
import { useParams } from 'react-router-dom'
import EditShop from 'Apps/Admin/Components/GeneralShopSettings'
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import { gql, useQuery } from '@apollo/client';
import { Tabs } from '@knxlab/components'
import {
    TabsContainer, TabsPanels
} from '../../Utils/layout'
import Dashboard from '../../Dashboard';
import Wants from '../../Wants';
import { Divider } from '../../Utils/layout';
import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox'
import { Title } from 'Apps/Admin/Layouts/Components/Page'



const GET_SHOP = gql`
    query getShop($uuid: String!){
        shop(uuid: $uuid){
            id,
            uuid,
            name,
            description,
            logo,
            cover,
            url
        }
    }
`;

export default () => {

    const {uuid} = useParams();

    const {
        loading,
        data,
        error
    } = useQuery(GET_SHOP, {
        variables: {
            uuid
        }
    })

    const {currentShop, setCurrentShop} = useCurrentShop();

    if(loading){
        return <p>Loading ...</p>
    }

    if(error ){
        return <p>Error</p>
    }

    setCurrentShop(data.shop);

    return (
        <>
            <FlexRowSpaceBetween>
                <Title>
                    Boutique | {currentShop?.name}
                </Title>
                <a href={`iwantit://shop?shop_uuid=${uuid}`}>{`iwantit://shop?shop_uuid=${uuid}`}</a>
            </FlexRowSpaceBetween>

            <Divider style={{
                marginBottom: '10px'
            }} />
            
            <TabsContainer orientation={"horizontal"}>
                
                <Tabs
                        defaultSelected={1}
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[
                            {
                                label: "Paramétres",
                                panel: (
                                    <TabsPanels style={{marginTop: '20px'}}>
                                        <EditShop />
                                    </TabsPanels>
                                )
                            },
                            {
                                label: "Statistiques",
                                panel: (
                                    <TabsPanels style={{marginTop: '20px'}}>
                                        <Dashboard />
                                    </TabsPanels>
                                )
                            }
                            ,
                            {
                                label: "Wants",
                                panel: (
                                    <TabsPanels style={{marginTop: '20px'}}>
                                        <Wants />
                                    </TabsPanels>
                                )
                            }
                        ]}
                    />

            </TabsContainer>
        </>
    )
}