import React  from 'react';

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import View from '../ProductsListList/view'

const DialogList = ({
    filterIds = [],
    onClose,
    onSelect = () => {},
    searchFormData = {}
}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Listes de produits</Typography>
            </DialogTitle>
            <DialogContent>
                <View
                    searchFormData={searchFormData}
                    filterIds={filterIds}
                    actions={[
                        element => (
                            <Button key="select" onClick={() => onSelect(element, { close: onClose })}>Selectionner</Button>
                        )
                    ]}
                    columns={['image', 'title', 'market_url']}
                />

            </DialogContent>
        </Dialog>
    );
}

export const useOpenProductsListListModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return (props) => openDialogComponent(
        ({ close }) => (
            <DialogList
              {...props}
              onClose={close}
            />
        )
    )
}