import React from 'react';
import { useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import View from './index'
import useAdminType, { TYPE_ADMIN_SHOP } from 'AppCore/react-hooks/useAdminType';

const CORE_PRODUCT_FIELDS = gql`
    fragment CoreProductFields on Product {
        id,
        title,
        description,
        price,
        price_discount,
        image,
        wantsCount,
        market_url,
        market_id,
        master_category,
        primary_product_id,
        status
        category {key, names},
        primaryProduct {
            id, title
        }
    }
`;

const GET_PRODUCT = gql`
    ${CORE_PRODUCT_FIELDS}
    query getProduct($id: Int) {
        product(id: $id) {
            ...CoreProductFields
        }
    }
`;

const UPDATE_PRODUCT = gql`
    ${CORE_PRODUCT_FIELDS}
    mutation UpdateProduct($id: Int!, $product: ProductUpdate!) {
        updateProduct(id: $id, product: $product) {
            ...CoreProductFields
        }
    }
`;

export default () => {

    const { id } = useParams();
    const adminType = useAdminType();

    const {
        loading,
        data: { product: data = {} } = {}
    } = useQuery(GET_PRODUCT, {
        variables: {
            id: parseInt(id)
        },
    });
    const [updateProduct] = useMutation(UPDATE_PRODUCT);

    if (loading) {
        return <ActivityIndicator material />;
    }

    return (
        <>
            <View
            layout={adminType === TYPE_ADMIN_SHOP ? 'edit-shop' : 'edit'}
            initialData={data}
            saveData={async (data) => {
                await updateProduct({
                    variables: {
                        id: parseInt(id),
                        product: data
                    }
                })
            }}
            />
        </>
    )
}