import { gql, useQuery, useMutation } from '@apollo/client';


export const GET_ALL = gql`
    query getShops($after: String, $first: Int) {
        myshops(after: $after, first: $first) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                    id, uuid, name, url, description, logo, cover
                }
            }
        }
    }
`;

const GET = gql`
        query getShop($id: String!) {
            shop(id: $id) {
                id, uuid, name
            }
        }
    `;

const UPDATE = gql`
  mutation UpdateShop($id: String!, $shop: ShopUpdate!) {
    updateShop(id: $id, shop: $shop) {
        id, uuid, name
    }
  }
`;


const CREATE = gql`
  mutation CreateShop($shop: ShopCreate!) {
    createShop(shop: $shop) {
        id, uuid, name
    }
  }
`;

export const useGetShop = (uuid) => {
    const {
        loading,
        data: { shop = {} } = {}
    } = useQuery(GET, {
        variables: {
            uuid
        },
    });

    return { loading, shop };
}

export const useCreateShop = () => {
    const [createShop] = useMutation(CREATE, {
        awaitRefetchQueries: true,
        refetchQueries: () => ([
            {
                query: GET_ALL,
                variables: {
                    after: "0",
                    first: 1000
                }
            }
        ])
    });

    return createShop;
}

export const useUpdateShop = () => {

    const [updateShop] = useMutation(UPDATE, {
        awaitRefetchQueries: true,
        refetchQueries: ({ data: { updateShop: { uuid } } }) => ([
            {
                query: GET,
                variables: {
                    uuid
                }
            },
            {
                query: GET_ALL,
                variables: {
                    after: "0",
                    first: 1000
                }
            }
        ])
    });

    return updateShop;
}

export default ({
    variables = {
        after: "0",
        first: 1000
    }
} = {}) => {
    const { data = {}, ...rest } = useQuery(GET_ALL, {
        variables,
        fetchPolicy: 'no-cache'
    });

    const { myshops: graphqlShops = [] } = data;

    const shops = (graphqlShops && graphqlShops.edges) ?
        graphqlShops.edges.map(edge => edge.node) :
        [];

    return {
        ...rest,
        shops
    }
}