import keyMirror from 'keymirror';
import axios from 'axios';

import { ApiError } from './ApiError';

let UserStore = null;
export function setUserStore(_UserStore) {
	UserStore = _UserStore;
}

let AccessTokenGetter = null;
export function setAccessTokenGetter(fct) {
	AccessTokenGetter = fct;
}

let onAccessTokenExpiredCallback = () => {};
export function setOnAccessTokenExpiredCallback(_onAccessTokenExpiredCallback) {
	onAccessTokenExpiredCallback = _onAccessTokenExpiredCallback;
}

const conf = {
	HOST_API: null,
	USER_AGENT: null
};

export function setUserAgent(user_agent) {
	conf.USER_AGENT = user_agent;
}
export function setHostApi(host_api) {
	conf.HOST_API = host_api + '/';
}

class Request {

	constructor(options) {
		this.uri = null;
		this.access_token = null;
		this.params = null;
		this.data = null;
		this.filter = null;
		this.request_method = Request.request_method.GET;
		this.user_agent = null;
		this.config = null;
		if (options) {
			this.setOptions(options)
		}
	}

	setOptions(options) {
		this.uri = options.uri;
		this.request_method = options.request_method || Request.request_method.GET;
		this.onResult = options.onResult || function() {};
		this.onError = options.onError || function(error) {
			throw error;
		};

		this.filter = options.filter || null;

		if (options.params) {
			this.params = {
				...(this.params || {}),
				...(options.params)
			};
		}

		if (options.config) {
			this.config = {
				...(this.config || {}),
				...(options.config)
			};
		}

		if (options.data) {
			if (options.data instanceof FormData) {
				this.data = options.data;
			} else {
				this.data = {
					...(this.data || {}),
					...(options.data)
				};
			}
		}
	}

	setAccessToken(access_token) {
		this.access_token = access_token;
	}

	setUserAgent(user_agent) {
		this.user_agent = user_agent;
	}

	call = async () => {


		const config = {
			...(this.config || {}),
			method : this.request_method.toLowerCase(),
			baseURL: conf.HOST_API,
			url : this.uri,
			params: {
				...(this.params || {}),
				...(!this.filter ? {} : {
					filter: JSON.stringify(this.filter)
				})
			},
			...(this.data ? { data : this.data } : {}),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				...(!this.access_token ? {} : {
					"X-access-token": this.access_token
				})
			}
		};

		try {
			const result = (await axios(config)).data;
			if (result.error) {
				const error = new Error(result.message);
				error.response = {
					data: {
						error: result
					}
				};
				throw error;
			}
			return result;
		} catch (e) {

			if (!e.response || !e.response.data || !e.response.data.error) {
				throw e;
			}

			const error = e.response.data.error;

			/// PROBLEM WITH A TOKEN
			if (error.code < 200 && error.code >= 100) {
				// Do something when access_token has expired
				// throw new ApiError(error);
				onAccessTokenExpiredCallback();
				return {};
			} else {
				throw new ApiError(error);
			}
		}
	}

	static getInstance(options) {

		let access_token = options.access_token || null;

		if (!access_token && AccessTokenGetter) {
			access_token = AccessTokenGetter();
		}

		if (!access_token && UserStore) {
			access_token = UserStore.getAccessToken();
		}

		var rq = new Request(options);
		if (access_token !== null) {
			rq.setAccessToken(access_token);
		}

		if (conf.USER_AGENT) {
			rq.setUserAgent(conf.USER_AGENT);
		}

		return rq;
	}

	static call(options) {
		return Request.getInstance(options).call();
	}

}

Request.request_method = keyMirror({
	GET : null,
	POST : null,
	PUT: null,
	PATCH: null,
	DELETE : null
});


export default Request;

