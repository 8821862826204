import Api from 'Meth/api/request';
import { checkParam } from './helpers';
import searchUser from './Search/User';

export default {

    getUser: ({ id }) => {
        checkParam(id, 'number', 'id', true);

        return Api.call({
			uri : `users/${id}`,
			request_method: Api.request_method.GET
		})
    },

    getAllUsers: ({ index = 0, limit = 0 } = {}) => {

		checkParam(index, 'number', 'index', false)
		checkParam(limit, 'number', 'limit', false)

		return Api.call({
			uri : `users`,
			request_method: Api.request_method.GET,
			params: {
				index,
				limit
			}
		})
	},

    searchUser: searchUser

}