import { loadMedia } from './Media/helpers'
import type { MediaType } from './Media'

export type StorageConfType = { storageRef: any, storageDir: string };

type Args = {
    file: File | Blob,
    filetype: string,

    onProgress: (progress: number) => any
}


export const uploadMeth = async ({
    onProgress, file, filetype, uploadMethod
}: Args & {
    uploadMethod: (e: {
        file: File | Blob,
        filetype: string,
        onProgress: (progress: number) => any
    }) => Promise<{ downloadURL: string } & any>

}) : Promise<MediaType & any> => {
    const { downloadURL, ...payload } = await uploadMethod({
        file,
        filetype,
        onProgress
    });

    const media = await loadMedia(downloadURL, filetype);
    return {
        src: media.src,
        width: media.width,
        height: media.height,
        type: file.type,
        payload
    }
}