import React, { FormEvent } from 'react';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import useForm from 'AppCore/Components/Form/useForm';
import { ShopModel } from 'AppCore/Types/Shop';


export default function GeneralForm() {
  const { currentShop, updateCurrentShop } = useCurrentShop();

  
  const {
    getMaterialFieldProps,
    getData: getFormData,
    errors,
    hasError,
    hasErrorAttr,
    getErrorMessagesAttr,
    getErrorMessages
  } = useForm({
    config: {
      name: {
        type: 'text',
        validators: ['not_empty']
      },
      description: {
        type: 'text',
        validators: ['not_empty']
      },
      url: {
        type: 'text',
        validators: ['not_empty', 'url']
      }
    },
    initialData: {
      name: currentShop.name,
      description: currentShop.description,
      url: currentShop.url
    }
  });

  if (hasError()) {
    console.log(Object.keys(errors).map((attr_name: string) => {
      if (!errors[attr_name].hasError) {
        return "";
      }
      return getErrorMessages(errors[attr_name].errors)
    }));
  }

  const onSubmitGeneral = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const renderError = (attr_name: string) => {
    return hasErrorAttr(attr_name) ? (
      <Alert severity="error">{getErrorMessagesAttr(attr_name).map(error_message => (
        <div key={error_message}>{error_message}</div>
      ))}</Alert>
    ) : "";
  }

  const update = async () => {
    const shopData = getFormData();
    console.log("UPDATE SHOP", shopData);
    await updateCurrentShop(shopData as ShopModel)
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      subheader="Informations sur la boutique"
      title="Général"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={update}
        >
          Update
        </Button>
      )}
    >
      <form onSubmit={onSubmitGeneral}>
      {renderError('name')}
      <TextField
        fullWidth
        label="Nom de la boutique"
        margin="normal"
        variant="outlined"
        {...getMaterialFieldProps('name')}
      />
      {renderError('url')}
      <TextField
        fullWidth
        label="Url"
        margin="normal"
        variant="outlined"
        {...getMaterialFieldProps('url')}
      />
      {renderError('description')}
      <TextField
        fullWidth
        label="Description"
        margin="normal"
        variant="outlined"
        {...getMaterialFieldProps('description')}
      />
      </form>
    </CardSection>
  )
}