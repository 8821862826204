import React from 'react';
import Helmet from 'react-helmet';
import Alert from '@material-ui/lab/Alert';

import { Tabs } from '@knxlab/components'

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox'

import {
    Title, Divider,
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'


import {
    TabsContainer, TabsPanels
} from '../../Utils/layout'

import Wants from './Wants'
import { Grid } from '@material-ui/core';
import ImageUpload from './Components/ImageUpload';
import InfosForm from './Components/InfosForm';

const defaultTitle = "Produit";
export default ({
    initialData,
    saveData,
    layout = 'edit'
}) => {

    //const isLayoutAdmin = () => layout === 'create' || layout === 'edit';
    const isLayoutCreate = () => layout === 'create' || layout === 'create-shop';
    const isLayoutEdit = () => layout === 'edit' || layout === 'edit-shop';

    const Form = (
        <Grid container spacing={5}>

            <Grid item xs={12} md={4}>
                <ImageUpload
                    product={initialData}
                    update={async (data) => {
                        await saveData(data);
                    }}
                />
            </Grid>
    
            <Grid item xs={12} md={8}>
                <InfosForm
                    product={initialData}
                    layout={layout}
                    update={async (data) => {
                        await saveData(data);
                    }}
                />
            </Grid>
        </Grid>
    )

    return (
        <React.Fragment>
            <Helmet title={initialData.title || defaultTitle} />

            <FlexRowSpaceBetween>

                <Title>
                    {initialData.title || defaultTitle}
                </Title>
                { isLayoutEdit() && initialData.id &&
                    <a href={`iwantit://product?productId=${initialData.id}`}>{`iwantit://product?productId=${initialData.id}`}</a>}
            </FlexRowSpaceBetween>

            <Divider my={6} />


            <FlexRowSpaceBetween style={{alignItems: 'flex-start'}}>
                    
                {isLayoutCreate() && Form}

                {isLayoutEdit() && initialData?.id && (
                <TabsContainer orientation={"horizontal"}>
                    <Tabs
                        defaultSelected={0}
                        orientation={"horizontal"}
                        variant="scrollable"
                        tabs={[
                            {
                                label: "Détails",
                                panel: (
                                    <TabsPanels style={{marginTop: '20px'}}>
                                        {Form}
                                    </TabsPanels>
                                )
                            },
                            {
                                label: "Statistiques",
                                panel: (
                                    <TabsPanels style={{marginTop: '20px'}}>
                                        <Wants product={initialData} />
                                    </TabsPanels>
                                )
                            }
                        ]}
                    />

                </TabsContainer>
                )}

                {isLayoutEdit() && !initialData?.id && (
                    <Alert severity="warning">Les informations relatives à votre produit n'ont pas pu etre chargées. Si cette erreur persiste, merci de contacter le support technique.</Alert>
                )}

            </FlexRowSpaceBetween>

        </React.Fragment>

    )
}
