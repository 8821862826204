import React, { useState } from 'react';
import { TextField, Button, Paper } from '@material-ui/core';
import { Title } from 'Apps/Admin/Layouts/Components/Page'
import Api from 'AppCore/Api';

export default () => {

    const [url, setUrl] = useState("https://www.lacoupedesfees.com/fr/cierges-magiques/666-cierge-des-anges.html");
    const [urlData, setUrlData] = useState();

    const onSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        const urlData = await Api.urlExplorer({ url });

        setUrlData(urlData);
    }

    console.log(urlData);

    return (
        <>
            <Title>Url Explorer</Title>

            <form onSubmit={onSubmit}>
                <Paper style={{marginTop: '20px', padding: '10px', display:'flex', flexDirection: 'row'}}>
                    <TextField
                        placeholder="https://..."
                        value={url}
                        onChange={({ target: { value } }) => setUrl(value)}
                        style={{flex: 1, marginRight: '5px'}}
                    /> <Button type="submit" variant="contained" color="primary">Explorer</Button>
                </Paper>
            </form>

        </>
    )
}