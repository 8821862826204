import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@material-ui/core';
import { Divider, TabsContainer, TabsPanels } from '../../Utils/layout';
import InfosForm from './Components/InfosForm';

// @ts-ignore
import { Tabs } from '@knxlab/components'
import ProductListsList from './Components/List';

export default function ProductsListsListDataView({
  productsListsList,
  update,
  layout = 'edit'
}: {
  productsListsList: any,
  update: (data: any) => Promise<any>,
  layout?: 'edit' | 'create'
}) {

  const Form = (
    <Box
      style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
      }}
    >
      <Grid container spacing={3} justify={'space-around'} alignItems={'stretch'}>
        <Grid item xs={12} md={12}>
          <InfosForm
            layout={'edit'}
            productsListsList={productsListsList}
            update={update}
          />
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      <Helmet>
        <title>{productsListsList.title}</title>
      </Helmet>

      <h1>{productsListsList.title}</h1>
      <Divider style={{
        marginBottom: '10px'
      }} />

      {layout === 'create' && Form}
      {layout === 'edit' && (
      <TabsContainer orientation={"horizontal"}>
        <Tabs
          defaultSelected={0}
          orientation={"horizontal"}
          variant="scrollable"
          tabs={[
            {
              label: "Détails",
              panel: (
                <TabsPanels style={{marginTop: '20px'}}>
                  {Form}
                </TabsPanels>
              )
            },
            {
              label: "Listes de produits",
              panel: (
                <TabsPanels style={{marginTop: '20px'}}>
                  <ProductListsList
                    productsListsList={productsListsList} update={update}
                  />
                </TabsPanels>
              )
            }
          ]}
        />
      </TabsContainer>
      )}
    </>
  )
}