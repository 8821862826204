import React from "react";
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet';
import { Edit, Delete } from '@material-ui/icons';
import { gql, useMutation } from "@apollo/client";

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'


import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { Title } from 'Apps/Admin/Layouts/Components/Page'


import ProductsView from './view'
import Button from "AppCore/Components/Button";

const DELETE_PRODUCT = gql`
    mutation UpdateProduct($id: Int!, $product: ProductUpdate!) {
        updateProduct(id: $id, product: $product) {
            id,
            title,
            status
        }
    }
`;

export default () => {

    const navigate = useNavigate();

    const [deleteProduct] = useMutation(DELETE_PRODUCT);

    const deleteAfterConfirm = async (id) => {
        if (window.confirm('Voulez-vous supprimer définitivement ce produit ?')) {
         await deleteProduct({
            variables: {
                id: parseInt(id),
                product: {
                    status: 'ARCHIVED'
                }
            }
        })
        }
    }

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <Helmet title="Products" />

            <FlexRowSpaceBetween>
                <Title>
                  Produits
                </Title>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  onClick={() => {
                    navigate('/create-product')
                  }}
                >Créer un nouveau produit</Button>
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <ProductsView
                actions={[
                    { key: 'edit', icon: <Edit />, onClick: id => navigate('/product/'+id) },
                    { key: 'delete', icon: <Delete />, onClick: id => deleteAfterConfirm(id) },
                ]}
            />

        </div>
    );
}