import React from "react";
import { gql, useQuery, useMutation } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { useCurrentShop } from "AppCore/react-hooks/useCurrentShop";
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import { Title } from "Apps/Admin/Layouts/Components/Page";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ProductsListList from "Apps/Admin/Components/ProductsListList";

import { Edit, Delete } from '@material-ui/icons';

import { useSnackbar } from 'notistack';



const GET_ALL_PRODUCTSLISTS = gql`
    query shopProductsLists($after: String, $first: Int, $shop_uuid: String!) {
      shopProductsLists(after: $after, first: $first, shop_uuid: $shop_uuid) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                cursor
                node {
                  id,
                  uuid, title, image,
                  position_in_shop,
                  product_ids,
                  products {
                    id, title
                  }
                }
            }
        }
    }
`;

const DELETE_PRODUCTSLIST = gql`
    mutation DeleteProductsList($uuid: String!) {
      status: deleteProductsList(uuid: $uuid)
    }
`;


const UPDATE_PRODUCTSLIST = gql`
  mutation UpdateProductsList($uuid: String!, $productsList: ProductsListUpdate!) {
    updateProductsList(uuid: $uuid, productsList: $productsList) {
      id, uuid, title, position_in_shop, image, product_ids, products { id, title }
    }
  }
`;

const DataTable = ({
    data,
    columns,
    actions,
    fetchMore,
    orderIds,
    onOrderChanged
}: {
  data: any,
  columns: any,
  actions : any,
  fetchMore: any,
  orderIds: Array<string>,
  onOrderChanged: (result: any) => any
}) => {

    if (!data) {
      return null;
    }
    const {
      shopProductsLists
    } = data;

    const {
        edges
    } = shopProductsLists

    let productsLists = edges.map((edge: any) => edge.node);
    let productsListsSorted: Array<any> = [];
    orderIds.forEach((uuid: string) => {
      productsListsSorted.push(productsLists.find((productsList: any) => {
        return productsList.uuid === uuid;
      }))
    });

    return (
      <Grid container>
          <Grid item xs={12}>
              <ProductsListList
                reorder={true}
                onOrderChanged={onOrderChanged}
                data={productsListsSorted}
                columns={columns}
                actions={actions}
              />
          </Grid>
      </Grid>
    )
}

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default ({
    actions = [],
    columns = ['image', 'id', 'title']
}) => {

  const navigate = useNavigate();
  const { currentShop } = useCurrentShop();
  const [ updateProductsList ] = useMutation(UPDATE_PRODUCTSLIST);
  const [ deleteProductsList ] = useMutation(DELETE_PRODUCTSLIST);
  const { enqueueSnackbar } = useSnackbar();


  const deleteAfterConfirm = async (uuid: any) => {
    if (window.confirm('Voulez-vous supprimer définitivement cette liste ?')) {
      const { data : { status } = {} } = await deleteProductsList({
          variables: {
              uuid
          }
      })
      enqueueSnackbar(status ? "Sauvegardé !" : "Erreur lors de la suppression de la liste.", {
        variant: status ? 'success' : 'error'
      })
    window.location.reload();
    }
  }

  const {
      loading,
      data,
      fetchMore
  } = useQuery(GET_ALL_PRODUCTSLISTS, {
      nextFetchPolicy: "cache-first",
      fetchPolicy: 'cache-and-network',
      variables: {
          after: "",
          first: 100,
          shop_uuid: currentShop.uuid
      },
  });

  const [ orderChanged, setOrderChanged ] = React.useState<boolean>(false);
  const [ newOrderIds, setNewOrderIds ] = React.useState<Array<any> | null>(null);
  const getOriginalOrderId = (): Array<string> => {
    if (loading || !data) {
      return [];
    }
    const {
      shopProductsLists
    } = data;

    const {
        edges
    } = shopProductsLists;

    let productLists = edges.map((edge: any) => edge.node)
    productLists.sort((a: any, b: any) => ((a.position_in_shop || 0) > (b.position_in_shop || 0) ? 1 : -1))

    return productLists.map((p: any) => (p.uuid))
  }

  const onClickSaveNewOrder = async () => {
    if (!newOrderIds) {
      return;
    }
    await Promise.all(newOrderIds.map(async (uuid: string, index: number) => {
      await updateProductsList({
          variables: {
              uuid,
              productsList: {
                position_in_shop: index
              }
          }
      });
    }))
    setOrderChanged(false);
  }

  return (
    <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

      <Helmet title="Liste de produits" />

      <FlexRowSpaceBetween>
          <Title>
            Listes de produits
          </Title>
          <div>
            <Button
              variant={'contained'}
              color={'primary'}
              disabled={!orderChanged}
              style={{marginRight: '10px'}}
              onClick={onClickSaveNewOrder}
            >Sauvegarder l'ordre</Button>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                navigate('/create-productslist')
              }}
            >Créer une nouvelle liste</Button>
          </div>
      </FlexRowSpaceBetween>

      <Divider />

      <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

          {loading ? <div>Loading...</div> : (
              <DataTable
                  data={data}
                  columns={columns}
                  actions={
                    [...actions, 
                        { key: 'edit', icon: <Edit />, onClick: (uuid: any) => navigate('/productslist/'+uuid) },
                        { key: 'delete', icon: <Delete />, onClick: (uuid: any) => deleteAfterConfirm(uuid) },
                    ]}
                  fetchMore={fetchMore}
                  orderIds={newOrderIds || getOriginalOrderId()}
                  onOrderChanged={(result: any) => {
                    setNewOrderIds(
                      reorder(newOrderIds || getOriginalOrderId(), result.source.index, result.destination.index)
                    )
                    setOrderChanged(true);
                  }}
              />
          )}
          

      </div>
    </div>
  );
}