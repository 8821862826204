import React from 'react';
import '../node_modules/react-vis/dist/style.css';
import { ApolloProvider } from '@apollo/client/react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import Helmet from 'react-helmet';
import { StylesProvider, ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider } from "styled-components";
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'

import getGraphQlClient from 'AppCore/Api/GraphQL';
import Init from './AppCore/init'
import maTheme from "./AppCore/theme";
import Router from './Apps/Shared/Router';
import { getAppNameFromHost } from 'AppCore/configs'
import { DialogProvider } from 'AppCore/Components/Dialog'

import './App.css';

const APP_NAME = getAppNameFromHost()

const {
    store: { store, persistor },
    AppConfig
} = Init({ APP_NAME })

export const ConfigContext = React.createContext(AppConfig);

function App() {

    return (
        <ConfigContext.Provider value={AppConfig}>
            <ApolloProvider client={getGraphQlClient()}>
                <Helmet
                    titleTemplate={"%s | " + AppConfig.title}
                    defaultTitle={AppConfig.defaultTitle}
                />
                <StylesProvider injectFirst>
                    <MuiThemeProvider theme={maTheme[APP_NAME] || maTheme.darkVariant}>
                        <ThemeProvider theme={maTheme[APP_NAME] || maTheme.darkVariant}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Provider store={store}>
                                    <PersistGate loading={null} persistor={persistor}>
                                        <DialogProvider />
                                        <SnackbarProvider
                                            maxSnack={2}
                                            autoHideDuration={1500}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Router APP_NAME={APP_NAME} />
                                        </SnackbarProvider>
                                    </PersistGate>
                                </Provider>
                            </MuiPickersUtilsProvider>
                        </ThemeProvider>
                    </MuiThemeProvider>
                </StylesProvider>
            </ApolloProvider>
        </ConfigContext.Provider>
    );
}

export default App;
