import React from 'react';
import { gql, useMutation } from '@apollo/client';

import View from './index'
import { useNavigate } from 'react-router-dom';

const CREATE_MUTATION = gql`
  mutation CreateProductsListsList($productsListsList: ProductsListsListCreate!) {
    createProductsListsList(productsListsList: $productsListsList) {
      id, key, title, productslist_ids, productsLists { id, title }
    }
  }
`;

export default function CreateProductsListsListPage() {

  const navigate = useNavigate();
  const [createProductsListsList] = useMutation(CREATE_MUTATION);

  return (
    <View
      layout={'create'}
      productsListsList={{}}
      update={async (data: any) => {
        await createProductsListsList({
          variables: {
            productsListsList: data
          }
        });

        navigate('/productslists-list');
      }}
    />
  )
}