import React from "react";
import styled from "styled-components";

import Logo from 'Assets/logo/logo-picto.png'

const Container = styled.div`
    background: ${props => props.theme.sidebar.background};
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    img {
        margin-right: 8px;
    }
    span {
        margin-top: -10px;
        color: white;
        font-size: 25px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
`


export default () => (
    <Container>
        <img src={Logo} style={{width: '30px'}} alt="logo"/> <span>i-wantit</span>
    </Container>
)