import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Logo from 'Apps/Admin/Components/Logo';
import { Paper } from '@material-ui/core';
import { Plus } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import { ArrowForward } from '@material-ui/icons';
import useShopList from 'AppCore/react-hooks/useShopList';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://i-wantit.com/">
                I Want It
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '10px'
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    button: {
        margin: theme.spacing(3, 0, 2),
        justifyContent: 'space-between'
    },
}));


function ShopButton({ shop }) {

    const { name }  = shop;

    const classes = useStyles();
    const { setCurrentShop } = useCurrentShop();

    return (
        <Button
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={() => {
                console.log("SET SHOP", shop);
                setCurrentShop(shop);
            }}
        >{name} <ArrowForward /> </Button>
    )
}

export default function ChooseShop() {
    const classes = useStyles();
    const navigate = useNavigate();

    const { shops, loading } = useShopList();

    console.log("shopsshopsshops", shops);

    const onClickCreate = () => {
        navigate('/shop-choose/create');
    }

    if (loading) {
        return null;
    }

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Paper className={classes.paper}>
                <Logo dark />
                {shops.length > 0 && (
                <Typography component="h1" variant="h5">
                    Choisissez une boutique
                </Typography>
                )}
                
                {shops.map(shop => (
                    <ShopButton key={shop.id} shop={shop} /> 
                ))}
                
                    
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onClickCreate}
                >
                    Créer une nouvelle boutique <Plus /> 
                </Button>
            </Paper>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}