import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@material-ui/core';
import { Divider } from '../../../Utils/layout';
import InfosForm from './Components/InfosForm';

// @ts-ignore
import FpsSectionsList from './Components/List';

export default function FpsPageView({
  fpsPage,
  save,
  layout = 'edit'
}: {
  fpsPage: any,
  save: (data: any) => Promise<any>,
  layout?: 'edit' | 'create'
}) {

  const Form = (
    <Box
      style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
      }}
    >
      <Grid container spacing={3} justify={'space-around'} alignItems={'stretch'}>
        <Grid item xs={12} md={12}>
          <InfosForm
            layout={layout}
            fpsPage={fpsPage}
            update={save}
          />
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      <Helmet>
        <title>{fpsPage.title}</title>
      </Helmet>

      <h1>{fpsPage.title}</h1>
      <Divider style={{
        marginBottom: '10px'
      }} />

      <Grid container>
        <Grid item xs={12}>
          {Form}
        </Grid>
        <Divider style={{marginTop: '20px', marginBottom: '20px', width: '100%'}}/>
        {layout === 'edit' && (
          <Grid item xs={12}>
            <FpsSectionsList
                sections={[]}
                fpsPage={fpsPage}
                save={save}
              />
          </Grid>
        )}
      </Grid>
    </>
  )
}