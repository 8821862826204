import React from "react";
import Selector from "../GenericSelector"
import { gql, useQuery } from '@apollo/client';


const CORE_SHOPS_LIST_FIELDS = gql`
fragment CoreShopsListFields on ShopList{
    pageInfo {
        hasNextPage
        endCursor
    }
    edges {
        cursor
        node {
            id,
            uuid,
            name,
          }
        }
    }`;

const GET_ALL_SHOPS = gql`
    ${CORE_SHOPS_LIST_FIELDS}
        query getShops($after: String, $first: Int) {
            shops(after: $after, first: $first) {
                ...CoreShopsListFields      
            }
        }
    `;

export default ({...props}) => {

  const {
    data,
    loading
  } = useQuery(GET_ALL_SHOPS, {
    fetchPolicy: 'cache-and-network',
        variables: {
            after: "",
            first: 1000
        },
  })

  if(loading){
    return (
      <p>loading ...</p>
    )
  }

  const {
    shops : {
      edges
    } 
  } = data ;

  const options = edges.map(edge => {
    return {
      value: edge.node.uuid,
      description: edge.node.name
      }
    }
  )

  return(
    <Selector
      options={options}
      {...props}
     />
  )
}