import React from 'react';
import { gql, useMutation } from '@apollo/client';

import ShopOfferPageView from './index'
import { useNavigate } from 'react-router-dom';

const CREATE_MUTATION = gql`
  mutation CreateShopOffer($shopOffer: ShopOfferCreate!) {
    createShopOffer(shopOffer: $shopOffer) {
      id, 
      seller, 
      uuid,
    }
  }
`;

export default function CreateShopOfferPage() {

  const navigate = useNavigate();
  const [createShopOffer] = useMutation(CREATE_MUTATION);

  return (
    <ShopOfferPageView
      layout={'create'}
      shopOffer={{}}
      save={async (data: any) => {

        try {
          await createShopOffer({
            variables: {
              shopOffer: data
            }
          });
        } catch (e: any) {
          alert(e?.message || "une erreur est survenue")
          console.error(e);
          return

        }
        

        navigate('/shop-offers');
      }}
    />
  )
}