import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@material-ui/core';



export default function CardSection({
    children,
    title = null,
    subheader = null,
    action = null,
    bottomAction = null,
    ...props
}) {
    return (
        <Card {...props}>
            <CardHeader
                action={action}
                title={title}
                subheader={subheader}
            />
            <Divider />
            <CardContent>
                <Box
                    style={{
                        position: 'relative'
                    }}
                >
                    {children}
                </Box>
            </CardContent>
            {bottomAction && (
                <>
                    <Divider />
                    <Box
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '16px'
                        }}
                    >
                        {bottomAction}
                    </Box>
                </>
            )}
            
        </Card>
    )
}