import React from "react";
import { Card, CardContent, CardHeader, Divider} from "@material-ui/core";
import Filters from './Components/filters'
import { useQuery, gql  } from "@apollo/client";
import dateformat from 'dateformat';
import WantsCountByProductList from './view'

const CORE_PRODUCTS_LIST_FIELDS = gql`
    fragment CoreProductsListFields on ProductList{
        pageInfo {
            hasNextPage
            endCursor
        }
        edges {
            cursor
            node {
                id
                title
                image
                market_url
                market_id
                master_category
                wantsCount
                customWantsCount
                category {key, names}
                status
                price
            }
        }
    }
    `

const GET_WANTS_COUNT_BY_PRODUCT = gql`
  ${CORE_PRODUCTS_LIST_FIELDS}
  query wantsProductsList(
    $after: String, 
    $first: Int,
    $startDate: String,
    $endDate: String,
    $shop_uuid: String, 
    $product_category: String,
    $user_isChild: Boolean,
    $user_ageRange: String,
    $user_gender: String
  ){
    products: wantsProductsList(
      after: $after, 
      first: $first, 
      shop_uuid: $shop_uuid, 
      product_category: $product_category,
      user_isChild: $user_isChild,
      user_ageRange: $user_ageRange,
      user_gender: $user_gender,
      startDate: $startDate,
      endDate: $endDate
    ){
      ...CoreProductsListFields
    }
  }
`;

export default ({
  shopUuid = null
}: {
  shopUuid : String | null
}
) => {
  const ONE_DAY = 86400000;
  const initialEndDate = new Date();
  const initialStartDate = new Date();
  initialStartDate.setTime(initialEndDate.getTime() - ONE_DAY*7);

  const initialData = {
    startDate: dateformat(initialStartDate, 'yyyy-mm-dd'),
    endDate: dateformat(initialEndDate, 'yyyy-mm-dd'),
    ...(shopUuid ? {"shop_uuid": shopUuid} : {})
  }

  const [filters, setFilters] = React.useState(initialData)
  console.log('Filters',filters);

  const {
    loading,
    data,
    fetchMore
  } = useQuery(GET_WANTS_COUNT_BY_PRODUCT, {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: "cache-first",
      variables: {
          after: "0",
          first: 100,
          ...filters
      },
  })

  return (
      <Card>
        <CardHeader
            title={"Produits les plus wantés"}
        />
      <Divider />
        <CardContent>
        <Filters
          initialData={initialData}
          update={setFilters}
        />
      </CardContent>
      <Divider />
      <CardContent>

        { loading
            ? <div>Loading ...</div>
            : <WantsCountByProductList
                data={data}
                columns={['customWantsCount', 'image', 'id', 'title', 'price', 'wantsCount', 'market_url', 'category']}
                actions={[]}
                fetchMore={fetchMore}
                />
        }

      </CardContent>
        
      </Card>
  )
}