import { ModelObject } from "./ModelObject"

export class Product extends ModelObject {

  getPrimaryKey() {
    return this.id;
  }
  
  getImageUrl(): string | null {
    if (this.image) {
      return this.image
    }
    return null;
  }

  id?: number

  title?: string
  image?: string
  status?: string

}