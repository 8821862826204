import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Bar } from 'test-react-chartjs-2';
import { useNavigate } from "react-router";


const GET_WANTS_SHOPS_HISTOGRAM = gql `
  query getWantsShopsHistogram($startDate: String, $endDate: String) {
    histogram: wantsShopsHistogram(startDate: $startDate, endDate: $endDate) {
        total,
        data { key, label, value }
    }
}
`;

export default function ShopsHistogram({
  startDate,
  endDate,
}){

  const {
    data: {histogram = {}} = {}
  } = useQuery(GET_WANTS_SHOPS_HISTOGRAM,{
    variables:{
      startDate,
      endDate,
    }
  })


  const navigate = useNavigate()

  const datasets = [
    {
      label: 'Wants',
      data: histogram?.data?.map(d=>d.value),
      backgroundColor: 'rgb(255, 99, 13)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    }
  ];


  return(
    <Bar
      height={400}
      key={1}
      data={{
          labels: histogram?.data?.map(d => d.label),
          datasets
      }}
      options={{
          maintainAspectRatio: false,
          interaction: {
              intersect: false
          },
          scales: {
              yAxes: [
                  {
                      ticks: {
                          beginAtZero: true,
                      },
                  },
              ],
          },
          onClick: function(evt, element) {
            navigate(`/shop/${histogram?.data[element[0]['index']].key}`)
          }
      }}
  />
  )
}