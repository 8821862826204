import Constants from "../Constants";
import Api from 'AppCore/Api';
import { gql } from '@apollo/client';
import client from 'AppCore/Api/GraphQL'

const { REDUCER: C } = Constants;

export const login = ({ email, password }) => async (dispatch, getState) => {

	const authData = await Api.login({
		email,
		password
	});

	dispatch({
		type: C.SET_AUTH_DATA,
		authData: {
			access_token: authData.token,
		}
	});
	dispatch({
		type: C.SET_CURRENT_USER,
		current_user: authData
	});
}

const GET_ME = gql`
        query getMe {
					me {
						canSeeProfile
						id
						parent_id
						_id
						private_account
						shareUrl
						email
						facebookId
						appleId
						googleId
						firstname
						lastname
						full_name
						desc
						avatar
						gender
						birthday
						onBoarding
						role
						wantsCount
						followingsCount
						followingsShopsCount
						followersCount
						childrenCount
						created_at
					}
        }
    `;


export const loginWithIWTToken = (token) => async (dispatch, getState) => {

	await dispatch({
		type: C.SET_AUTH_DATA,
		authData: {
			access_token: token,
		}
	});


	const { data, error } = await client().query({query: GET_ME});

	if(error || !data || !("me" in data)) {
		console.error('Auth: GET_ME error', error, data);
		dispatch(logout());
		throw new Error('Auth: GET_ME error');
	}

	dispatch({
		type: C.SET_CURRENT_USER,
		current_user: {
			...data.me,
			token,
			"count_followers": data.me.followersCount,
			"count_followings": data.me.followingsCount,
		}
	});

}

		


export const logout = () => (dispatch, getState) => {
    dispatch({
		type: C.SET_AUTH_DATA,
		authData: {}
	});
	dispatch({
		type: C.SET_CURRENT_USER,
		user: {}
	});
	dispatch({
		type: C.CLEAR_CURRENT_SHOP
	});
}