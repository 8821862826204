import React from "react";
import Helmet from 'react-helmet';
import { Box, Grid, Container } from "@material-ui/core";

import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import useAdminType  from 'AppCore/react-hooks/useAdminType'

import CardHistoTemp from 'Apps/Admin/Components/CardDiagram/CardHistoTemp'
import WantsHistogram from 'Apps/Admin/Components/WantsHistogram'
import ShopsHistogram from "Apps/Admin/Components/ShopsHistogram";

import { useLocation } from 'react-router-dom';

import WantsCountList from "./List";

export default () => {

    const {currentShop, setCurrentShop} = useCurrentShop();
    const TYPE = useAdminType();
    const location = useLocation();

    const isShopPage = location.pathname.includes(currentShop?.uuid);

    if(TYPE === "ADMIN" && !isShopPage){
        setCurrentShop(null);
    }

    return (
        <Box
        style={{
            minHeight: '100%'
        }} >

            <Helmet title="Wants" />

            <Container maxWidth={false} >
                <Grid container spacing={3}>

                    <Grid item xs={12}>
                        <CardHistoTemp
                            title="Wants par jour">
                                {/*startDate and endDate props mandatory with WantsHistogram components are handle by CardHistoTemp  */}
                                <WantsHistogram
                                    shopUuid={currentShop?.uuid}
                                />
                        </CardHistoTemp>
                    </Grid>

                    {
                        TYPE === "ADMIN" && !isShopPage &&
                        <>
                            <br />
                            <Grid item xs={12}>
                                <CardHistoTemp
                                    title="Wants par shop">
                                        {/*startDate and endDate props mandatory with WantsHistogram components are handle by CardHistoTemp  */}
                                        <ShopsHistogram
                                        />
                                </CardHistoTemp>
                            </Grid>

                        </>
                    }

                    <Grid item xs={12}>
                        <WantsCountList 
                            shopUuid={currentShop?.uuid}
                        />
                    </Grid>
                

                </Grid>
            </Container>
        </Box>

    );
}