import Api from 'Meth/api/request';

export default {

    uploadImage: ({
      file,
      onUploadProgress
    }: {
      file: File | Blob,
      onUploadProgress?: (progress: { total: number, loaded: number }) => any
    }) => {

      const bodyFormData = new FormData();
      bodyFormData.append('uploadfile', file);

      return Api.call({
        uri : `upload`,
        request_method: Api.request_method.POST,
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress
        }
      })
    }
}