import React from "react";
import Helmet from 'react-helmet';

import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'


import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { Title } from 'Apps/Admin/Layouts/Components/Page'


import ProductsView from './view'

export default () => {

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <Helmet title="Shops" />

            <FlexRowSpaceBetween>
                <Title>
                    Shops
                </Title>
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <ProductsView/>

        </div>
    );
}