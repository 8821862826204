import React, { useEffect } from "react";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import { removeSlashesFromPath } from 'AppCore/Utils';

import AdminRoutes from '../Admin/routes'
import ShopAdminRoutes from '../Admin/routesShopAdmin'
import { LogEvent } from 'AppCore/Libs/Log'
import { useCurrentUser } from "AppCore/react-hooks/useCurrentUser";
import useAdminType, { TYPE_ADMIN, TYPE_ADMIN_SHOP } from "AppCore/react-hooks/useAdminType";
import { useCurrentShop } from "AppCore/react-hooks/useCurrentShop";

let routesConfig = {
    config: {
        redirectSigninPath: '/auth/sign-in',
        redirectHomePath: '/home'
    },
    routes: []
}

const AuthSelector = createSelector(
    state => state.Auth,
    Auth => !!(Auth && Auth.current_user)
);

export const CheckRouteElement = ({ children, config, route, ...props }) => {

    const userIsLogged = useSelector(AuthSelector)
    const currentUser = useCurrentUser();
    const adminType = useAdminType();
    const { currentShop: shop } = useCurrentShop();
    const location = useLocation();

    if (route.canAccess && !route.canAccess(currentUser)) {
        return <Navigate replace to={config.redirectForbiddenPath || config.redirectHomePath} />
    }

    if (route.redirectSignin || (route.needUser && !userIsLogged)) {
        return <Navigate replace to={config.redirectSigninPath} />
    }

    if (route.redirectHome || (route.needLogout && userIsLogged)) {
        return <Navigate replace to={config.redirectHomePath} />
    }

    if (
        location.pathname !== config.redirectForbiddenPath 
        && adminType === TYPE_ADMIN 
        && currentUser && currentUser.role !== 4) {
        return <Navigate replace to={config.redirectForbiddenPath} />
    }

    if (route.needShop && route.needShop.redirectTo &&  adminType === TYPE_ADMIN_SHOP && !shop) {
        return <Navigate replace to={route.needShop.redirectTo} />
    }

    return children || <Outlet />
}

export const RenderRoutes = ({ routes, config, parentPath = '', current_user = null }) => {

    return routes.map(({ element: Element, routes = null, path, ...route }) => {

        const absolutePath = '/' + removeSlashesFromPath(parentPath + "/" + path);

        return (
            <Route key={absolutePath} path={path} element={
                <CheckRouteElement config={config} route={{...route, path}}>
                    {Element && <Element/>}
                </CheckRouteElement>
            }>
                {routes &&
                    RenderRoutes({ routes, config, parentPath: parentPath + "/" + path, current_user })
                }
            </Route>
        )
    })

};

export const getRoutesConfig = () => (routesConfig);
export const setRoutesConfig = config => {
    routesConfig = config;
}

export const RoutesContext = React.createContext(getRoutesConfig());

export default ({ APP_NAME }) => {

    let location = useLocation();
    const adminType = useAdminType();

    let config;
    switch(APP_NAME) {
        case 'ADMIN':
            config = adminType === TYPE_ADMIN ? AdminRoutes : ShopAdminRoutes
            break;
        default:
            throw new Error("No router defined for " + APP_NAME)
    }

    setRoutesConfig(config);

    useEffect(() => {
        console.log("ROUTE", location.pathname);
        LogEvent('navigate', { path: location.pathname })
    }, [location.pathname])

    return (
        <RoutesContext.Provider value={getRoutesConfig()}>
            <Routes>
                {RenderRoutes(getRoutesConfig())}
            </Routes>
        </RoutesContext.Provider>
    )
}