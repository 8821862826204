import { init as initAdmin, onStoreReady as onStoreReadyAdmin } from 'Apps/Admin/init'

const defaultInit = () => ({
    storeExtraArguments: {}
})

export default (APP_NAME) => {
    switch(APP_NAME) {
        case 'ADMIN':
            return {
                init: initAdmin,
                onStoreReady: onStoreReadyAdmin
            };
        default:
            return {
                init: defaultInit,
                onStoreReady: ({ store }) => {}
            };
    }
}