import React  from 'react';

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'

import { Button } from '@material-ui/core';

import ProductsView from '../ProductList/view'
import { Product } from 'AppCore/Types/Product';

const DialogProducts = ({
    filterIds = [],
    onClose,
    onSelectProduct = () => {},
    onSelect = () => {}
}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Products</Typography>
            </DialogTitle>
            <DialogContent>

                <ProductsView
                    filterIds={filterIds}
                    actions={[
                        (productData) => {
                            const product = new Product();
                            product.setRawData(productData);
                            return (
                                <>
                                <Button key="select" onClick={() => {
                                    onSelectProduct(product, { close: null })
                                    onSelect(product, { close: null })
                                }}>Ajouter à la liste</Button>
                                <Button key="selectAndClose" onClick={() => {
                                    onSelectProduct(product, { close: onClose })
                                    onSelect(product, { close: onClose })
                                }}>Ajouter et fermer</Button>
                                </>
                            )
                        }
                    ]}
                    columns={['image', 'title', 'wantsCount', 'price', 'market_url' ]}
                />

            </DialogContent>
        </Dialog>
    );
}

export const useOpenProductListModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({
        onSelectProduct = (product, props) => {},
        onSelect = (product, props) => {},
        filterIds = []
    }) => openDialogComponent(
        ({ close }) => (
            <DialogProducts filterIds={filterIds} onSelect={onSelect} onSelectProduct={onSelectProduct} onClose={close} />
        )
    )
}