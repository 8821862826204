import Api from 'Meth/api/request';
import { checkParam } from './helpers';
import Product from './Product';
import User from './User';
import Want from './Want';
import Upload from './Upload';

export default {

	urlExplorer: ({ url }) => {
		return Api.call({
			uri : `url-explorer`,
			request_method: Api.request_method.POST,
			data: {
				url
			}
		})
	},

	login: async ({ email, password } = {}) => {

		checkParam(email, 'string', 'email', true)
		checkParam(password, 'string', 'password', true)

		return await Api.call({
			uri : `auth/login-old`,
			request_method: Api.request_method.POST,
			data: {
				email,
				password
			}
		});

	},
	...Product,
	...Want,
	...User,
	...Upload
}