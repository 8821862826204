import React from "react";
import { Navigate, Outlet } from 'react-router-dom';
import styled, { createGlobalStyle } from "styled-components";

import { CssBaseline } from "@material-ui/core";
import { useCurrentShop } from "AppCore/react-hooks/useCurrentShop";

const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${props => props.theme.body.background};
  }
`;

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`;

export default function ShopChooserContainer() {

    const { currentShop } = useCurrentShop()

    if (currentShop) {
        return <Navigate to="/" replace />
    }

    return (
        <Root>
            <CssBaseline />
            <GlobalStyle />
            <Outlet/>
        </Root>
    );
}
