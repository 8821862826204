import React from "react";
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import { Title } from "Apps/Admin/Layouts/Components/Page";
import { Helmet } from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";
import ActivityIndicator from "AppCore/Components/ActivityIndicator";
import { FpsSection, FpsSectionItem } from "AppCore/Types/FpsSection";
import MixedObjectList from "Apps/Admin/Components/MixedList";
import { useOpenProductListModal } from "Apps/Admin/Components/ProductSelector/ModalSelector";
import { Product } from "AppCore/Types/Product";
import { ShopModel } from "AppCore/Types/Shop";
import { User } from "AppCore/Types/User";
import { useOpenShopsListModal } from "Apps/Admin/Components/ShopSelector/ModalSelector";
import { useOpenUsersListModal } from "Apps/Admin/Components/UserSelector/ModalSelector";


const GET_SECTION = gql`
  query GetFpsSection($uuid: String!) {
    fpsSection(uuid: $uuid) {
      id
      page_key
      visible
      position_in_page
      uuid
      title
      type
      items {
        type
        primary_key
        layout
        object {
          ... on User {
            id
            avatar
            full_name
          }
          ... on Product {
            id
            title
            image
          }
          ... on Shop {
            id
            uuid
            name
            logo
          }
        }
      }
      
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateSection($uuid: String!, $fpsSection: FpsSectionUpdate!) {
    updateFpsSection(uuid: $uuid, fpsSection: $fpsSection) {
      id
      page_key
      visible
      position_in_page
      uuid
      title
      type
      items {
        type
        primary_key
        layout
        object {
          ... on User {
            id
            avatar
            full_name
          }
          ... on Product {
            id
            title
            image
          }
          ... on Shop {
            id
            uuid
            name
            logo
          }
        }
      }
    }
  }
`;

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const getFpsSectionFromData = (data: any) => {
  let fpsSection: FpsSection | null = null;
  let fpsSectionItems: Array<FpsSectionItem> | null = null;
  if (data) {
    const { fpsSection: fpsSectionRaw } : { fpsSection: FpsSection } = data;
    fpsSection = new FpsSection();
    fpsSection.setRawData(fpsSectionRaw);
    fpsSectionItems = fpsSection.items || null;
  }

  return {
    fpsSection, fpsSectionItems
  }
}

export default function SectionEditListItemsPage() {
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [ sectionItems, setSectionItems ] = React.useState<Array<FpsSectionItem>>([]);
  const [ changed, setChanged ] = React.useState<boolean>(false);
  const [ loading, setLoading ] = React.useState<boolean>(false);

  const openProductSelector = useOpenProductListModal();
  const openShopSelector = useOpenShopsListModal();
  const openUserSelector = useOpenUsersListModal();

  const {
    loading: fpsSectionLoading,
    data,
  } = useQuery(GET_SECTION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid
    },
  });
  const [ updateFpsSection ] = useMutation(UPDATE_MUTATION, {
    ignoreResults: true,
    fetchPolicy: 'no-cache',
    update: () => {}
  });

  const firstLoading = fpsSectionLoading && !data;

  React.useEffect(() => {
    const { fpsSectionItems } = getFpsSectionFromData(data); 
    if (fpsSectionItems) {
      setSectionItems(fpsSectionItems);
    } else {
      setSectionItems([]);
    }
  }, [data])

  const { fpsSection } = getFpsSectionFromData(data); 
  if (!fpsSection || firstLoading) {
    return <ActivityIndicator />
  }

  const updateSection = async () => {
    setLoading(true);
    await updateFpsSection({
      variables: {
        uuid,
        fpsSection: {
          items: sectionItems.map(sectionItem => ({
            type: sectionItem.type,
            layout: sectionItem.layout,
            primary_key: sectionItem.primary_key,
          }))
        }
      }
    })
    setChanged(false);
    setLoading(false);
  }

  const openSelector = (type: "Product" | "Shop" | "User") => () => {

    let selectorFct: any;
    switch (type) {
      case 'Product':
        selectorFct = openProductSelector;
        break;
      case 'Shop':
        selectorFct = openShopSelector;
        break;
      case 'User':
        selectorFct = openUserSelector;
        break;
      default: 
        return null;
    }

    selectorFct({
      filterIds: sectionItems.map(sectionItem => sectionItem.primary_key),
      onSelect: (element: Product | ShopModel | User, { close }: any) => {
        console.log("elementelement", element);
        const fpsSectionItem: FpsSectionItem = new FpsSectionItem();

        fpsSectionItem.setRawData({
          type: type.toLowerCase(),
          layout: "",
          primary_key: element.getPrimaryKey(),
          object: {
            ...element,
            __typename: type
          }
        });

        const isInList = (list: any[], item: any)=> {
          let alreadyInList = false;
          list.every(i=>{
            if(i?.primary_key === item?.primary_key && i?.type == item?.type ){
              alreadyInList = true;
              return false;
            }
            return true;
          })
          return alreadyInList
        }

        setSectionItems(sectionItems => {
          if(!isInList(sectionItems,fpsSectionItem)) {
            return [
              ...sectionItems,
              fpsSectionItem
            ]
          } else {
          return sectionItems
        }
      });


        if(close !== null){
          close();
        }
        console.log('section',sectionItems)
        setChanged(true);
      }
    })
  }

  const onOrderChanged = (result: any) => {
    const newOrder = reorder(sectionItems, result.source.index, result.destination.index);
    setSectionItems(newOrder);
    setChanged(true);
  }

  const onClickDelete = (id: number, element: any) => {
    const result = Array.from(sectionItems)
    const startIndex = sectionItems.findIndex(el => element.getPrimaryKey() === el.primary_key);
    if (startIndex < 0) {
      return;
    } 
    result.splice(startIndex, 1)

    setSectionItems(result);
    setChanged(true);
  }

  return (
    <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
      <Helmet title={fpsSection.title} />

      <FlexRowSpaceBetween>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => {
            navigate(-1)
          }}
        >Retour</Button>
      </FlexRowSpaceBetween>

      <Divider />

      <FlexRowSpaceBetween>
        <Title>
          {fpsSection.title}
        </Title>
        <div>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={openSelector('Shop')}
            style={{marginRight: '10px'}}
            disabled={fpsSectionLoading}
          >Ajouter Boutique</Button>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={openSelector('User')}
            style={{marginRight: '10px'}}
            disabled={fpsSectionLoading}
          >Ajouter Utilisateur</Button>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={openSelector('Product')}
            style={{marginRight: '10px'}}
            disabled={fpsSectionLoading}
          >Ajouter Produit</Button>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              updateSection();
            }}
            disabled={!changed || loading || fpsSectionLoading}
          >Sauvegarder</Button>
        </div>
      </FlexRowSpaceBetween>

      <Divider />

      <Grid container>
        <Grid item xs={12}>
          <MixedObjectList
            actions={[
              { icon_name: 'delete', onClick: onClickDelete }
            ]}
            data={(sectionItems || []).map((sectionItem) => {
              return sectionItem.object
            })}
            reorder
            onOrderChanged={onOrderChanged}
          />
        </Grid>
      </Grid>

    </div>
  )
}