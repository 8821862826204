import React from 'react';
import { Grid } from '@material-ui/core';
import { gql, useMutation, useQuery } from '@apollo/client';

import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import Button from 'AppCore/Components/Button';
import Table from 'Apps/Admin/Components/FpsSectionsList';
import { useOpenModalEditFpsSection } from 'Apps/Admin/Components/Modals/FpsSectionCreate';
import { FpsSection, getSectionTypeDetails } from 'AppCore/Types/FpsSection';
import { useNavigate } from 'react-router';

const GET_ALL = gql`
  query GetFpsPageSections($page_key: String!) {
    fpsSections(page_key: $page_key) {
      id
      page_key
      visible
      position_in_page
      uuid
      title
      type
    }
  }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateSection($uuid: String!, $fpsSection: FpsSectionUpdate!) {
    updateFpsSection(uuid: $uuid, fpsSection: $fpsSection) {
      id
      visible
      position_in_page
      uuid
      title
      type
    }
  }
`;

const DELETE_MUTATION = gql`
  mutation DeleteSection($uuid: String!) {
    deleteFpsSection(uuid: $uuid)
  }
`;

const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function FpsSectionsList({
  fpsPage,
  sections: defaultSections,
  save
}: any) { 

  const {
    loading: fpsSectionsLoading,
    data,
    refetch
  } = useQuery(GET_ALL, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page_key: fpsPage.key
    },
  });
  const [ updateFpsSection  ] = useMutation(UPDATE_MUTATION);
  const [ updateFpsSectionPosition  ] = useMutation(UPDATE_MUTATION, {
    ignoreResults: true,
    fetchPolicy: 'no-cache',
    update: () => {}
  });
  const [ deleteFpsSection ] = useMutation(DELETE_MUTATION);

  const openModalSectionEdit = useOpenModalEditFpsSection();
  const navigate = useNavigate();

  const [ loading, setLoading ] = React.useState<boolean>(false);
  const [ changed, setChanged ] = React.useState<boolean>(false);
  const [ sections, setSections ] = React.useState<Array<any>>(defaultSections || []);

  React.useEffect(() => {
    if (data && data.fpsSections) {
      const sections = [...data.fpsSections];
      sections.sort((a: any, b: any) => {
        return a.position_in_page > b.position_in_page ? 1 : -1;
      })
      setSections(sections);
    }
  }, [data])

  const onClickDelete = async (id: number, fpsSection: any) => {
    if (!window.confirm("Etes-vous sur de vouloir supprimer cette section ? " + fpsSection.title)) {
      return;
    }
    await deleteFpsSection({
      variables: {
        uuid: fpsSection.uuid
      }
    })
    await refetch();
  }

  const onClickSave = async () => {
    setLoading(true);
    await Promise.all(sections.map(async (section: any, index: number ) => {
      await updateFpsSectionPosition({
        variables: {
          uuid: section.uuid,
          fpsSection: {
            position_in_page: index
          }
        }
      })
    }));
    await refetch();
    setLoading(false);
    setChanged(false);
  }

  const onOrderChanged = (result: any) => {
    const newOrder = reorder(sections, result.source.index, result.destination.index);
    setSections(newOrder);
    setChanged(true);
  }

  return (
    <CardSection
      title="Sections"
      subheader={"Ajoutez / Supprimez / reordonnez les sections"}
      action={(
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSave}
            disabled={fpsSectionsLoading || loading || !changed}
            style={{marginRight: '10px'}}
          >Sauvegarder</Button>
          <Button
            variant="contained"
            color="primary"
            disabled={loading || fpsSectionsLoading}
            onClick={() => {
              openModalSectionEdit({
                fpsPageKey: fpsPage.key,
                onSaved: ({ fpsSection, close }) => {
                  console.log("SSAAAVED");
                  close();
                  refetch();
                }
              });
            }}
          >Ajouter une section</Button>
        </div>
      )}
    >

      <Grid container>
          <Grid item xs={12}>
              <Table
                data={sections}
                actions={[
                  (section: FpsSection) => {
                    if (!section.type) {
                      return null;
                    }
                    const sectionTypeDetails = getSectionTypeDetails(section.type);
                    if (!sectionTypeDetails) {
                      return null;
                    }
                    if (!sectionTypeDetails.canSelectItems) {
                      return null;
                    }
                    return (
                      <Button
                        key="edit_list_items"
                        color='primary'
                        variant='contained'
                        onClick={() => {
                          navigate('/flexible-page-system/section/' + section.uuid)
                        }}
                        style={{marginRight: '10px'}}
                      >Editer la liste</Button>
                    )
                  },
                  (section: FpsSection) => section.visible ? (
                    <Button
                      key='unpublish'
                      color='primary'
                      variant='contained'
                      onClick={() => updateFpsSection({
                        variables: {
                          uuid: section.uuid,
                          fpsSection: {
                            visible: false
                          }
                        }
                      })}
                      style={{marginRight: '10px'}}
                    >Désactiver</Button>
                  ) : (
                    <Button
                      key="publish"
                      color='primary'
                      variant='contained'
                      onClick={() => updateFpsSection({
                        variables: {
                          uuid: section.uuid,
                          fpsSection: {
                            visible: true
                          }
                        }
                      })}
                      style={{marginRight: '10px'}}
                    >Publier</Button>
                  ),
                  (section: FpsSection) => (
                    <Button
                      key="edit"
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        openModalSectionEdit({
                          fpsSection: section,
                          fpsPageKey: fpsPage.key,
                          onSaved: ({ close }) => {
                            close();
                            refetch();
                          }
                        });
                      }}
                    >Edit infos</Button>
                  ),
                  { icon_name: 'delete', title: "Supprimer", onClick: onClickDelete }
                ]}
                reorder={true}
                onOrderChanged={onOrderChanged}
              />
          </Grid>
      </Grid>

    </CardSection>
  )
}
