import React from 'react';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import styled from 'styled-components';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { logout } from 'AppCore/Actions'

import {
    Button,
    Grid,
} from "@material-ui/core";

import {
    SidebarFooter,
    SidebarFooterText,
    SidebarFooterSubText
} from 'Apps/Shared/Layouts/Components/Sidebar/StyledComponents'
import { useDispatch } from 'react-redux';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import useAdminType, { TYPE_ADMIN_SHOP } from 'AppCore/react-hooks/useAdminType';

const UnfoldMoreIconIconGrid = styled(Grid)`
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const MenuStyled = styled(Menu)`
    .MuiPaper-root {
        width: 200px;
    }
`

export default ({ currentUser }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const adminType = useAdminType();
    const { currentShop, setCurrentShop } = useCurrentShop();
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <SidebarFooter>
            <Button onClick={handleClick} style={{ width: '100%', textAlign: 'left' }}>
                <Grid container spacing={2}>
                    <Grid item>
                        <SidebarFooterText variant="body2">
                            {adminType === TYPE_ADMIN_SHOP ? currentShop.name : "Admin Backoffice"}
                        </SidebarFooterText>
                        <SidebarFooterSubText variant="caption">
                            {currentUser.email}
                        </SidebarFooterSubText>
                    </Grid>
                    <UnfoldMoreIconIconGrid item>
                        <UnfoldMoreIcon />
                    </UnfoldMoreIconIconGrid>
                </Grid>
            </Button>
            <MenuStyled
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
                <MenuItem onClick={() => {
                    setCurrentShop(null);
                }}>Changer de boutique</MenuItem>
                <MenuItem onClick={() => {
                    dispatch(logout());
                }}>Se déconnecter</MenuItem>
            </MenuStyled>
        </SidebarFooter>
    )
}
// pro-white-with-color.png