import React from 'react';
import { Grid } from '@material-ui/core';

import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import Button from 'AppCore/Components/Button';
import Table from 'Apps/Admin/Components/ProductList';
import { useOpenProductsListListModal } from 'Apps/Admin/Components/ProductsListSelector/ModalSelector';


const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function ProductListsList({
  productsListsList,
  update
}: any) {

  const [ changed, setChanged ] = React.useState<boolean>(false);
  const [ productsLists, setProductsLists ] = React.useState<Array<any>>(productsListsList.productsLists || []);

  const openProductsListSelector = useOpenProductsListListModal();

  const onClickSelectProductsList = () => {
    openProductsListSelector({
      filterIds: productsLists.map(p => p.id),
      onSelect: (productsList: any, { close }: any) => {
        console.log(productsList);
        setProductsLists([
          productsList,
          ...productsLists
        ])
        setChanged(true);
        close();
      }
    })
  };

  const onClickDelete = (id: number, product: any) => {
    const result = Array.from(productsLists)
    const startIndex = productsLists.findIndex(el => el.id === id);
    if (startIndex < 0) {
      return;
    } 
    result.splice(startIndex, 1)

    setProductsLists(result);
    setChanged(true);
  }

  const onClickSave = async () => {
    const productslist_ids = productsLists.map((productsList: any) => productsList.uuid).join(',')
    await update({
      productslist_ids
    })
    setChanged(false);
  }

  const onOrderChanged = (result: any) => {
    const newProductsListsOrder = reorder(productsLists, result.source.index, result.destination.index);
    setProductsLists(newProductsListsOrder);
    setChanged(true);
  }

  return (
    <CardSection
      title="Liste de listes de produits"
      subheader={"Ajoutez / Supprimez / reordonnez les listes"}
      action={(
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSave}
            disabled={!changed}
            style={{marginRight: '10px'}}
          >Sauvegarder</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSelectProductsList}
          >Ajouter une liste</Button>
        </div>
      )}
    >

      <Grid container>
          <Grid item xs={12}>
              <Table
                  data={productsLists}
                  columns={['image', 'id', 'title']}
                  actions={[
                    { icon_name: 'delete', title: "Supprimer", onClick: onClickDelete }
                  ]}
                  reorder={true}
                  onOrderChanged={onOrderChanged}
              />
          </Grid>
      </Grid>

    </CardSection>
  )
}