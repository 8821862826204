import { ModelObject } from "./ModelObject"
import settings from 'AppCore/settings';

export class ShopModel extends ModelObject {

  getPrimaryKey() {
    return this.uuid;
  }

  getImageUrl(): string | null {
    if (this.logo) {
      return settings.image_url_v2 + "/" + this.logo
    }
    return null;
  }

  id?: number
  uuid?: string

  name?: string
  url?: string
  description?: string

  logo?: string | null
  cover?: string | null
}