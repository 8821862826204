class UsualDate {
  
  static toDay(){
      return new Date();
  }

  static yesterday(){
      let yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1)
      return yesterday
  }

  static lastWeek(){
      let lastWeek = new Date();
      lastWeek.setDate(lastWeek.getDate() - 7)
      return lastWeek
  }

  static lastMonth(){
      let lastMonth = new Date();
      lastMonth.setDate(lastMonth.getDate() - 30)
      return lastMonth
  }

  static lastYear(){
      let lastYear = new Date()
      lastYear.setDate(lastYear.getDate() - 365)
      return lastYear
  }

  static last5Years(){
      let last5Years = new Date()
      last5Years.setDate(last5Years.getDate() - 365*5)
      return last5Years
  }

  static formateDate(date) {
    return `${date.getFullYear()}-${(date.getMonth()+1).toString().padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
  }

  static toString(usualDate){
      console.log(this[usualDate]())
    return this.formateDate(this[usualDate]())
  }
}

export { UsualDate }