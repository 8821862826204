import React, { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';

import { Tabs } from '@knxlab/components'

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox'

import Button from 'AppCore/Components/Button'

import {
    Title, Divider,
    Container,
    SubTitle
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import {
    FormContainer,
    GridContainer,
    TabsContainer, TabsPanels
} from './styledComponents'
import useForm from 'AppCore/Components/Form/useForm';
import FormField from 'AppCore/Components/Form/formField';

import Wants from './Wants'
import RoleSelector from 'Apps/Admin/Components/RoleSelector';

const defaultTitle = "Utilisateur sans nom";
export default ({ initialData, saveData }) => {

    const { enqueueSnackbar } = useSnackbar();

    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const {
        data,
        getFormFieldProps,
        getData: getFormData,
        setData: setFormData,
    } = useForm({
        config: {
            full_name: {
                type: 'text',
                validators: ['not_empty']
            },
            firstname: {
                type: 'text',
                validators: ['not_empty']
            },
            lastname: {
                type: 'text',
                validators: ['not_empty']
            },
            role: {
                type: 'text',
                validator: ['not_empty']
            }
        },
        initialData,
        onChange: () => {
            setChanged(true);
        }
    });

    const onClickCancel = () => {
        setFormData(initialData);
    }
    const onClickSave = async () => {
        if (!saveData) {
            return;
        }

        setLoading(true);
        try {
            await saveData(getFormData());
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setChanged(false);
        } catch (e) {
            alert("une erreur est survenue")
        }
        setLoading(false);
    }

    const onSubmit = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    const canSave = () => {
        if (!changed || loading) {
            return false;
        }

        return true;
    }

    return (
        <React.Fragment>
            <Helmet title={data.title || defaultTitle} />
            <Prompt when={changed} message="Vous n'avez pas enregistré vos modifications. Etes-vous sûr de vouloir quitter cette page ?"/>

            <FlexRowSpaceBetween>

                <Title>
                    {data.full_name || initialData.email || defaultTitle}

                </Title>
                <a href={`iwantit://user?user_id=${data.id}`}>{`iwantit://user?user_id=${data.id}`}</a>

                
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <FlexRowSpaceBetween style={{alignItems: 'flex-start'}}>
                <Container style={{flex:1}}>

                    <TabsContainer orientation={"horizontal"}>

                        <Tabs
                            defaultSelected={0}
                            orientation={"horizontal"}
                            variant="scrollable"
                            tabs={[
                                {
                                    label: "Détails",
                                    panel: (
                                        <TabsPanels>
                                            <form onSubmit={onSubmit}>
                                            <GridContainer>

                                                <FormContainer>
                                                    <SubTitle style={{alignSelf: 'flex-start'}}>Infos</SubTitle>
                                                    <Divider my={6} style={{ width: '100%' }} />

                                                    <div>{initialData.email}</div>
                                                    <FormField {...getFormFieldProps('firstname')} disabled style={{width: '100%'}} />
                                                    <FormField {...getFormFieldProps('lastname')} disabled style={{width: '100%'}} />
                                                    <FormField {...getFormFieldProps('full_name')} disabled style={{width: '100%'}} />
                                                    <RoleSelector {...getFormFieldProps('role')} disabled={loading}/>

                                                </FormContainer>
                                            </GridContainer>
                                            </form>
                                            <div>
                                                {saveData && <>
                                                    <Button
                                                        loading={false} disabled={!canSave()} color="primary"
                                                        onClick={onClickCancel} style={{marginRight: '10px'}}
                                                    >{"annuler les modifications".toUpperCase()}</Button>
                                                    <Button
                                                        style={{marginRight: '10px'}}
                                                        loading={false} disabled={!canSave()} color="primary"
                                                        onClick={onClickSave}
                                                    >{"Enregistrer".toUpperCase()}</Button>
                                                </>}
                                            </div>
                                        </TabsPanels>
                                    )
                                },
                                {
                                    label: "Wants",
                                    panel: (
                                        <TabsPanels>
                                            <Wants />
                                        </TabsPanels>
                                    )
                                }
                            ]}
                        />

                    </TabsContainer>

                </Container>

            </FlexRowSpaceBetween>

        </React.Fragment>

    )
}
