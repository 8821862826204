import React from 'react';
import { Button } from '@material-ui/core';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import ImageUploader from 'AppCore/Components/MediaUploader/ImageUploader';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

export default function LogoUpload() {
  const { currentShop, updateCurrentShop } = useCurrentShop();
  const [ changed, setChanged ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);

  const [ imageFileName, setImageFileName ] = React.useState<string | null>(currentShop.logo || null);

  const onClickUpdate = async () => {
    setLoading(true);
    await updateCurrentShop({
      logo: imageFileName
    })
    setLoading(false);
    setChanged(false);
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      title="Logo"
      subheader="Votre logo"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={onClickUpdate}
          disabled={!changed || loading}
        >
          {loading ? <ActivityIndicator material /> : "Sauvegarder"}
        </Button>
      )}
    >

      <ImageUploader
        imageFileName={imageFileName}
        setImageFileName={(fileName: string | null) => {
          console.log("fileNamefileName", fileName);
          setImageFileName(fileName);
          setChanged(true);
        }}

        title="Uploadez votre logo"
        subTitle={"Uploadez votre logo"}
        btnLabel={"Selectionnez une photo"}
        style={{ width: "100%", height: "300px" }}
        canCrop={true}
      />

    </CardSection>
  )
}