
import Query from './Query';

export const createSearchQuery = (data) => {
    const query = new Query();

    if (data.title) {
        query.matchText({
            fieldName: 'title',
            value: data.title
        })
    }

    console.log("query Product : ", query)

    return query;
}