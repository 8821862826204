import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import { Image as ImageIcon } from '@material-ui/icons'

import DataTable from 'AppCore/Components/DataTable';
import Image from "AppCore/Components/Image";

const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`

export default function ProductsListList({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = () => {}
}: any) {

    return <DataTable
        data={data}
        config={{
            key: "uuid",
            columns,
            cells: [
                { title: '', key: 'image', width: 90 },
                { title: 'id', key: 'id' },
                { title: 'Titre', key: 'title' }
            ],
            actionsLabel: "",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ],
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(item: any, attr_name: string) => {

            if (attr_name === 'id') {
                return (
                    <Link to={`/productslist/${item.uuid}`}>{item.id}</Link>
                )
            }

            if (attr_name === "image") {
                return (
                    <ImageContainer>
                        {!item.image &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.image &&
                          <Image
                            filename={item.image}
                            style={{width: '100%'}}
                          />
                        }
                    </ImageContainer>
                )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}