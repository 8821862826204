import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Bar } from 'test-react-chartjs-2';


const GET_WANT_HISTOGRAM = gql`
    query getWantsHistogram($startDate: String, $endDate: String) {
        histogram: wantshistogram(startDate: $startDate, endDate: $endDate) {
            total,
            data { count, date }
        }
    }
`;

const GET_WANT_PRODUCT_HISTOGRAM =  gql`
    query getWantsProductHistogram($startDate: String, $endDate: String, $product_id: Int!) {
        histogram: wantsProductHistogram(startDate: $startDate, endDate: $endDate, product_id: $product_id) {
            total,
            data { count, date }
        }
    }
`;

const GET_WANT_SHOP_HISTOGRAM =  gql`
    query getWantsShopHistogram($startDate: String, $endDate: String, $shop_uuid: String!) {
        histogram: wantsShopHistogram(startDate: $startDate, endDate: $endDate, shop_uuid: $shop_uuid) {
            total,
            data { count, date }
        }
    }
`;

const getHistogramQuery = (startDate, endDate, productId, shopUuid) => {
    const histoType = productId ? "PRODUCT" : shopUuid ? "SHOP" : "ALL";

    let QUERY = {};
    let variables = {};

    switch (histoType) {
        case "PRODUCT":
            QUERY = GET_WANT_PRODUCT_HISTOGRAM;
            variables = getHistogramVariables(histoType,startDate,endDate,productId);
            break;
        case "SHOP":
            QUERY = GET_WANT_SHOP_HISTOGRAM;
            variables = getHistogramVariables(histoType,startDate,endDate,shopUuid);
            break;
        default:
            QUERY = GET_WANT_HISTOGRAM;
            variables = getHistogramVariables(histoType,startDate,endDate);
            break;
    }
    return [QUERY, variables]
}

const getHistogramVariables = (histoType, startDate, endDate, value = null) => {
    let variables = {
        startDate,
        endDate,
    }
    switch (histoType) {
        case "PRODUCT":
            variables.product_id = value;
            break;
        case "SHOP":
            variables.shop_uuid = value;
            break;
        default:
            break;
    }
    return variables
}

export default function WantsHistogram({
    startDate,
    endDate,
    productId = null,
    shopUuid = null,
    addOtherFake
}) {

    const [QUERY, variables] = getHistogramQuery(startDate, endDate, productId, shopUuid);

    const { data 
    }  = useQuery(QUERY, {
        variables,
        fetchPolicy: 'network-only',
    });

    
    const originalData = data?.histogram?.data || [];    
   
    const datasets = [
        {
            label: 'Wants',
            data: originalData.map(d => d.count),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        }
    ];

    if (addOtherFake) {
        datasets.push({
            label: 'Visites',
            data: originalData.map((d, index) => Math.round(Math.random() * 300)),
            fill: false,
            backgroundColor: 'rgb(255, 99, 54)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        })
        datasets.push({
            label: 'Clics',
            data: originalData.map((d, index) => Math.round(Math.random() * 100)),
            fill: false,
            backgroundColor: 'blue',
            borderColor: 'rgba(255, 99, 132, 0.2)',
        })
    }

    return (
        <Bar
            height={400}
            key={1}
            data={{
                labels: originalData.map(d => d.date),
                datasets
            }}
            options={{
                maintainAspectRatio: false,
                interaction: {
                    intersect: false
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            }}
        />
    );
}