export default {

    title: "iwantit Admin",
    defaultTitle: "Iwantit Admin",

    auth: {
        provider: 'custom'
    },

    reduxPersistConfig: {
        whitelist: [
            "Auth",
            "context",
            "user", "Auth", "current_user"
        ]
    }
}