import React from 'react';
import { useOpenProductListModal } from './ModalSelector';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export default ({ product, onChange = (product) => {}, label = "Product", ...props }) => {

    const openProductSelector = useOpenProductListModal();

    const onClickSelect = () => {
        openProductSelector({
            onSelectProduct: (product, { close }) => {
                onChange(product);
                close();
            }
        })
    };

    return (
        <Container {...props}>
            <div>{label}</div>
            {product?.id ? <div>{product.title || product.id}</div> : null}
            <Button onClick={onClickSelect}>
                {product?.id ? "Change" : "Select"}
            </Button>
            {product?.id ? (

            <Button onClick={() => onChange(null)}>
                Supprimer
            </Button>

            ) : null}
        </Container>
    )
}