import React from 'react';
import { useParams } from 'react-router-dom'
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import View from './index'
import { useGetCategory, useUpdateCategory } from 'AppCore/react-hooks/useCategories';

export default () => {

    const { key } = useParams();

    const { category: data, loading } = useGetCategory(key);
    const updateCategory = useUpdateCategory();

    if (loading) {
        return <ActivityIndicator material />;
    }

    return (
        <View
            initialData={{
                ...data,
                names: JSON.parse(data.names)
            }}
            saveData={async (data) => {
                console.log("SAVEEEED", data);
                await updateCategory({
                    variables: {
                        key,
                        category: data
                    }
                })
            }}
        />
    )
}