const BASE_CONFIG = {

	primaryColor: '#FF754C',
	pageBackgroundColor: '#F2F4F7',
	textColor: '#000000',

	app_version: '1.0',

	www_url: "https://www.i-wantit-dev.com",
	default_api_url: "https://api.i-wantit-dev.com",
	graphql_api_uri: "https://api.i-wantit-dev.com/graphql",
	image_url: "https://www.i-wantit-dev.com/images",
	image_url_v2: "https://images.i-wantit.com",
	sentry_dsn: "https://ba2851d174e547da9cb3a815ca8a6f47@sentry.io/1296724'"
};


const CONFIG_DEV = {
	www_url: "https://www.i-wantit-dev.com",
	default_api_url: "https://api.i-wantit-dev.com",
	graphql_api_uri: "https://api.i-wantit-dev.com/graphql",
	image_url: "https://www.i-wantit-dev.com/images",
	image_url_v2: "https://images.i-wantit.com",
};

const CONFIG_PREPROD = {
	www_url: "https://v4.i-wantit-dev.com",
	default_api_url: "https://preprod-api.i-wantit-dev.com",
	graphql_api_uri: "https://preprod-api.i-wantit-dev.com/graphql",
	image_url: "https://www.i-wantit-dev.com/images",
	image_url_v2: "https://images.i-wantit.com",
};

const CONFIG_PROD = {
	www_url: "https://v5.i-wantit.com",
	default_api_url: "https://api.i-wantit.com",
	graphql_api_uri: "https://api.i-wantit.com/graphql",
	image_url: "https://www.i-wantit.com/images",
	image_url_v2: "https://images.i-wantit.com",
};

let CONFIG = BASE_CONFIG;
switch (window.location.hostname) {
	case 'localhost':
	case 'admin.i-wantit-dev.com':
	case 'myshop.i-wantit-dev.com':
		CONFIG = {
			...CONFIG,
			...CONFIG_PREPROD
		};
		break;
	case 'preprod-admin.i-wantit-dev.com':
	case 'preprod-myshop.i-wantit-dev.com':
		CONFIG = {
			...CONFIG,
			...CONFIG_PREPROD
		};
		break;
	case 'admin.i-wantit.com':
	case 'myshop.i-wantit.com':
		CONFIG = {
			...CONFIG,
			...CONFIG_PROD
		};
		break;
	default:
		break;
}

export default CONFIG;