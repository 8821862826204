import React, { useState, useEffect } from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import Table from 'Apps/Admin/Components/ProductList';
import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import ProductSearchForm from 'Apps/Admin/Components/SearchForms/Products'
import { useCurrentShop } from "AppCore/react-hooks/useCurrentShop";
import useAdminType, { TYPE_ADMIN_SHOP, TYPE_ADMIN } from "AppCore/react-hooks/useAdminType";

const CORE_PRODUCTS_LIST_FIELDS = gql`
    fragment CoreProductsListFields on ProductList{
        pageInfo {
            hasNextPage
            endCursor
        }
        edges {
            cursor
            node {
                id
                title
                image
                market_url
                market_id
                master_category
                wantsCount
                category {key, names}
                status
            }
        }
    }
    `

const GET_ALL_PRODUCTS = gql`
    ${CORE_PRODUCTS_LIST_FIELDS}
    query getProducts($after: String, $first: Int, $master_category: String, $shop_uuid: String) {

        products(after: $after, first: $first, master_category: $master_category, shop_uuid: $shop_uuid, status:"PUBLISHED") {
            ...CoreProductsListFields
        }

        draftProducts : products(shop_uuid: $shop_uuid, status:"DRAFT") {
            ...CoreProductsListFields
        }
        
    }
`;

const GET_POPULAR_PRODUCTS= gql`
    ${CORE_PRODUCTS_LIST_FIELDS}
    query getPopularProducts($after: String, $first: Int,) {
        products: popularProducts(after: $after, first: $first) {
            ...CoreProductsListFields
        }
    }
`;

const SEARCH_PRODUCTS = gql`
    ${CORE_PRODUCTS_LIST_FIELDS}
    query searchProducts($after: String, $first: Int, $query: String!) {
        products: searchProducts(after: $after, first: $first,query: $query) {
            ...CoreProductsListFields
        }
    }
`;

const ProductList = ({
    data, columns, actions, fetchMore
}) => {

    if(!data){
        return <p>Waiting for data...</p>
    }

    const { products, draftProducts = [] }  = data;


    const {
        pageInfo,
        edges
    } = products ;

    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={[
                            ...(draftProducts?.edges ? draftProducts.edges.map(edge => edge.node) : []),
                            ...edges.map(edge => edge.node)
                            ]}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            {   
                pageInfo.hasNextPage &&
                    <Button onClick={() => {
                        fetchMore({
                            variables: {
                                after: pageInfo.endCursor,
                                first: 100
                            }
                        })
                    }}>Load More</Button>
            }
        </>
    )
}

export default ({
    actions = [],
    columns = ['image', 'id', 'title', 'wantsCount', 'market_url', 'category', 'status']
}) => {

    const adminType = useAdminType()
    const { currentShop } = useCurrentShop()

    const [queryField, setQueryField] = useState(null)
    const [popularFilter, setPopularFilter] = useState(true);
    const [query,setQuery] = useState(GET_POPULAR_PRODUCTS);

 
    useEffect(()=>{
        if(!queryField && (adminType === TYPE_ADMIN_SHOP)){
            setQuery(GET_ALL_PRODUCTS);
        }
        if(popularFilter && (adminType === TYPE_ADMIN)){
            setQuery(GET_POPULAR_PRODUCTS);
        }
        if(queryField){
            setQuery(SEARCH_PRODUCTS);
        }
    },[popularFilter,queryField])
  

    const {
        loading,
        data,
        fetchMore
    } = useQuery(query, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: "cache-first",
        variables: {
            after: "",
            first: 100,
            ...(queryField ? {query: queryField}: {}),
            ...(
                adminType === TYPE_ADMIN_SHOP ? {
                    shop_uuid: currentShop.uuid
                } : {}
            )
        },
    });

    console.log(data, loading, fetchMore);

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            {
                adminType === TYPE_ADMIN &&
                <>
                    <Grid
                        container
                        spacing={3}
                    >
                        <Grid item  sm={6}  xs={12}>
                            <ProductSearchForm
                                onChangeSearchQuery={console.log}
                                onSubmit={setQueryField}
                                onUse={()=>{setPopularFilter(false)}}
                                disabled={popularFilter}
                                loading={loading} />
                        </Grid>
                        <Grid item sm={6}  xs={12}>
                            <Button
                                variant={'contained'}
                                color={popularFilter ? 'secondary' : 'primary' }
                                onClick={()=>{
                                    setPopularFilter(popularFilter=>!popularFilter)
                                    }}>
                                    {
                                        popularFilter 
                                            ? "Désactiver le filtre de popularité"
                                            : "Filtrer par popularité"
                                    }
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider my={6} />

                </>
            }
            
            {loading ? <div>Loading...</div> : (
                <ProductList
                    data={data}
                    columns={columns}
                    actions={actions}
                    fetchMore={fetchMore}
                />
            )}
            

        </div>
    );
}