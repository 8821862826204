import React from "react";
import { Link } from 'react-router-dom'

import DataTable from 'AppCore/Components/DataTable';

export default ({
    data = [],
    onClickEdit = () => {},
    actions = null
}) => {

    return <DataTable
        data={data}
        getKey={o => o.key}
        config={{
            cells: [
                { title: 'key', key: 'key' },
                { title: 'Nom', key: 'fr' },
                { title: 'Name (en)', key: 'en' }
            ],
            actionsLabel: "Modifier/Supprimer",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'key') {
                return (
                    <Link to={`/categories/${item.key}`}>{item.key}</Link>
                )
            }

            if (item.names && item.names[attr_name]) {
                return item.names[attr_name];
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}