import React from 'react';

import Button from 'AppCore/Components/Button';
import Style from './style.module.css';

type InfosProps = {
	title: string,
	infos: string,
	btnLabel: string,
	btnDisabled: boolean,
	onClick: (e: any) => void,

	actions?: Array<any>
}

export default ({
	title, infos, btnDisabled, btnLabel, onClick = () => {},
	actions = []
}: InfosProps) => (
	<div className={Style.infosContainer}>

		{title && <div className={Style.title}>
			{title}
		</div>}
		{infos && <div className={Style.infos}>
			{infos}
		</div>}

		<div className={Style.btnUploadContainer}>
			<Button
				color="secondary" variant="outlined" disabled={btnDisabled}
				onClick={onClick}
			>{btnLabel}</Button>
		</div>

		{actions}

	</div>
)