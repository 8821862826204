import React from 'react'
import { Button, Grid } from "@material-ui/core";
import Table from 'Apps/Admin/Components/ProductList';

export default ({
  data,
  columns,
  actions,
  fetchMore
}:{
  data: any,
  columns: any,
  actions: any,
  fetchMore: any
}) => {

  if(!data){
    return null
  }

  const { 
    products : {
      pageInfo,
      edges
    } } = data;

  console.log(edges.map((edge: any) => edge.node));
  console.log(columns)

  return (
    <>
    <Grid container>
        <Grid item xs={12}>
            <Table
                data={edges.map((edge: any) => edge.node)}
                columns={columns}
                actions={actions}
            />
        </Grid>
    </Grid>

    {   
        pageInfo.hasNextPage &&
            <Button onClick={() => {
                fetchMore({
                    variables: {
                        after: pageInfo.endCursor,
                        first: 100
                    }
                })
            }}>Load More</Button>
    }
</>
  )
}