import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';

export const FlexRowSpaceBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`

export const FlexRow = styled.div`
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
`
export const FlexRowCentered = styled(FlexRow)`
    justify-content: center;
`
export const FlexRowEnd= styled(FlexRow)`
    justify-content: flex-end;
`

export const FlexColumnCentered = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


const FullWidthRatioContainer = styled.div`
    display: flex;
    width: 100%;
`
export const FullWidthRatio = ({ children, ratio = 4/5, style, ...props }) => {
    const viewport = useRef(null);
    const [ height, setHeight ] = useState(0);

    const onWindowResize = () => {
        if (!viewport.current) {
            return;
        }
        setHeight(viewport.current.offsetWidth / ratio);
    }

    useEffect(() => {
        onWindowResize();
        window.addEventListener('resize', onWindowResize);
        return () => {
            window.removeEventListener('resize', onWindowResize)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ratio ])

    return (
        <FullWidthRatioContainer
            ref={viewport}
            {...props}
            style={{
                ...style,
                ...(height === 0 ? {} : { height: height+ 'px' })
            }}
        >
                {children}
        </FullWidthRatioContainer>
    )
}