

export const TYPE_ADMIN_SHOP = "ADMIN_SHOP";
export const TYPE_ADMIN = "ADMIN";

export default function useAdminType() {
    switch(window.location.hostname) {
        case 'localhost':
            return TYPE_ADMIN_SHOP;
        case 'myshop.i-wantit-dev.com':
        case 'preprod-myshop.i-wantit-dev.com':
        case 'myshop.i-wantit.com':
            return TYPE_ADMIN_SHOP;
        case 'admin.i-wantit.com':
        case 'preprod-admin.i-wantit-dev.com':
        case 'admin.i-wantit-dev.com':
            return TYPE_ADMIN;
        default:
            return null;
    }
}