import React, { FormEvent, useState } from "react";
import {  Button, Grid } from "@material-ui/core";
import useForm from 'AppCore/Components/Form/useForm';
import CategorySelector from 'Apps/Admin/Components/CategorySelector';
import Selector from "Apps/Admin/Components/GenericSelector";
import ShopSelector from "Apps/Admin/Components/ShopSelector/SelectSelector";
import dateformat from 'dateformat';


export default ({
  update,
  initialData = {}
} : {
  update: any,
  initialData : any
}) => {

  const [ startDateFromPicker, setStartDateFromPicker ] = useState(initialData.startDate || null);
  const [ endDateFromPicker, setEndDateFromPicker ] = useState(initialData.endDate || null);

  const {
    getFieldProps,
    getData: getFormData,
    setData: setFormData,
  } = useForm({
    config: {
      product_category : {
        type: 'text'
      },
      user_isChild : {
        type: 'boolean'
      },
      user_gender : {
        type: 'text'
      },
      user_ageRange : {
        type: 'text'
      },
      shop_uuid : {
        type: 'text'
      }
    },
    initialData
  })

  const onSubmitGeneral = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const onClickUpdate = () => {
    update({
      ...getFormData(),
      startDate: startDateFromPicker,
      endDate: endDateFromPicker
      })
  }

  const onClickReset = () => {
    setFormData(initialData)
    update(initialData)
  }


  return (
    <form onSubmit={onSubmitGeneral}>
      <Grid container spacing={2}>
        <Grid container item spacing={2} direction="row" justify="flex-end" >
          <Grid item md={3} sm={6} xs={12}>
            <input
                placeholder="Date de début"
                type="date"
                style={{height: 36, width: '100%'}}
                value={startDateFromPicker}
                onChange={e => {setStartDateFromPicker(e.target.value)}}
            />
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <input
                placeholder="Date de fin"
                type="date"
                style={{height: 36, width: '100%'}}
                value={endDateFromPicker}
                max={dateformat(new Date(), 'yyyy-mm-dd')}
                onChange={e => {setEndDateFromPicker(e.target.value)}}
            />
          </Grid>
        </Grid>
        {
          !initialData.shop_uuid &&
          <Grid item md={3} sm={6} xs={12}>
            <ShopSelector
              label="Boutique"
              {...getFieldProps('shop_uuid')} 
            />
          </Grid>
        }     
        <Grid item md={3} sm={6} xs={12}>
          <Selector
            label="Type de compte"
            options = {[
                {
                  value: true,
                  description: "Enfant"
                },
                {
                  value: false,
                  description: "Parent"
                }
              ]}
            {...getFieldProps('user_isChild')} 
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Selector
            label="Genre"
            options = {[
                {
                  value: "male",
                  description: "Homme"
                },
                {
                  value: "female",
                  description: "Femme"
                }
              ]}
            {...getFieldProps('user_gender')} 
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <Selector
            label="Tranche d'age"
            options = {[
                {
                  value: "15-24",
                  description: "15-24"
                },
                {
                  value: "25-39",
                  description: "25-39"
                },
                {
                  value: "40 et +",
                  description: "40-99"
                }
              ]}
            {...getFieldProps('user_ageRange')} 
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <CategorySelector 
            {...getFieldProps('product_category')} 
          />
        </Grid>
        <Grid container item spacing={2} direction="row" justify="flex-end">
        <Grid item md={3} sm={6} xs={12}>
            <Button
              type='submit'
              color="secondary"
              variant="contained"
              style={{height: 36, width: '100%'}}
              onClick={onClickReset}
            >
              Effacer les filtres
            </Button>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Button
              type='submit'
              color="primary"
              variant="contained"
              style={{height: 36, width: '100%'}}
              onClick={onClickUpdate}
            >
              Rechercher
            </Button>
          </Grid>
        </Grid>
      </Grid>
   </form>
  )
}