import Api from 'AppCore/Api';
import React from 'react';
import { loadImage } from './Media/helpers';
import MediaUploader from "./v2";
import getImageUrl from 'AppCore/Utils/Image';


export default function ImageUploader({
  imageFileName,
  setImageFileName,

  title,
  subTitle,
  btnLabel = "Selectionnez un fichier",

  style,
  canCrop = true,
  needAspectRatio
}: {
  imageFileName: string | null, 
  setImageFileName: (imageFileName: string | null) => any,

  title?: string,
  subTitle?: string,
  btnLabel?: string,

  style?: React.CSSProperties,
  canCrop?: boolean,
  needAspectRatio?: number
}) {

  const mimetype = "image/jpeg";

  const [ imageSize, setImageSize ] = React.useState<{width: number, height: number} | null>(null);

  const media = (imageFileName && imageSize) ? {
    src: getImageUrl(imageFileName),
    type: mimetype,
    width: imageSize.width,
    height: imageSize.height,
  } : undefined;

  React.useEffect(() => {
    if (!imageFileName) {
      return;
    }
    loadImage(getImageUrl(imageFileName)).then((image) => {
      setImageSize({
        width: image.width,
        height: image.height
      })
    })
  }, [imageFileName])

  return (
    <MediaUploader

      onChangeMedia={newMedia => {
        setImageFileName(newMedia?.payload?.imageFileName || "");
      }}
      media={media}
      onClickDelete={() => { setImageFileName(null) }}

      title={title || ""}
      infos={subTitle || ""}
      btnLabel={btnLabel}
      style={style}
      fileType={mimetype}
      canCrop={canCrop}
      needAspectRatio={needAspectRatio}
      onError={e => {
          switch (e.type) {
              case 'file_type':
                  alert("Votre fichier doit etre une image");
                  break;
              default:
                  alert("Une erreur est survenue :  " + e.type);
                  break;
          }
      }}

      uploadMethod={async ({ file, filetype, onProgress }) => {
          onProgress(10);

          const bodyFormData = new FormData();
          bodyFormData.append('uploadfile', file);

          try {
            const rs = await Api.uploadImage({
              file,
              onUploadProgress: ({ total, loaded }: { total: number, loaded: number }) => {
                onProgress((total / loaded) * 100);
              }
            });

            const { filename } = rs

            onProgress(100);

            return {
              imageFileName: filename,
              downloadURL: getImageUrl(filename)
            }
          } catch(e) {
              console.log(e);
              console.log(e.response.data)
              alert(e.response?.data?.error || "Une erreur inconnue est survenue");
              throw e;
          }
      }}
    />
  )
}