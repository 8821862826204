import React from 'react';

import View from './index'
import { useCreateCategory } from 'AppCore/react-hooks/useCategories';


export default () => {

    const createCategory = useCreateCategory();

    return (
        <View
            type="create"
            initialData={{}}
            saveData={async (data) => {
                const { data: { createCategory: savedCategory } } = await createCategory({
                    variables: {
                        category: data
                    }
                })
                return savedCategory;
            }}
        />
    )
}