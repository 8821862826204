import React, { FormEvent } from 'react';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import useForm from 'AppCore/Components/Form/useForm';

export default function InfosForm({
  update,
  layout,
  fpsPage
}: {
  update: (data: any) => Promise<any>,
  layout: 'edit' | 'create',
  fpsPage: any
}) {

  const {
    getMaterialFieldProps,
    getData: getFormData,
    errors,
    hasError,
    hasErrorAttr,
    getErrorMessagesAttr,
    getErrorMessages
  } = useForm({
    config: {
      ...(layout === 'edit' ? {} : {
        key: {
          type: 'text',
          validators: ['not_empty','valid_key']
        }
      }),
      title: {
        type: 'text',
        validators: ['not_empty']
      }
    },
    initialData: {
      key: fpsPage.key,
      title: fpsPage.title
    }
  });

  if (hasError()) {
    console.log(Object.keys(errors).map((attr_name: string) => {
      if (!errors[attr_name].hasError) {
        return "";
      }
      return getErrorMessages(errors[attr_name].errors)
    }));
  }

  const onSubmitGeneral = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const renderError = (attr_name: string) => {
    return hasErrorAttr(attr_name) ? (
      <Alert severity="error">{getErrorMessagesAttr(attr_name).map(error_message => (
        <div key={error_message}>{error_message}</div>
      ))}</Alert>
    ) : "";
  }

  const onClickUpdate = async () => {
    const data = getFormData();
    await update(data);
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      title="Général"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={onClickUpdate}
        >
          Sauvegarder
        </Button>
      )}
    >
      <form onSubmit={onSubmitGeneral}>

        {layout === 'edit' && (
          <h1>{fpsPage.key}</h1>
        )}
        {layout === 'create' && (
          <>
            {renderError('key')}
            <TextField
              fullWidth
              label="Clé unique pour la page"
              margin="normal"
              variant="outlined"
              {...getMaterialFieldProps('key')}
            />
          </>
        )}
        
        {renderError('title')}
        <TextField
          fullWidth
          label="Titre"
          margin="normal"
          variant="outlined"
          {...getMaterialFieldProps('title')}
        />
      </form>
    </CardSection>
  )
}