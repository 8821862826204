import React from 'react';
import { Button } from '@material-ui/core';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import ImageUploader from 'AppCore/Components/MediaUploader/ImageUploader';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import { ShopModel } from 'AppCore/Types/Shop';

export default function CoverUpload() {
  const { currentShop, updateCurrentShop } = useCurrentShop();
  const [ changed, setChanged ] = React.useState(false);
  const [ loading, setLoading ] = React.useState(false);

  const [ imageFileName, setImageFileName ] = React.useState<string | null>(currentShop.cover || null);

  const onClickUpdate = async () => {
    setLoading(true);
    await updateCurrentShop({
      cover: imageFileName
    } as ShopModel)
    setLoading(false);
    setChanged(false);
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      title="Cover"
      subheader="Votre image d'en-tête au format 16/9ème"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={onClickUpdate}
          disabled={!changed || loading}
        >
          {loading ? <ActivityIndicator material /> : "Sauvegarder"}
        </Button>
      )}
    >

      <ImageUploader
        imageFileName={imageFileName}
        setImageFileName={(fileName: string | null) => {
          setImageFileName(fileName);
          setChanged(true);
        }}

        title="Uploadez votre cover au format 16/9ème"
        subTitle={"Uploadez votre cover"}
        btnLabel={"Selectionnez une image"}
        style={{ width: "100%", height: "300px" }}
        needAspectRatio={16/9}
        canCrop={true}
      />
      
    </CardSection>
  )
}