import React from "react";
import { Navigate } from "react-router-dom";

import LayoutDashboard from '../Shared/Layouts/Dashboard'
import LayoutAuth from '../Shared/Layouts/Auth'

import Common from './Layouts/Common'

import SidebarHeaderComponent from './Layouts/Components/Sidebar/Header';
import SidebarFooter from './Layouts/Components/Sidebar/Footer'

// EXPERIMENTS
import ExperimentsUpload from './Pages/Experiments/UploadMedia'
import AuthLogin from './Pages/Auth/Login'

import {
  Users as UsersIcon,
  ShoppingCart,
  ShoppingBag,
  Package,
  Layers,
  Shuffle
} from "react-feather";
import {useCurrentUser} from "AppCore/react-hooks/useCurrentUser";
import Users from "./Pages/Users";
import Wants from "./Pages/Wants";
import Products from "./Pages/Products";
import Shops from "./Pages/Shops";
import EditShop from "./Pages/Shops/EditCreate/Edit";
import CategoriesList from "./Pages/Categories/List";
import CategoriesEdit from "./Pages/Categories/EditCreate/Edit";
import CategoriesCreate from "./Pages/Categories/EditCreate/Create";
import Product from "./Pages/Products/EditCreate/Edit";
import ProductCreate from "./Pages/Products/EditCreate/Create";
import User from './Pages/User/Edit';
import UrlExplorer from './Pages/UrlExplorer';
import { Description } from "@material-ui/icons";
import ShopsListsPage from "./Pages/ShopsLists";
import FpsPagesPage from "./Pages/FlexiblePageSystem/Pages";
import UpdateShopsListPage from "./Pages/ShopsLists/EditCreate/Edit";
import CreateShopsListPage from "./Pages/ShopsLists/EditCreate/Create";
import ProductsListsListsPage from "./Pages/ProductsListsLists";
import UpdateProductsListsListPage from "./Pages/ProductsListsLists/EditCreate/Edit";
import CreateProductsListsListPage from "./Pages/ProductsListsLists/EditCreate/Create";
import CreateFpsPagePage from "./Pages/FlexiblePageSystem/Pages/EditCreate/Create";
import EditFpsPagePage from "./Pages/FlexiblePageSystem/Pages/EditCreate/Edit";
import SectionEditListItemsPage from "./Pages/FlexiblePageSystem/Sections/EditListItems";
import ShopOfferListPage from "./Pages/ShopOffers";
import ShopOfferCreatePage from "./Pages/ShopOffers/EditeCreate/Create";

const NotFound = () => {
    return (<div>NOT FOUND</div>)
}

const HomeSwitch = () => {

    const current_user = useCurrentUser();

    if (current_user && current_user.id) {
        return (
            <Navigate to={"/users"} />
        )
    }

    return (
        <div>NOT LOGGED</div>
    );
}

export default {
    config: {
        redirectSigninPath: '/auth/login',
        redirectHomePath: '/users',
        redirectForbiddenPath: '/not-authorized'
    },
    sidebar: [
        {
            title: "Flexible Page System",
            icon: <Description />,
            path: '/flexible-page-system'
        },
        { title: 'Users', path: '/users', icon: <UsersIcon /> },
        { title: 'Shops', path: '/shops', icon: <ShoppingBag /> },
        { title: 'Categories', path: '/categories', icon: <Layers /> },
        { title: 'Produits', path: '/products', icon: <Package /> },
        { title: 'Wants', path: '/wants', icon: <ShoppingCart /> },
        { title: 'Shops <-> Sellers', path: '/shop-offers', icon: <Shuffle /> },

        // { title: 'Url Explorer', path: '/url-explorer', icon: <Globe /> },
        // {
        //     title: "Experiments",
        //     icon: <Code />,
        //     children: [
        //         { title: "Upload media", path: "/experiments/upload" }
        //     ]
        // }
    ],

    sidebarConfig: {
        SidebarHeaderComponent,
        SidebarFooterComponent: SidebarFooter
    },

    routes: [

        {
            path: "/",
            element: Common,
            routes: [

                {
                    path: "/", element: HomeSwitch,
                    needUser: true
                },

                {
                    path: "/auth",
                    element: LayoutAuth,
                    routes : [
                        { path: "", redirectSignin: true },
                        { path: "login", needLogout: true, element: AuthLogin }
                    ]
                },

                {
                    path: "/not-authorized",
                    element: LayoutAuth,
                    routes : [
                        {
                            path: "",
                            element: () => (<div>NOT AUTHORIZED !</div>)
                        }
                    ]
                },

                {
                    path: "/", element: LayoutDashboard,
                    needUser: true,
                    routes : [
                        { path: '/users', element: Users, needUser: true },

                        { path: '/categories', element: CategoriesList, needUser: true },
                        { path: '/categories/:key', element: CategoriesEdit, needUser: true },
                        { path: '/category-create', element: CategoriesCreate, needUser: true },

                        { path: '/products', element: Products, needUser: true },
                        { path: '/shops', element: Shops, needUser: true },
                        { path: '/wants', element: Wants, needUser: true },
                        { path: '/url-explorer', element: UrlExplorer, needUser: true },
                        { path: '/experiments/upload', element: ExperimentsUpload },
                        {
                            path: '/shop/:uuid', element: EditShop
                        },
                        {
                            path: '/product/:id', element: Product
                        },
                        {
                            path: '/create-product', element: ProductCreate
                        },
                        {
                            path: '/user/:id', element: User
                        },

                        { path: '/shops-list', element: ShopsListsPage },
                        { path: '/shops-list-create', element: CreateShopsListPage },
                        { path: '/shops-list/:key', element: UpdateShopsListPage },
                        { path: '/productslists-list', element: ProductsListsListsPage },
                        { path: '/productslists-list/:key', element: UpdateProductsListsListPage },
                        { path: '/create-productslists-list', element: CreateProductsListsListPage },

                        { path: '/flexible-page-system', element: FpsPagesPage },
                        { path: '/flexible-page-system/page/create', element: CreateFpsPagePage },
                        { path: '/flexible-page-system/page/:key', element: EditFpsPagePage },
                        { path: '/flexible-page-system/section/:uuid', element: SectionEditListItemsPage },


                        { path: '/shop-offers', element: ShopOfferListPage },
                        { path: '/shop-offers/create', element: ShopOfferCreatePage },

                        

                    ]
                }
            ]
        },

        {
            path: "*", element: NotFound
        }
    ]
}