import React from 'react';
import { gql, useMutation } from '@apollo/client';

import View from './index'
import useAdminType, { TYPE_ADMIN_SHOP } from 'AppCore/react-hooks/useAdminType';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';


const CREATE_SHOP_PRODUCT = gql`
  mutation CreateShopProduct($shop_uuid: String!, $product: ProductCreate!) {
    createShopProduct(shop_uuid: $shop_uuid, product: $product) {
      id,
      title,
      market_url,
      market_id,
      primary_product_id,
      master_category,
      status,
      category {key, names},
      primaryProduct {
          id, title
      }
    }
  }
`;
const CREATE_ADMIN_PRODUCT = gql`
  mutation adminCreateProduct($product: ProductCreate!) {
    adminCreateProduct(product: $product) {
      id,
      title,
      market_url,
      market_id,
      primary_product_id,
      master_category,
      status,
      category {key, names},
      primaryProduct {
          id, title
      }
    }
  }
`;

export default () => {

  const adminType = useAdminType();
  const { currentShop } = useCurrentShop()

  const [createProduct] = useMutation(adminType === TYPE_ADMIN_SHOP ? CREATE_SHOP_PRODUCT : CREATE_ADMIN_PRODUCT);

  return (
    <View
      layout={adminType === TYPE_ADMIN_SHOP ? 'create-shop' : 'create'}
      initialData={{}}
      saveData={async (productData: any) => {
        await createProduct({
          variables: {
            product: productData,
            ...(adminType === TYPE_ADMIN_SHOP ? {
              shop_uuid: currentShop.uuid
            } : {})
          }
        })
      }}
    />
  )
}