
import Api from 'Meth/api/request';
import Query from './Query';

export const createUserSearchQuery = (data) => {
    const query = new Query();

    if (data.firstname) {
        query.matchText({
            fieldName: 'firstname',
            value: data.firstname
        })
    }

    if (data.lastname) {
        query.matchText({
            fieldName: 'lastname',
            value: data.lastname
        })
    }

    if (data.full_name) {
        query.matchText({
            fieldName: 'full_name',
            value: data.full_name
        })
    }

    if (data.email) {
        query.matchText({
            fieldName: 'email',
            value: data.email
        })
    }

    console.log("query User : ", query)

    return query;
}

export default async (data) => {

    const query = createUserSearchQuery(data);

    return Api.call({
        uri : `users/search`,
        request_method: Api.request_method.POST,
        data: {
            query: query.query,
            queryGraphQl: query.getGraphQlQuery()
        }
    })
}