import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import FpsPageView from './index';


const GET_QUERY = gql`
    query GetFpsPage($key: String!) {
      fpsPage(key: $key) {
        id
        key
        title
      }
    }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateFpsPage($key: String!, $fpsPage: FpsPageUpdate!) {
    updateFpsPage(key: $key, fpsPage: $fpsPage) {
      id
      key
      title
    }
  }
`;

export default function EditFpsPagePage() {
  const { key } = useParams();
  
  const {
    loading,
    data: { fpsPage: data = {} } = {}
  } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      key
    },
  });
  const [ updateFpsPage ] = useMutation(UPDATE_MUTATION);
  
  if (loading) {
    return <ActivityIndicator material />;
  }

  return (
    <FpsPageView
      fpsPage={data}
      save={async (data) => {
          await updateFpsPage({
              variables: {
                  key,
                  fpsPage: data
              }
          })
      }}
    />
)
}