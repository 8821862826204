import React from "react";
import { Link } from 'react-router-dom'
import styled from "styled-components";
import { Image as ImageIcon } from '@material-ui/icons'

import DataTable from 'AppCore/Components/DataTable';
import Image from "AppCore/Components/Image";

const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`

export default ({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = (data) => {}
}) => {

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: '', key: 'logo', width: 90 },
                { title: 'Uuid', key: 'uuid' },
                { title: 'Boutique', key: 'name' },
                { title: 'Description', key: 'description' },
                { title: 'Domain', key: 'domain' },
                { title: 'Domain verified', key: 'domain_verified' },
                { title: 'Propriétaire', key: 'owner' }
            ],
            actionsLabel: "",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ],
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'uuid') {
                return (
                    <Link to={`/shop/${item.uuid}`}>{item.name}</Link>
                )
            }

            if (attr_name === 'owner') {
                return item.owner?.full_name || item.owner?.email || "";
            }

            if (attr_name === "logo") {
                return (
                    <ImageContainer>
                        {!item.logo &&
                            <ImageIcon fontSize='large'/>
                        }
                        {item.logo &&
                            <Image
                                filename={item.logo}
                                style={{width: '100%'}}
                            />
                        }
                    </ImageContainer>
                )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}