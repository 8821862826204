import React from 'react';
import styled from 'styled-components';

import { spacing } from "@material-ui/system";
import {
    Grid, withStyles,
    Divider as MuiDivider,
    Typography as MuiTypography
} from '@material-ui/core'


//// MISC
export const Divider = styled(MuiDivider)(spacing);
export const Typography = styled(MuiTypography)(spacing);


///// GRID CONTAINER
const GridContainerPadding = styled.div`
    padding: 20px;
`
export const GridContainer = withStyles(theme => ({
  [theme.breakpoints.down('md')]: {
      root: {
        flexDirection: 'column-reverse',
      },
  }
}))(
    React.forwardRef((props, ref) => (
        <GridContainerPadding><Grid ref={ref} container spacing={10} {...props} /></GridContainerPadding>
    ))
);



///// GRID ITEM
const _GridItem = (props) => (
    <Grid item xs={12} sm={12} lg={6} {...props} />
);
export const GridItem = styled(_GridItem)`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 30px;
`


///// TABS
export const TabsContainerHorizontal = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-grow: 1;
`
export const TabsContainerVertical = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-grow: 1;
`
export const TabsContainer = ({ orientation, ...props }) =>
    orientation === 'vertical' ? <TabsContainerVertical {...props} /> : <TabsContainerHorizontal {...props} />
export const TabsPanels = styled.div`
    flex: 1;
    min-height: 400px;
`


///// FORM
export const FormContainer = (props) => (
    <GridItem lg={12} {...props} />
)