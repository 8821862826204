import { gql, useMutation } from '@apollo/client';
import { ShopModel } from 'AppCore/Types/Shop';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect'
import Constants from "../Constants";
import { GET_ALL as GET_ALL_SHOPS } from './useShopList';

const UPDATE_SHOP = gql`
  mutation UpdateShop($uuid: String!, $shop: ShopUpdate!) {
    updateShop(uuid: $uuid, shop: $shop) {
        id, uuid, name, url, description, logo, cover
    }
  }
`;

const { REDUCER: C } = Constants;

const CurrentShopSelector = createSelector(
    (state: any) => state.CurrentShop,
    (currentShop: any) => currentShop? currentShop.shop : null
);


const filterShopUpdate = (shop: ShopModel): ShopModel => Object.keys(shop).filter((key: string) => {
    return [
        'name', 'url', 'description', 'cover', 'logo'
    ].indexOf(key) !== -1
}).reduce((res, key) => {
      (res as any)[key] = (shop as any)[key]
      return res;
    },
    {} as ShopModel 
);



export const useCurrentShop = () : {
    currentShop: ShopModel,
    setCurrentShop: (shop: ShopModel) => any,
    updateCurrentShop: (shop: any) => Promise<ShopModel>
} => {

    const currentShop = useSelector(CurrentShopSelector)
    const dispatch = useDispatch();

    const [updateShop] = useMutation(UPDATE_SHOP, {
        awaitRefetchQueries: true,
        refetchQueries: () => ([
            {
                query: GET_ALL_SHOPS,
                variables: {
                    after: "0",
                    first: 1000
                }
            }
        ])
    });

    const setCurrentShop = (shop: ShopModel) => {
        dispatch({
            type: C.SET_CURRENT_SHOP,
            shop
        });
    };

    const updateCurrentShop = async (shop: any) => {

        const newShopData = {
            ...filterShopUpdate(currentShop),
            ...filterShopUpdate(shop)
        } as ShopModel

        const variables = {
            uuid: currentShop.uuid,
            shop: newShopData
        };

        await updateShop({
            variables
        });
        setCurrentShop({
            ...currentShop,
            ...shop
        });
        return newShopData;
    }

    return {
        currentShop, 
        setCurrentShop,
        updateCurrentShop
    };
}