import React from 'react';
import { gql, useMutation } from '@apollo/client';

import FpsPageView from './index'
import { useNavigate } from 'react-router-dom';

const CREATE_MUTATION = gql`
  mutation CreateFpsPage($fpsPage: FpsPageCreate!) {
    createFpsPage(fpsPage: $fpsPage) {
      id, 
      key, 
      title,
    }
  }
`;

export default function CreateFpsPagePage() {

  const navigate = useNavigate();
  const [createFpsPage] = useMutation(CREATE_MUTATION);

  return (
    <FpsPageView
      layout={'create'}
      fpsPage={{}}
      save={async (data: any) => {
        await createFpsPage({
          variables: {
            fpsPage: data
          }
        });

        navigate('/flexible-page-system');
      }}
    />
  )
}