import React from 'react';
import { gql, useMutation } from '@apollo/client';

import View from './index'
import { useNavigate } from 'react-router-dom';

const CREATE_MUTATION = gql`
  mutation CreateShopsList($shopsList: ShopsListCreate!) {
    createShopsList(shopsList: $shopsList) {
      id, key, title, shop_ids, shops { id, name, logo }
    }
  }
`;

export default function CreateShopsListPage() {

  const navigate = useNavigate();
  const [createShopsList] = useMutation(CREATE_MUTATION);

  return (
    <View
      layout={'create'}
      shopsList={{}}
      update={async (data: any) => {
        await createShopsList({
          variables: {
            shopsList: data
          }
        });

        navigate('/shops-list');
      }}
    />
  )
}