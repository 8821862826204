import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import DataView from './index';


const GET_QUERY = gql`
    query getProductsListsList($key: String!) {
      productsListsList(key: $key) {
        id, key, title, productslist_ids,
        productsLists {
          id, uuid, title, image
        }
      }
    }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateProductsListsList($key: String!, $productsListsList: ProductsListsListUpdate!) {
    updateProductsListsList(key: $key, productsListsList: $productsListsList) {
      id, key, title, productslist_ids, productsLists { id, title }
    }
  }
`;

export default function UpdateProductsListsListPage() {
  const { key } = useParams();
  
  const {
    loading,
    data: { productsListsList: data = {} } = {}
  } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      key
    },
  });
  const [ updateProductsListsList ] = useMutation(UPDATE_MUTATION);
  
  if (loading) {
    return <ActivityIndicator material />;
  }

  return (
    <DataView
      productsListsList={data}
      update={async (data) => {
          await updateProductsListsList({
              variables: {
                  key,
                  productsListsList: data
              }
          })
      }}
    />
)
}