import React from 'react';
import { useParams } from 'react-router-dom'
import { gql, useQuery, useMutation } from '@apollo/client';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';

import View from './index'

const GET_USER = gql`
        query getUser($id: Int) {
            user(id: $id) {
                id,
                full_name,
                firstname,
                lastname,
                role,
                email
            }
        }
    `;

const UPDATE_USER = gql`
  mutation UpdateUser($id: Int!, $user: UserUpdate!) {
    updateUser(id: $id, user: $user) {
      id,
      full_name, 
      firstname, 
      lastname,
      role
    }
  }
`;

export default () => {

    const { id } = useParams();

    const {
        loading,
        data: { user: data = {} } = {}
    } = useQuery(GET_USER, {
        variables: {
            id: parseInt(id)
        },
    });
    const [updateUser] = useMutation(UPDATE_USER);

    if (loading) {
        return <ActivityIndicator material />;
    }

    return (
        <>
            <View
                initialData={data}
                saveData={async (data) => {
                    await updateUser({
                        variables: {
                            id: parseInt(id),
                            user: data
                        }
                    })
                }}
            />
        </>
    )
}