import React, { useState, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import settings from 'AppCore/settings';

import useForm from "AppCore/Components/Form/useForm";
import FormField from "AppCore/Components/Form/formField";

import { login, loginWithIWTToken } from 'AppCore/Actions'

import { parseJwt } from 'Meth/utils/jwt';

import { useSnackbar } from 'notistack';


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://i-wantit.com/">
                I Want It
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
        );
    }

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const [error, setError] = useState(null);

    const {
        getFormFieldProps,
        getData: getFormData
    } = useForm({
        config: {
            email: {
                type: 'text',
                validators: ['not_empty', 'email']
            },
            password: {
                type: 'password',
                validators: ['not_empty']
            }
        }
    });

    useEffect(() => {
        const handleHash = async (hash) => {
            if(typeof hash === 'string' && hash.length > 0) {
                let token = hash.substr(1);
                try {
                    let decoded = parseJwt(token);
                    if(decoded && 'iss' in decoded && decoded.iss.includes('iwantit')) {
                        setLoading(true);
                        await dispatch(loginWithIWTToken(token));
                        setLoading(false);
                    } else {
                        setError('Le token n\'est pas valide.');
                        console.log(decoded);
                    }
                } catch(e) {
                    setError('Connexion impossible, le token n\'est pas valide ou le processus d\'authentification a échoué.');
                    setLoading(false);
                    console.error(e);
                }
            }
        };
        setError(null);
        let hash = window.location.hash;
        handleHash(hash);
        // eslint-disable-next-line
    }, []);

    const { enqueueSnackbar } = useSnackbar();

    const onSubmit = async e => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        try {
            await dispatch(login(getFormData()))
        } catch(e) {
            console.log(JSON.stringify(e))
            enqueueSnackbar("Erreur lors de la connexion: " + JSON.stringify(e.message), {
                variant:  'error'
              })
        }
        setLoading(false);
    }

    const logInWithIWT = async e => {
        e.stopPropagation();
        e.preventDefault();
        let IWTWebAppUrl = settings.www_url;
        window.location.href = `${IWTWebAppUrl}?store=${window.location.origin}${window.location.pathname}`;
    }



    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                {error && <Alert severity="error">{error}</Alert>}
                <form className={classes.form} noValidate onSubmit={onSubmit}>

                    <FormField {...getFormFieldProps('email')} autoComplete="email" disabled={loading} />
                    <br/>
                    <FormField {...getFormFieldProps('password')} autoComplete="current-password" type="password" disabled={loading} />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        {loading ? '...' : 'Sign In'}
                    </Button>

                </form>
                <p>ou</p>
                <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={logInWithIWT}
                    disabled={loading}
                    >
                    {loading ? '...' : 'Se connecter avec mon compte I Want It'}
                </Button>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
        );
    }