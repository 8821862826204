import React from "react";
import styled from "styled-components";

import LogoSrc from 'Assets/logo/logo-picto.png'

const ContainerLight = styled.div`
    background: 'transparent';
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    img {
        margin-right: 8px;
    }
    span {
        color: white;
        font-size: 25px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }
`
const ContainerDark = styled(ContainerLight)`
    span {
        color: #232f3e;
    }
`


export default function Logo({ dark = false }) {
    
    const Container = dark ? ContainerDark : ContainerLight;

    return (
        <Container>
            <img src={LogoSrc} style={{width: '30px'}} alt="logo"/> <span>i-wantit</span>
        </Container>
    )
}