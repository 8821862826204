import React, { useContext } from "react";
// import { useDispatch } from 'react-redux'
import { RoutesContext } from 'Apps/Shared/Router';
import { ConfigContext } from 'App';
import { useCurrentUser } from 'AppCore/react-hooks/useCurrentUser'
import 'react-perfect-scrollbar/dist/css/styles.css';

import {
    Avatar,
    Grid,
} from "@material-ui/core";

import {
    Drawer, Scrollbar, List, Items,
    SidebarFooter,
    SidebarFooterText, SidebarFooterSubText,
    Box,
    Brand
} from './StyledComponents'

import SidebarItem from './SidebarItem';
import getImageUrl from "AppCore/Utils/Image.ts";


export default ({ classes, staticContext, location, ...rest }) => {

    // const dispatch = useDispatch();
    const routeContextConfig = useContext(RoutesContext);
    console.log("routeContextConfig", routeContextConfig)
    const {
        sidebar = [],
        sidebarConfig = {}
    } = routeContextConfig;
    const AppConfig = useContext(ConfigContext);

    const currentUser = useCurrentUser();
    const profilPictureUrl = currentUser.avatar ? getImageUrl(currentUser.avatar) : null;

    const {
        SidebarContent,
        SidebarFooterComponent,
        SidebarHeaderComponent
    } = sidebarConfig;

    console.log("sidebarConfig", sidebarConfig);

    return (
        <Drawer variant="permanent" {...rest}>
            {SidebarHeaderComponent ?
                <SidebarHeaderComponent/> :
                <Brand>
                    <Box ml={1}><i>{AppConfig.title}</i></Box>
                </Brand>
            }
            <Scrollbar>
                {!SidebarContent &&
                <List disablePadding>
                    <Items>
                        {sidebar.map(sidebarItem =>
                            (!sidebarItem.canAccess || sidebarItem.canAccess(currentUser)) &&
                                <SidebarItem key={sidebarItem.path || sidebarItem.title} {...sidebarItem} />
                            )
                        }
                    </Items>
                </List>}
                {SidebarContent &&
                    <SidebarContent sidebar={sidebar} />
                }
            </Scrollbar>

            {/* <ButtonContainer>
                <Button
                    startIcon={<Power />}
                    color="secondary"
                    variant="contained"
                    style={{backgroundColor: "red"}}
                    onClick={() => {
                        dispatch(logout());
                    }}
                >Se déconnecter</Button>
            </ButtonContainer> */}

            {!SidebarFooterComponent && <SidebarFooter>

                <Grid container spacing={2}>
                    <Grid item>
                        {!!profilPictureUrl && <Avatar alt="profile picture" src={profilPictureUrl} />}
                    </Grid>
                    <Grid item>
                        <SidebarFooterText variant="body2">
                            {currentUser.displayName}
                        </SidebarFooterText>
                        <SidebarFooterSubText variant="caption">
                            Coach PRO
                        </SidebarFooterSubText>
                    </Grid>
                </Grid>
            </SidebarFooter>}

            {SidebarFooterComponent &&
                <SidebarFooterComponent currentUser={currentUser} profilPictureUrl={profilPictureUrl} />
            }
        </Drawer>

    )
}