import React from 'react';
import Button from '@material-ui/core/Button';
import { ActivityIndicator } from "react-native";

const getLoadingIndicatorColor = ({ color, buttonVariant }) => {
    if (!buttonVariant) {
        return "blue";
    }

    if (!color) {
        return "black";
    }
    switch (color) {
        case 'primary':
            return "white";
        default:
            return "white";
    }
}
const LoadingIndicator = ({ color, buttonVariant }) => (
    <ActivityIndicator size='small' style={{marginRight: 4}} color={getLoadingIndicatorColor({ color, buttonVariant })} />
)

export default ({ loading = false, icon = null, startIcon = null, endIcon = null, color, variant = null, ...props }) => (
    <Button
        startIcon={loading ? <LoadingIndicator buttonVariant={variant} color={color} /> : (startIcon || icon)}
        endIcon={!loading && endIcon}
        color={color}
        variant={variant}
        {...props}
    />
)