import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import ProductsListDataView from '.';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';


const GET_PRODUCTSLIST = gql`
    query getProduct($uuid: String!) {
      productsList(uuid: $uuid) {
        id, uuid, title, position_in_shop, image, product_ids,
        products {
          id, title,
          image,
          market_url,
          wantsCount,
          status,
          category { key, names }
        }
      }
    }
`;

const UPDATE_PRODUCTSLIST = gql`
  mutation UpdateProductsList($uuid: String!, $productsList: ProductsListUpdate!) {
    updateProductsList(uuid: $uuid, productsList: $productsList) {
      id, uuid, title, position_in_shop, image, product_ids, products { id, title }
    }
  }
`;

export default function EditProductsListPage() {
  const { uuid } = useParams();
  
  const {
    loading,
    data: { productsList: data = {} } = {}
  } = useQuery(GET_PRODUCTSLIST, {
    fetchPolicy: 'cache-and-network',
    variables: {
      uuid
    },
  });
  const [ updateProductsList ] = useMutation(UPDATE_PRODUCTSLIST);
  
  if (loading) {
    return <ActivityIndicator material />;
  }

  return (
    <ProductsListDataView
      productsList={data}
      update={async (data) => {
          await updateProductsList({
              variables: {
                  uuid,
                  productsList: data
              }
          })
      }}
    />
)
}