import React from "react";
import { Helmet } from "react-helmet";
import { gql, useQuery, useMutation } from '@apollo/client';
import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import { Title } from "Apps/Admin/Layouts/Components/Page";
import { Button, Grid } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import ShopsOffersList from "Apps/Admin/Components/ShopsOffersList";
import { Delete } from '@material-ui/icons';

const GET_ALL = gql`
  query getShopOfferList($after: String, $first: Int) {
    shopOffers(after: $after, first: $first) {
      edges {
        node {
          id
          uuid
          seller
        }
      }
    }
  }
`;

const DELETE_SHOP_OFFER = gql`
    mutation DeleteShopOffer($id: Int!) {
      deleteShopOffer(id: $id)
    }
`;


const DataTable = ({
  data,
  columns,
  actions,
}: {
data: any,
columns: any,
actions : any,
}) => {

  console.log(actions)

  if (!data) {
    return null;
  }
  const {
    shopOffers
  } = data;

  const {
      edges
  } = shopOffers;

  return (
    <Grid container>
        <Grid item xs={12}>
            <ShopsOffersList
              data={edges.map((edge: any) => edge.node)}
              columns={columns}
              actions={actions}
            />
        </Grid>
    </Grid>
  )
}



export default function ShopsOffersListPage({
  actions = null,
  columns = ['id', 'uuid', 'seller']
}){

  const navigate = useNavigate();

  const [deleteProduct] = useMutation(DELETE_SHOP_OFFER);

  const deleteAfterConfirm = async (id: any) => {
    if (window.confirm('Voulez-vous supprimer définitivement cette association ?')) {
      try {
        await deleteProduct({
          variables: {
              id: parseInt(id),
          }
        })
      }catch(e: any){
        alert(e?.message || 'Something wrong happened')
      }     
    }

}

  const {
    loading,
    data,
    refetch
  } = useQuery(GET_ALL, {
      fetchPolicy: 'cache-and-network',
      variables: {
          after: "",
          first: 100
      },
  });

  return (
    <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

    <Helmet title="Associations Shops - Offers (seller)" />

    <FlexRowSpaceBetween>
          <Title>
            Listes des associations
          </Title>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              navigate('/shop-offers/create')
            }}
          >Créer une nouvelle association</Button>
      </FlexRowSpaceBetween>

      <Divider />

      <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

          {loading ? <div>Loading...</div> : (
              <DataTable
                  data={data}
                  columns={columns}
                  actions={actions || [
                    { key: 'delete', icon: <Delete />, onClick: async (id:any) => {
                      await deleteAfterConfirm(id)
                      refetch()
                    } },
                  ]}
              />
          )}
        
      </div>

    </div>

  )
}