import React, { FormEvent } from 'react';
import { Button, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import useForm from 'AppCore/Components/Form/useForm';
import { useSnackbar } from 'notistack';
import CategorySelector from 'Apps/Admin/Components/CategorySelector';
import ProductSelector from 'Apps/Admin/Components/ProductSelector';
import FormField from 'AppCore/Components/Form/formField';
import ProductStatusSelector from 'Apps/Admin/Components/ProductStatusSelector';

export default function InfosForm({
  update,
  product,
  layout = 'edit'
}: {
  update: (data: any) => Promise<any>,
  product: any,
  layout: 'edit' | 'edit-shop' | 'create' | 'create-shop'
}) {

  const { enqueueSnackbar } = useSnackbar();
  const [ changed, setChanged ] = React.useState(false)
  const [ loading, setLoading ] = React.useState(false)
  const [ primaryProduct, setPrimaryProduct ] = React.useState(product.primaryProduct);

  const isLayoutAdmin = () => layout === 'create' || layout === 'edit';
  const isLayoutEdit = () => layout === 'edit' || layout === 'edit-shop';

  const {
    getMaterialFieldProps,
    getFieldProps,
    getFormFieldProps,
    getData: getFormData,
    errors,
    hasError,
    hasErrorAttr,
    getErrorMessagesAttr,
    getErrorMessages
  } = useForm({
    config: {
      title: {
        type: 'text',
        validators: ['not_empty']
      },
      description: {
        type: 'text',
        validators: ['not_empty']
      },
      price: {
        type: 'text',
        transformers: ['float']
      },
      price_discount: {
        type: 'text',
        transformers: ['float']
      },
      market_url: {
        type: 'text',
        validators: ['not_empty']
      },
      master_category: {
        type: 'text'
      },
      status : {
        type: 'text'
      }
    },
    initialData: {
      title: product.title,
      description: product.description,
      price: product.price,
      price_discount: product.price_discount,
      market_url: product.market_url,
      master_category: product.master_category,
      status: product.status
    },
    onChange: () => {
      setChanged(true);
    }
  });

  const onClickUpdate = async () => {
    if (!update) {
      return;
    }

    setLoading(true);
    try {
      console.log("getFormData()", getFormData())
      await update({
        ...getFormData(),
        ...((isLayoutEdit() && isLayoutAdmin()) ? {
          primary_product_id: (primaryProduct && primaryProduct.id) || null
        } : {})
      });
      enqueueSnackbar("Sauvegardé", {
        variant: 'success'
      });
      setChanged(false);
    } catch (e) {
      alert("une erreur est survenue")
    }
    setLoading(false);
  }

  if (hasError()) {
    console.log(Object.keys(errors).map((attr_name: string) => {
      if (!errors[attr_name].hasError) {
        return "";
      }
      return getErrorMessages(errors[attr_name].errors)
    }));
  }

  const onSubmitGeneral = (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  }

  const renderError = (attr_name: string) => {
    return hasErrorAttr(attr_name) ? (
      <Alert severity="error">{getErrorMessagesAttr(attr_name).map(error_message => (
        <div key={error_message}>{error_message}</div>
      ))}</Alert>
    ) : "";
  }

  return (
    <CardSection
      style={{
        height: '100%'
      }}
      subheader="Informations sur le produit"
      title="Général"
      bottomAction={(
        <Button
          type='submit'
          color="primary"
          variant="contained"
          onClick={onClickUpdate}
          disabled={!changed || loading}
        >
          Sauvegarder
        </Button>
      )}
    >
      <form onSubmit={onSubmitGeneral}>
        {renderError('title')}
        <TextField
          fullWidth
          label="Nom du produit"
          margin="normal"
          variant="outlined"
          {...getMaterialFieldProps('title')}
        />

        
        {renderError('price')}
        <FormField
          style={{}}
          {...getFormFieldProps('price')}
          label="Prix"
          variant="outlined"
        />

        {isLayoutEdit() && renderError('price_discount')}
        {isLayoutEdit() && <FormField
          style={{}}
          {...getFormFieldProps('price_discount')}
          label="Prix promo"
          variant="outlined"
        />}

        
        {renderError('description')}
         <TextField
          multiline
          fullWidth
          label="Description du produit"
          margin="normal"
          variant="outlined"
          {...getMaterialFieldProps('description')}
        />

        {renderError('market_url')}
        <TextField
          fullWidth
          label="Url d'achat du produit"
          margin="normal"
          variant="outlined"
          {...getMaterialFieldProps('market_url')}
        />

        {renderError('master_category')}
        <CategorySelector 
          {...getFieldProps('master_category')} 
        />

        {renderError('status')}
        <ProductStatusSelector 
          {...getFieldProps('status')} 
        />

        {isLayoutAdmin() && (
          <ProductSelector
            label="Primary product"
            product={primaryProduct}
            onChange={(product) => {
              setPrimaryProduct(product);
              setChanged(true);
            }}
          />
        )}

      </form>
    </CardSection>
  )
}