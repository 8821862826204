import React from 'react';
import {
    Avatar,
    Card,
    CardContent,
    Grid,
    Typography
  } from '@material-ui/core';
  import { red } from '@material-ui/core/colors';
  
  const InfoBox = ({ title, value, icon, ...props}) => (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          style={{
            display:'flex',  justifyContent: 'space-between', width: '100%',
            height: '80px'
          }}
        >
          <Grid item style={{
            maxWidth: 'calc(100% - 45px)'
          }}>
            <Typography
              color="textSecondary"
              gutterBottom
              variant="h6"
            >
              {title}
            </Typography>
            <Typography
              color="textPrimary"
              variant="h3"
              
            >
              {value}
            </Typography>
          </Grid>
          {icon && (
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: red[600],
                height: 56,
                width: 56
              }}
            >
              {icon}
            </Avatar>
          </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
  
  export default InfoBox;