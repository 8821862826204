import React, { useState, useEffect } from "react";
import { gql, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom'
import Helmet from 'react-helmet';
import { Grid } from "@material-ui/core";
import { Edit } from '@material-ui/icons'
import Button from 'AppCore/Components/Button'
import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox'
import Table from 'Apps/Admin/Components/UserList';
import { Divider  } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { Title } from 'Apps/Admin/Layouts/Components/Page'
import UserSearchForm from 'Apps/Admin/Components/SearchForms/Users';


const CORE_USERS_LIST_FIELDS = gql `
fragment CoreUsersListFields on UserList{
    pageInfo {
        hasNextPage
        endCursor
    }
    edges {
        cursor
        node {
            id,
            email,
            full_name,
            avatar
        }
    }
}`;

const GET_ALL_USERS = gql`
    ${CORE_USERS_LIST_FIELDS}
    query getUsers($after: String, $first: Int) {
        users(after: $after, first: $first) {
            ...CoreUsersListFields
        }
    }
`;

const SEARCH_USERS = gql`
    ${CORE_USERS_LIST_FIELDS}
    query searchUsers($after: String, $first: Int, $query: String!){
        users: searchUsers(after: $after, first: $first,query: $query){
            ...CoreUsersListFields
        }
    }    
`;

const UserList = ({
    data, columns, actions, fetchMore
}) => {

    if(!data){
        return <p>Waiting for data...</p>
    }

    const {
        users = null,
    } = data;

    const {
        pageInfo,
        edges
    } = users;

    return (
        <>
            <Divider my={6} />

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={edges.map(edge => edge.node)}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            {pageInfo.hasNextPage && 
                <Button onClick={() => {
                    fetchMore({
                        variables: {
                            after: pageInfo.endCursor,
                            first: 100
                        }
                    })
                }}>Load More</Button>
            }
        </>
    )
}

export default () => {


    const navigate = useNavigate();

    const [searchFormData, setSearchForm] = useState({});
    const [query,setQuery] = useState(GET_ALL_USERS);

    useEffect(()=>{
        if(searchFormData?.full_name){
            setQuery(SEARCH_USERS)
        } else {
            setQuery(GET_ALL_USERS)
        }
    }, [searchFormData])

    const { 
        loading,
        data, 
        fetchMore 
    } = useQuery( query , {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: "cache-first",
        variables: {
            after: "",
            first: 100,
            ...(searchFormData?.full_name ? {query: searchFormData?.full_name} : {})
        }
    });

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <Helmet title="Users" />

            <FlexRowSpaceBetween>
                <Title>
                    Users
                </Title>
            </FlexRowSpaceBetween>

            <UserSearchForm
                onChangeSearchQuery={setSearchForm}
                onChangeForm={console.log}
                loading={loading}
            />
            
            <UserList
                data={data}
                actions={[
                        { key: 'edit', icon: <Edit />, onClick: id => navigate('/user/'+id) }
                    ]}
                fetchMore={fetchMore}
            />
        </div>
    );
}