import React from "react";

import { FpsSection, getSectionTypeDetails } from 'AppCore/Types/FpsSection';
import DataTable from 'AppCore/Components/DataTable';

export default function FpsSectionsList({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = () => {}
}: any) {

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: 'Titre', key: 'title' },
                { title: 'Type', key: 'type' },
                { title: 'Status', key: 'visible' }
            ],
            actionsLabel: "",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ],
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(section: FpsSection, attr_name: string) => {

            if (attr_name === 'type') {
                const sectionTypeDetails = section.type ? getSectionTypeDetails(section.type) : null;
                if (!sectionTypeDetails) {
                    return section.type;
                }
                return sectionTypeDetails.label;
            }

            if (attr_name === 'visible') {
                if (section.visible) {
                    return "Publié";
                } else {
                    return "Brouillon";
                }
            }

            const item = section as any;
            return Object.prototype.hasOwnProperty.call(section, attr_name) ? item[attr_name] : '';
        }}
    />

}