export const removeSlashesFromPath = (path: string) => path.split("/")
                                .map(s => s.replace(/\s+/g,''))
                                .filter(s => s !== '')
                                .join('/')


const paddTime = (time: number): string => {
    return (time < 10) ? ("0"+time): time+""
}
export const renderTime = (value: number, format: 'short' | 'long') => {
    const minutes = Math.floor(value / 60)
    const seconds = Math.round(((value/60) - minutes) * 60);
    return format === 'short' ? `${paddTime(minutes)}:${paddTime(seconds)}`
        : `${paddTime(minutes)}min ${paddTime(seconds)}s`
}