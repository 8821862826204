import Api from 'Meth/api/request';
import { checkParam } from './helpers';

export default {

    getWant: ({ id }) => {

        checkParam(id, 'number', 'id', true);

        return Api.call({
			uri : `want/${id}`,
			request_method: Api.request_method.GET
		})
    },

    getAllWantByProduct: ({ id, index = 0, limit = 0 } = {}) => {

		checkParam(id, 'number', 'index', true)
		checkParam(index, 'number', 'index', false)
		checkParam(limit, 'number', 'limit', false)

		return Api.call({
			uri : `products/${id}/wants`,
			request_method: Api.request_method.GET,
			params: {
				index,
				limit
			}
		})
	},

}