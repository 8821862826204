import { Product } from "./Product";
import { ShopModel } from "./Shop";
import { User } from "./User";


export const FPS_SECTION_TYPES_DETAILS: Array<{
  canSelectItems: boolean,
  key: string,
  label: string,
  description: string
}> = [
  { canSelectItems: true, key: 'horizontal_list', label: "Liste horizontale", description: "Liste de produit/shop/user présenté horizontalement" },
  { canSelectItems: true, key: 'vertical_list', label: "Liste vertical", description: "Liste de produit/shop/user présenté verticalement" },
  { canSelectItems: false, key: 'auto_categories', label: "Catégories", description: "Liste des catégories, horizontal, organisé en fonction des choix de l'utilisateur" },
  { canSelectItems: false, key: 'auto_user_discounts', label: "Mes promos", description: "Liste horizontale de produits en promo pour l'utilisateur" },
  { canSelectItems: false, key: 'auto_product_reco', label: "Recommandé pour vous", description: "Liste horizontale de produits recommandé pour l'utilisateur" },
  { canSelectItems: false, key: 'auto_popular_wants_followings', label: "Populaire parmis mes abonnés", description: "Liste horizontale des wants populaires parmis mes abonnés" },
  { canSelectItems: false, key: 'auto_popular_wants', label: "Wants les + populaires", description: "Liste 'infinie' des wants les plus populaire, présentée verticalement" }  ,
  { canSelectItems: false, key: 'auto_my_contacts', label: "Followings", description: "Liste de followings de l utilisateur présenté horizontalement" },
  { canSelectItems: false, key: 'auto_last_products', label: "Derniers produits vus", description: "Liste des derniers produits consultés par l utilisateur" },
  { canSelectItems: false, key: 'auto_last_want_followings', label: "Wantés par mes abonnements", description: "Liste des derniers produits wantés par mes abonnements" }
];

export const getSectionTypeDetails = (type: string) => {
  return FPS_SECTION_TYPES_DETAILS.find((el) => el.key === type);
}

export enum FpsSectionType {
  horizontal_list = 'horizontal_list',
  vertical_list = 'vertical_list',
  auto_categories = 'auto_categories',
  auto_user_discounts = 'auto_user_discounts',
  auto_product_reco = 'auto_product_reco',
  auto_popular_wants_followings = 'auto_popular_wants_followings',
  auto_popular_wants = 'auto_popular_wants',
  auto_my_contacts = 'auto_my_contacts',
  auto_last_products = 'auto_last_products',
  auto_last_want_followings = 'auto_last_want_followings'
}

export class FpsSectionItem {

  public type?: 'shop' | 'product' | 'user'
  public primary_key?: string | number
  public layout?: string

  public object?: Product | ShopModel | User

  setRawData(data: any) {
    const objectProperties = Object.getOwnPropertyNames(this);
    Object.keys(data).forEach(key => {

      if (key === 'object') {
        return true;
      }

      if (objectProperties.indexOf(key) !== -1) {
        Object.assign(this, {[key]: data[key]});
      }
    })

    if (data.object) {
      switch (data.object.__typename) {
        case 'Shop':
          this.object =  new ShopModel();
          this.object.setRawData(data.object);
          break;
        case 'User':
          this.object =  new User();
          this.object.setRawData(data.object);
          break;
        case 'Product':
          this.object =  new Product();
          this.object.setRawData(data.object);
          break;
        default:
          break;
      }
    }
  }
}

export class FpsSection {
  public id?: number
  public uuid?: string
  public visible?: boolean
  public title?: string
  public type?: FpsSectionType
  public page_key?: string
  public position_in_page?: number
  public items?: Array<FpsSectionItem>

  setRawData(data: any) {
    const objectProperties = Object.getOwnPropertyNames(this);
    Object.keys(data).forEach(key => {

      if (key === 'items') {
        return true;
      }

      if (objectProperties.indexOf(key) !== -1) {
        Object.assign(this, {[key]: data[key]});
      }
    })

    if (data.items && data.items.length) {
      this.items = data.items.map((item: any) => {
        const fpsSectionItem = new FpsSectionItem();
        fpsSectionItem.setRawData(item);
        return fpsSectionItem;
      });
    }
  }
}