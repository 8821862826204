

export const checkParam = (param, type, param_name, required = false) => {

	if ((param === undefined || param === null) && !required) {
		return true;
	}

	let error = false;
	switch (true) {
		case type === 'number' && typeof param !== 'number' :
		case type === 'string' && typeof param !== 'string' :
		case type === 'array' && !Array.isArray(param) :
			error = true;
			break;
		default:
			break;
	}

	if (error) {
		throw new Error(`${param_name} must be a ${type} !`);
	}

	return true;
}