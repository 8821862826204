import React from 'react';
import { connect, useDispatch } from 'react-redux'

import Button from '@material-ui/core/Button';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ActivityIndicator from '../ActivityIndicator';

export const reducer = (state = {}, action) => {

    switch (action.type) {
        case 'OPEN':
            return {
                ...state,
                [action.id]: action.dialog
            }
        case 'CLOSE':
            delete state[action.id];
            return {...state};
        case 'LOADING':
            return {
                ...state,
                [action.id]: {
                    ...state[action.id],
                    loading: action.loading
                }
            }
        default:
            return state;
    }

};

export const useOpenDialogComponent = () => {
    const dispatch = useDispatch();

    return (Dialog) => {
        const id = Math.random()*1000;
        const close = () => {
            dispatch({
                type: 'CLOSE',
                id
            })
        };
        dispatch({
            type: 'OPEN',
            id,
            dialog: (
                <Dialog
                    key={id}
                    close={close}
                />
            )
        });

        return {
            close
        }
    }
}

export const useOpenDialog = () => {

    const dispatch = useDispatch();

    return ({ title, content }) => new Promise((resolve, reject) => {

        const id = Math.random()*1000;
        const onClose = () => {
            dispatch({
                type: 'CLOSE',
                id
            })
        }
        const setLoading = (loading) => {
            dispatch({
                type: 'LOADING',
                id, loading
            })
        }
        dispatch({
            type: 'OPEN',
            id,
            dialog: {
                title,
                content,
                onClose: () => {
                    resolve({ confirm: false, close: onClose, setLoading })
                },
                actions: [
                    <Button
                        key="no"
                        onClick={() => {
                            resolve({
                                confirm: false, close: onClose,
                                setLoading
                            });
                        }} color="primary"
                    >
                        No
                    </Button>,
                    <Button
                        key="yes"
                        onClick={() => {
                            resolve({
                                confirm: true, close: onClose,
                                setLoading
                            });
                        }}
                        color="primary"
                        autoFocus
                    >
                        Yes
                    </Button>
                ]
            }
        })
    })
}

const Dialog = ({
    title,
    content,
    onClose,
    actions = [],
    loading = false,
}) => {

    return (
        <MuiDialog
            open
            onClose={() => loading || onClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
            <DialogActions>
                {!loading && actions}
                {loading && <ActivityIndicator />}
            </DialogActions>
        </MuiDialog>
    )
}

export default Dialog;

const Dialogs = connect(state => ({
    dialogs: state.dialogs || {}
}))(({ dialogs }) => {

    return (
        <>
            {Object.keys(dialogs).map(dialogId => {
                return React.isValidElement(dialogs[dialogId]) ? dialogs[dialogId] : (
                    <Dialog
                        key={dialogId}
                        {...dialogs[dialogId]}
                    />
                );
            })}
        </>
    );
})

export const DialogProvider = () => {
    return (
        <Dialogs />
    );
}