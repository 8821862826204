import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from "@apollo/client/utilities";


let client;
let getAccessToken = () => null;

const authLink = setContext((_, { headers }) => {

    const token = getAccessToken();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        }
    }
});

export const init = ({ uri }) => {
    const httpLink = createHttpLink({
        uri
    });
    client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        products: relayStylePagination(['master_category', 'shop_uuid', 'status']),
                        wantsProductsList: relayStylePagination(['product_category', 'shop_uuid', 'user_isChild', 'user_gender', 'user_ageRange', 'startDate', 'endDate']),
                        searchProducts : relayStylePagination(['query']),
                        popularProducts: relayStylePagination(),
                        users: relayStylePagination(),
                        searchUsers: relayStylePagination(['query']),
                        shops: relayStylePagination(['owner_id']),
                        searchShops: relayStylePagination(['query']),
                    }
                }
            }
        })
    })
}

export function setAccessTokenGetter(fct) {
	getAccessToken = fct;
}

export default () => (client)