import React, { useState } from 'react';
import { Prompt, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Helmet from 'react-helmet';

import { FlexRowSpaceBetween } from 'Apps/Shared/Layouts/FlexBox'

import ImageUpload from './Components/ImageUpload';
import Button from 'AppCore/Components/Button'

import {
    Title, Divider,
} from 'Apps/Shared/Layouts/Dashboard/Components/Page'


import {
    FormContainer,
    GridContainer
} from '../../Utils/layout'
import useForm from 'AppCore/Components/Form/useForm';
import FormField from 'AppCore/Components/Form/formField';
import { Grid } from '@material-ui/core';

const defaultTitle = "Catégorie";
export default ({ initialData, saveData, type = 'edit' }) => {

    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [ changed, setChanged ] = useState(false)
    const [ loading, setLoading ] = useState(false)

    const {
        data,
        getFormFieldProps,
        getData: getFormData,
        setData: setFormData,
    } = useForm({
        config: {
            ...(type === 'edit'? {}: {
                key: {
                    type: 'text',
                    validators: ['not_empty']
                }
            }),
            names_fr: {
                type: 'text',
                validators: ['not_empty']
            },
            names_en: {
                type: 'text',
                validators: ['not_empty']
            },
            color: {
                type: 'color',
                validators: ['not_empty']
            },
        },
        initialData: {
            ...initialData,
            names_fr: initialData.names?.fr,
            names_en: initialData.names?.en
        },
        onChange: () => {
            setChanged(true);
        }
    });

    console.log("initialData", initialData);

    const onClickCancel = () => {
        setFormData(initialData);
    }
    const onClickSave = async () => {
        if (!saveData) {
            return;
        }

        let success = false;
        setLoading(true);
        let savedCategory;
        try {
            console.log('GET FORM DATA',getFormData())
            console.log('TRANSFORMED',{
                ...getFormData(),
                names: JSON.stringify({'fr':getFormData().names_fr,'en':getFormData().names_en})
            })
            savedCategory = await saveData({
                ...(getFormData().key ? {key: getFormData().key} : {}),
                ...(getFormData().color ? {color: getFormData().color} : {}),
                names: JSON.stringify({'fr':getFormData().names_fr,'en':getFormData().names_en})
            });
            enqueueSnackbar("Sauvegardé", {
                variant: 'success'
            })
            setChanged(false);
            success = true;
        } catch (e) {
            alert("une erreur est survenue")
        }

        if (!success) {
            setLoading(false);
        } else {
            setTimeout(() => {
                if (type !== 'edit') {
                    navigate('/categories/' + savedCategory.key , { replace: true });
                } else {
                    navigate('/categories');
                }
            })
        }

    }

    const onSubmit = e => {
        e.stopPropagation();
        e.preventDefault();
    }

    const canSave = () => {
        if (!changed || loading) {
            return false;
        }

        return true;
    }

    return (
        <React.Fragment>
            <Helmet title={data.key || defaultTitle} />
            <Prompt when={changed} message="Vous n'avez pas enregistré vos modifications. Etes-vous sûr de vouloir quitter cette page ?"/>

            <FlexRowSpaceBetween>

                <Title>
                    {data.key || defaultTitle}
                </Title>

                <div>
                    {saveData && <>
                        <Button
                            loading={false} disabled={!canSave()} color="primary"
                            onClick={onClickCancel} style={{marginRight: '10px'}}
                        >{"annuler les modifications".toUpperCase()}</Button>
                        <Button
                            style={{marginRight: '10px'}}
                            loading={false} disabled={!canSave()} color="primary"
                            onClick={onClickSave}
                        >{"Enregistrer".toUpperCase()}</Button>
                    </>}
                </div>
            </FlexRowSpaceBetween>

            <Divider my={6} />
        
            <Grid container spacing={5}>
                <Grid item xs={12} md={4}>
                    <ImageUpload
                        category={initialData}
                        update={async (data) => {
                            await saveData(data);
                        }}
                    />
                </Grid>

                <Grid item xs={12} md={8}>
                    <form onSubmit={onSubmit}>
                    <GridContainer>

                        <FormContainer>

                            {type !== 'edit' &&
                                <FormField {...getFormFieldProps('key')} disabled={loading} style={{width: '100%'}} />
                            }
                            <FormField {...getFormFieldProps('names_en')} disabled={loading} style={{width: '100%'}} />

                            <FormField {...getFormFieldProps('names_fr')} disabled={loading} style={{width: '100%'}} />

                            {type ==='edit' &&
                            <FormField {...getFormFieldProps('color')} disabled={loading} style={{width: '100%'}} />
                            }
                        </FormContainer>
                    </GridContainer>
                    </form>
                </Grid>
            </Grid>


        </React.Fragment>

    )
}
