import React from 'react';
import { Grid } from '@material-ui/core';

import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import Button from 'AppCore/Components/Button';
import Table from 'Apps/Admin/Components/ShopList';
import { useOpenShopsListModal } from 'Apps/Admin/Components/ShopSelector/ModalSelector';


const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function ShopsList({
  shopsList,
  update
}: any) {

  const [ changed, setChanged ] = React.useState<boolean>(false);
  const [ shops, setShops ] = React.useState<Array<any>>(shopsList.shops || []);

  const openShopsListSelector = useOpenShopsListModal();

  const onClickSelectShop = () => {
    openShopsListSelector({
      filterIds: shops.map(p => p.uuid),
      onSelect: (shop: any, { close }: any) => {
        console.log(shop);
        setShops([
          shop,
          ...shops
        ])
        setChanged(true);
        close();
      }
    })
  };

  const onClickDelete = (id: number, product: any) => {
    const result = Array.from(shops)
    const startIndex = shops.findIndex(el => el.id === id);
    if (startIndex < 0) {
      return;
    } 
    result.splice(startIndex, 1)

    setShops(result);
    setChanged(true);
  }

  const onClickSave = async () => {
    const shop_ids = shops.map((shop: any) => shop.uuid).join(',')
    await update({
      shop_ids
    })
  }

  const onOrderChanged = (result: any) => {
    const newOrder = reorder(shops, result.source.index, result.destination.index);
    setShops(newOrder);
    setChanged(true);
  }

  return (
    <CardSection
      title="Liste de boutiques"
      subheader={"Ajoutez / Supprimez / reordonnez les boutiques"}
      action={(
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSave}
            disabled={!changed}
            style={{marginRight: '10px'}}
          >Sauvegarder</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSelectShop}
          >Ajouter une boutique</Button>
        </div>
      )}
    >

      <Grid container>
          <Grid item xs={12}>
              <Table
                  data={shops}
                  columns={['logo', 'name', 'owner']}
                  actions={[
                    { icon_name: 'delete', title: "Supprimer", onClick: onClickDelete }
                  ]}
                  reorder={true}
                  onOrderChanged={onOrderChanged}
              />
          </Grid>
      </Grid>

    </CardSection>
  )
}