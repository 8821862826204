import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@material-ui/core';
import { Divider } from '../../Utils/layout';
import InfosForm from './Components/InfosForm'

// // @ts-ignore
// import FpsSectionsList from './Components/List';

export default function ShopOfferView({
  shopOffer,
  save,
  layout = 'create'
}: {
  shopOffer: any,
  save: (data: any) => Promise<any>,
  layout?: 'edit' | 'create'
}) {

  const Form = (
    <Box
      style={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          paddingTop: '3px',
          paddingBottom: '3px',
      }}
    >
      <Grid container spacing={3} justify={'space-around'} alignItems={'stretch'}>
        <Grid item xs={12} md={12}>
          <InfosForm
            layout={layout}
            shopOffer={shopOffer}
            update={save}
          />
        </Grid>
      </Grid>
    </Box>
  )

  return (
    <>
      <Helmet>
        <title>Creation nouvelle association shop - offer (seller)</title>
      </Helmet>

      <h1>Creation nouvelle association shop - offer (seller)</h1>
      <Divider style={{
        marginBottom: '10px'
      }} />

      <Grid container>
        <Grid item xs={12}>
          {Form}
        </Grid>
        <Divider style={{marginTop: '20px', marginBottom: '20px', width: '100%'}}/>
      </Grid>
    </>
  )
}