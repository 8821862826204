import React from 'react';
import {
  Typography as MuiTypography,
  AppBar, Toolbar
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";

export const Typography = styled(MuiTypography)(spacing);

export const Title = styled((props) => (
    <Typography variant="h1" gutterBottom display="inline" {...props} />
))`
    font-weight: normal;
`


export const AppBarTitle = ({ title }) => (
    <AppBar position="sticky" color="primary">
        <Toolbar>
            <MuiTypography variant="h6">{title}</MuiTypography>
        </Toolbar>
    </AppBar>
)