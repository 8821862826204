import React from "react";
import styled from "styled-components";
import { TextField, Paper } from "@material-ui/core";

import Button from 'AppCore/Components/Button'

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import useForm from "AppCore/Components/Form/useForm";
import { createSearchQuery } from "AppCore/Api/Search/Product";


const SearchContainer = styled(Paper)`
    padding: 20px;
`

export default ({ loading = false, disabled = false ,onChangeSearchQuery = () => {}, onSubmit = () => {}, onUse = () => {} }) => {

    const onClickReset = async () => {
        onSubmit(null)
        setSearchFormData({});
        onChangeSearchQuery(undefined);
    }

    React.useEffect(()=>{
        if(disabled){
            onClickReset()
        }
    },[disabled])


    const {
        //data: formData,
        getFieldProps,
        getData: getSearchFormData,
        setData: setSearchFormData,
    } = useForm({
        config: {
            title: {
                type: 'text'
            },
        },
        initialData: {}
    });

   

    const onClickSearch = async (e) => {
        console.log();
        const searchQuery = createSearchQuery(getSearchFormData());
        const queryString = JSON.stringify(searchQuery.query);

        onChangeSearchQuery(queryString);
        onSubmit(getSearchFormData().title)

        onUse()
    }
    

    return (
        <SearchContainer>
            <TextField {...getFieldProps('title')} disabled={loading} placeholder="Titre" size="small" variant="outlined" />
            <Divider my={6} />
            

            <Button variant="contained" color="primary" disabled={loading} loading={loading} onClick={onClickSearch}>Search</Button>
            <Button variant="contained" color="primary" disabled={loading} loading={loading} onClick={onClickReset}>Reset</Button>
        </SearchContainer>
    )
}