import React from "react";
import { Link } from 'react-router-dom'

import DataTable from 'AppCore/Components/DataTable';

export default function ProductsListsListList({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = () => {}
}: any) {

    return <DataTable
        data={data}
        config={{
            columns,
            cells: [
                { title: 'key', key: 'key' },
                { title: 'Titre', key: 'title' }
            ],
            actionsLabel: "",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ],
            canReorder: reorder,
            onOrderChanged
        }}
        getCell={(item: any, attr_name: string) => {

            if (attr_name === 'key') {
                return (
                    <Link to={`/shops-list/${item.key}`}>{item.key}</Link>
                )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}