import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Bar } from 'test-react-chartjs-2';

const GET_SHOP_VIEWS_HISTOGRAM = gql `
  query getShopViewsHistogram($startDate: String, $endDate: String, $uuid: String!) {
    histogram: shopViewsHistogram(startDate: $startDate, endDate: $endDate, uuid: $uuid) {
        total,
        data { count, date }
    }
}
`;

export default function ViewsHistogram({
  startDate,
  endDate,
  uuid,
}) {

  const { 
    data: { histogram = {} } = {}
  } = useQuery( GET_SHOP_VIEWS_HISTOGRAM, {
    variables:{
      startDate,
      endDate,
      uuid
    }
  })

  const datasets = [
    {
      label: 'Views',
      data: histogram?.data?.map(d=>d.count),
      backgroundColor: 'rgb(255, 99, 13)',
      borderColor: 'rgba(255, 99, 132, 0.2)',
    }
  ];

  return (
    <Bar
      height={400}
      key={1}
      data={{
          labels: histogram?.data?.map(d => d.date),
          datasets
      }}
      options={{
          maintainAspectRatio: false,
          interaction: {
              intersect: false
          },
          scales: {
              yAxes: [
                  {
                      ticks: {
                          beginAtZero: true,
                      },
                  },
              ],
          },
      }}
  />
  )

}

