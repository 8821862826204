import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


export default ({ value, onChange, ...props }) => {
    
    const roles = [
        {
            "value":1,
            "description":"user"
        },
        {
            "value":2,
            "description":"brand"
        },
        {
            "value":3,
            "description":"partner"
        },
        {
            "value":4,
            "description":"admin"
        },
    ]

    return (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="role-select-label">Role</InputLabel>
            <Select 
                label="Role"
                labelId="role-select-label"
                value={value || ""} 
                onChange={(e) => {onChange(e.target.value); }} 
                displayEmpty
                autoWidth 
                {...props}>
                {roles.map((role,index) => {
                    return (
                        <MenuItem key={index} value={role.value} >{role.description}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}