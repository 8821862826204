import React from 'react';
import { Box, Grid } from '@material-ui/core';
import GeneralForm from './Components/GeneralSettingsForm';
import LogoUpload from './Components/LogoUpload';
import CoverUpload from './Components/CoverUpload';


export default function GeneralShopSettings() {


  return (
    <>
      <Box
        style={{
            backgroundColor: 'background.default',
            minHeight: '100%',
            paddingTop: '3px',
            paddingBottom: '3px',
        }}
      >
        <Grid container spacing={3} justify={'space-around'} alignItems={'stretch'}>
          <Grid item xs={12} md={4}>
            <LogoUpload />
          </Grid>
          <Grid item xs={12} md={8}>
            <GeneralForm />
          </Grid>
          <Grid item xs={12}>
            <CoverUpload />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}