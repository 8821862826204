import React from "react";
import { gql, useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from "@material-ui/core";
import { Edit } from '@material-ui/icons'

import Table from 'Apps/Admin/Components/WantList';

const GET_ALL_WANTS = gql`
        query getWantByUser($after: String, $first: Int, $user_id: Int) {
            wants(after: $after, first: $first, user_id:$user_id) {
                edges {
                    node{
                        id,
                        title,
                        description,
                        link, link_website,
                        source,
                        product {
                            id, title, market_url, image
                        }
                    }
                  }
            }
        }
    `;

export default () => {

    const navigate = useNavigate();
    const { id: user_id } = useParams();

    const { data: { wants = [] } = {} } = useQuery(GET_ALL_WANTS, {
        variables: {
            first: 100,
            user_id: parseInt(user_id, 10)
        }
    });

    console.log('WANTS', wants?.edges?.map(item => item?.node))

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={wants?.edges?.map(item => item?.node)}
                        columns={['id', 'product', 'title', 'link', 'source']}
                        actions={[
                            { key: 'edit', icon: <Edit />, onClick: id => navigate('/want/'+id) }
                        ]}
                    />
                </Grid>
            </Grid>
        </div>
    );
}