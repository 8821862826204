import React, {useState}  from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";
import Table from 'Apps/Admin/Components/ShopList';
import ShopsSearchForm from "Apps/Admin/Components/SearchForms/Shops";

const CORE_SHOPS_LIST_FIELDS = gql`
fragment CoreShopsListFields on ShopList{
    pageInfo {
        hasNextPage
        endCursor
    }
    edges {
        cursor
        node {
            id,
            uuid,
            name,
            logo,
            description,
            owner {full_name ,email}
        }
    }
}`;


const GET_ALL_SHOPS = gql`
    ${CORE_SHOPS_LIST_FIELDS}
        query getShops($after: String, $first: Int) {
            shops(after: $after, first: $first) {
                ...CoreShopsListFields      
            }
        }
    `;

const SEARCH_SHOPS = gql`
    ${CORE_SHOPS_LIST_FIELDS}
    query searchShops($after: String, $first: Int, $query: String!){
        searchShops(after: $after, first: $first,query: $query){
            ...CoreShopsListFields
        }
    }
`;

const ShopList = ({
    data, fetchMore, columns, actions
}) => {

    if(!data){
        return <p>Waiting for data...</p>
    }
    
    const {
        shops = null,
        searchShops = null,
    } = data;

    const {
        pageInfo,
        edges
    } = shops ? shops : searchShops;

    return (
        <>


            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={edges.map(edge => edge.node)}
                        columns={columns}
                        actions={actions}
                    />
                </Grid>
            </Grid>

            {pageInfo.hasNextPage && 
                <Button onClick={() => {
                    fetchMore({
                        variables: {
                            after: pageInfo.endCursor,
                            first: 100
                        }
                    })
                }}>Load More</Button>
            }

        </>
    )
}

export default ({
    actions = [],
    columns = ['logo','uuid', 'name', 'owner']
}) => {

    const [searchFormData, setSearchForm] = useState({})

    const { 
        loading,
        data,
        fetchMore 
    } = useQuery(searchFormData?.name ? SEARCH_SHOPS : GET_ALL_SHOPS, {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: "cache-first",
        variables: {
            after: "0",
            first: 100,
            ...(searchFormData?.name ? {query: searchFormData?.name} : {})
        },
    });
    

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

            <ShopsSearchForm 
                onChangeSearchQuery={setSearchForm}
                onChangeForm={console.log}
                loading={loading}
                />


            {loading ? <div>Loading...</div> : (
                <ShopList
                    data={data}
                    fetchMore={fetchMore}
                    actions={actions}
                    columns={columns}
                    />
            )}

        </div>
    );
}