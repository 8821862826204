import React from 'react';
import {
    Box,
    Container,
    Grid
} from '@material-ui/core';
import {  Bookmark, History, List, People} from '@material-ui/icons';
import { Package } from "react-feather";


import ViewsHistogram from 'Apps/Admin/Components/ViewsHistogram/Shop'
import CardHistoTemp from 'Apps/Admin/Components/CardDiagram/CardHistoTemp'

import InfoBox from 'Apps/Admin/Layouts/Components/InfoBox';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';
import { UsualDate } from 'AppCore/Utils/Date';
import { useQuery, gql } from '@apollo/client';



const GET_SHOP_DASHBOARD_STAT = gql`
    query getShopDashboardStat($toDay: String, $lastWeek: String, $uuid: String!){
        shop(uuid: $uuid){
            productsCount
            followersCount
            productsListsCount
        }
        weekHisto: wantsShopHistogram(startDate: $lastWeek, endDate: $toDay, shop_uuid: $uuid){
            total,
            data {
                date
                count
            }
        }
        completeHisto: wantsShopHistogram(startDate: "2021-01-09", endDate: $toDay, shop_uuid: $uuid){
            total
        }
    }
`;

  

export default function Dashboard() {

    const {currentShop:{uuid}} = useCurrentShop();
    
    const toDay = UsualDate.toString('toDay')
    const lastWeek = UsualDate.toString('lastWeek')

    const { data } = useQuery(GET_SHOP_DASHBOARD_STAT,{
        variables:{
            toDay,
            lastWeek,
            uuid
        }
    })
    

    return (
        <Box
            style={{
                minHeight: '100%'
            }}
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <InfoBox
                            title="Produits"
                            value={data?.shop?.productsCount || 0}
                            icon={<Package />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <InfoBox
                            title="Listes de produits"
                            value={data?.shop?.productsListsCount || 0}
                            icon={<List />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <InfoBox
                            title="Wants"
                            value={data?.completeHisto?.total || 0}
                            icon={<Bookmark />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <InfoBox
                            title="Followers"
                            value={data?.shop?.followersCount || 0}
                            icon={<People />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <InfoBox
                            title="Wants de la semaine"
                            value={data?.weekHisto?.total || 0}
                            icon={<History />}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CardHistoTemp
                            title="Visites de votre shop par jour">
                                {/* startDate and endDate props mandatory with ViewsHistogram components are handle by CardHistoTemp */}
                                <ViewsHistogram
                                    uuid={uuid}
                                />
                        </CardHistoTemp>
                    </Grid>

                    


                </Grid>
            </Container>
        </Box>
        )
    }