import { useSelector } from 'react-redux';

export const useCurrentUser = () => {
    const currentUser = useSelector(state => state.Auth.current_user);

    // const user = new authConfig.userClass()
    // user.setData(currentUser.userData || {});
    // return user;

    return currentUser;
}