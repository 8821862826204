import React from 'react';
import {
    Box,
    Container,
    Grid
} from '@material-ui/core';
import {  Bookmark } from '@material-ui/icons';

import WantsHistogram from 'Apps/Admin/Components/WantsHistogram';
import CardHistoTemp from 'Apps/Admin/Components/CardDiagram/CardHistoTemp';


import InfoBox from 'Apps/Admin/Layouts/Components/InfoBox';



export default function Wants({product = {}}) {
   

    return (
        <Box
            style={{
                minHeight: '100%'
            }}
        >
            <Container maxWidth={false}>
                <Grid
                    container
                    spacing={3}
                >
                    <Grid item sm={4} xs={12}>
                        <InfoBox
                            title="Wants"
                            value={product?.wantsCount}
                            icon={<Bookmark />}
                        />
                    </Grid>
                    {/*
                    <Grid item sm={8} xs={12}>
                        <WomenMenSplit />
                    </Grid>
                    */}

                    <Grid item xs={12}>
                        <CardHistoTemp
                            title="Wants de votre produits par jour">
                                {/* startDate and endDate props mandatory with WantsHistogram components are handle by CardHistoTemp */}
                                <WantsHistogram
                                    productId={product?.id}
                                />
                        </CardHistoTemp>
                    </Grid>

                
                </Grid>
            </Container>
        </Box>
        )
    }