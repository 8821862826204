import React, {useState} from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { ArrowDropDown } from '@material-ui/icons';
import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import { UsualDate } from 'AppCore/Utils/Date';
import dateformat from 'dateformat';
import {
  FlexRowEnd,
} from 'Apps/Shared/Layouts/FlexBox';
import {  SpacerInline } from 'Apps/Shared/Layouts/Dashboard/Components/Page'




const MenuStyled = styled(Menu)`
  .MuiPaper-root {
      width: 200px;
  }
`

export default function CardHistoTemp({title, children}){

  // Picker part

  const ONE_DAY = 86400000;
  const initialEndDate = new Date();
  const initialStartDate = new Date();
  initialStartDate.setTime(initialEndDate.getTime() - ONE_DAY*30);

  const [ startDateFromPicker, setStartDateFromPicker ] = useState(dateformat(initialStartDate, 'yyyy-mm-dd'));
  const [ endDateFromPicker, setEndDateFromPicker ] = useState(dateformat(initialEndDate, 'yyyy-mm-dd'));
  
  // Drop down menu part

  const toDay = UsualDate.toString('toDay')
  const lastWeek = UsualDate.toString('lastWeek')
  const lastMonth = UsualDate.toString('lastMonth')
  const lastYear = UsualDate.toString('lastYear')

  const WantsDateOptions = [
    {label: "7 derniers jours", startDate: lastWeek, endDate: toDay},
    {label: "30 derniers jours", startDate: lastMonth, endDate: toDay},
    {label: "La derniere année", startDate: lastYear, endDate: toDay},
    {label: "Custom", startDate: startDateFromPicker, endDate: endDateFromPicker}
  ]

  const [dateOptionsSelected, setDateOptionSelected] = React.useState(WantsDateOptions[0]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  
  const label= dateOptionsSelected.label
  const startDate = label === "Custom" ? startDateFromPicker : dateOptionsSelected.startDate
  const endDate = label === "Custom" ? endDateFromPicker : dateOptionsSelected.endDate

  const childWithProps = React.cloneElement(children,{startDate, endDate})

  const handleClickMenu = (event) => {
      setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
      setAnchorEl(null);
  };

  

  return(
  <CardSection
      title={title}
      action={(
        <>
          <Button
              endIcon={<ArrowDropDown />}
              size="small"
              variant="text"
              onClick={handleClickMenu}
          >
              {dateOptionsSelected.label}
          </Button>

          <MenuStyled
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
              transformOrigin={{ vertical: -50, horizontal: "center" }}
          >
              {WantsDateOptions.map(WantsDateOption => (
                  <MenuItem onClick={() => {
                      setDateOptionSelected(WantsDateOption);
                      handleCloseMenu();
                  }}>{WantsDateOption.label}</MenuItem>
              ))}
              
          </MenuStyled>

          {
            label === "Custom" &&
            <FlexRowEnd>
                    <input
                        placeholder="Date de début"
                        type="date"
                        style={{height: 36}}
                        value={startDateFromPicker}
                        onChange={e => {setStartDateFromPicker(e.target.value)}}
                    />
                    <SpacerInline />
                    <input
                        placeholder="Date de fin"
                        type="date"
                        style={{height: 36}}
                        value={endDateFromPicker}
                        max={dateformat(new Date(), 'yyyy-mm-dd')}
                        onChange={e => {setEndDateFromPicker(e.target.value)}}
                    />
                    <SpacerInline />
                </FlexRowEnd>
          }

        </>
      )}
    > 
      {
        childWithProps
      }
    </CardSection>
  )
}