import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import { Title } from "Apps/Admin/Layouts/Components/Page";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ProductsListsListList from "Apps/Admin/Components/ProductsListsListList";

const GET_ALL_PRODUCTSLISTSLISTS = gql`
        query productsListsLists($after: String, $first: Int) {
          productsListsLists(after: $after, first: $first) {
                pageInfo {
                    hasNextPage
                    endCursor
                }
                edges {
                    cursor
                    node {
                      id,
                      key, title,
                      productslist_ids,
                      productsLists {
                        id, title
                      }
                    }
                }
            }
        }
    `;

const DataTable = ({
    data,
    columns,
    actions,
}: {
  data: any,
  columns: any,
  actions : any,
}) => {

    if (!data) {
      return null;
    }
    const {
      productsListsLists
    } = data;

    const {
        edges
    } = productsListsLists;

    return (
      <Grid container>
          <Grid item xs={12}>
              <ProductsListsListList
                data={edges.map((edge: any) => edge.node)}
                columns={columns}
                actions={actions}
              />
          </Grid>
      </Grid>
    )
}

export default function ProductsListsListsPage({
    actions = [],
    columns = ['key', 'title']
}) {

  const navigate = useNavigate();
  const {
      loading,
      data,
  } = useQuery(GET_ALL_PRODUCTSLISTSLISTS, {
      fetchPolicy: 'cache-and-network',
      variables: {
          after: "",
          first: 100
      },
  });

  return (
    <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

      <Helmet title="Listes de produits" />

      <FlexRowSpaceBetween>
          <Title>
            Listes de listes de produits
          </Title>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              navigate('/create-productslists-list')
            }}
          >Créer une nouvelle liste de liste</Button>
      </FlexRowSpaceBetween>

      <Divider />

      <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

          {loading ? <div>Loading...</div> : (
              <DataTable
                  data={data}
                  columns={columns}
                  actions={actions}
              />
          )}
        
      </div>
    </div>
  );
}