import InitStore from './Store';
import InitAmplitude from './config/amplitude'
import { setConfig } from './config'
import { APP_CONFIGS } from './configs'
import getInitFunctions from './initApp'
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr')

export default ({ APP_NAME }) => {


    if (!APP_CONFIGS[APP_NAME]) {
        throw new Error(APP_NAME + ": This app does not exists");
    }

    const APP_CONFIG = APP_CONFIGS[APP_NAME];
    const { amplitudeConfig, auth, reduxPersistConfig, ...AppConfig } = APP_CONFIG;

    if (amplitudeConfig) {
        InitAmplitude(amplitudeConfig)
    }

    const {
        init: initApp, onStoreReady
    } = getInitFunctions(APP_NAME);

    const { storeExtraArguments } = initApp();

    const extraArgument = {
        ...storeExtraArguments
    };

    const { store, persistor } = InitStore({
        extraArgument,
        APP_NAME,
        APP_CONFIG,
        reduxPersistConfig
    });

    onStoreReady({ store });

    setConfig({
        APP_NAME,
        amplitudeConfig,
        ...AppConfig,
        auth
    });

    return {
        AppConfig,
        store: {
            store, persistor
        }
    }
}