
import matcher from 'matcher';
// @ts-ignore
import { constructFirebaseStorageUrl } from '@knxlab/utils';
import type { MediaType } from './Media'
import { loadMedia } from './Media/helpers'
import type { StorageConfType } from './upload'

import { getConfig } from 'AppCore/config'

export const isAspectRatioValid = (media?: { width: number, height: number }, needAspectRatio?: number) => {

    if (!needAspectRatio || !media || !media.width || !media.height) {
        return true;
    }
    let aspectRatio = Math.round((media.width / media.height)*100)/100;
    return aspectRatio === needAspectRatio
}

export const loadFirebaseMedia = async ({ media, fileType, storageConf }: { media: MediaType, fileType: string, storageConf: StorageConfType }) => {

    let fileSrc = null

    if (media && media.ref && !media.src && matcher.isMatch(media.type, fileType)) {
        const { firebaseConfig: { storageBucket } } = getConfig() as any;
        fileSrc = constructFirebaseStorageUrl(storageBucket, media.ref)
    }

    if (fileSrc) {
        const newMedia = await loadMedia(fileSrc, media.type);
        return {
            ...newMedia,
            src: fileSrc,
            type: media.type,
            ref: media.ref,
            width: newMedia.width,
            height: newMedia.height,
        }
    }
}