import React from "react";
import styled from "styled-components";
import { Image as ImageIcon } from '@material-ui/icons'

import DataTable from 'AppCore/Components/DataTable';
import { ShopModel } from "AppCore/Types/Shop";
import { Product } from "AppCore/Types/Product";
import { User } from "AppCore/Types/User";
import Image from "AppCore/Components/Image";

export default function MixedObjectList({
    data = [],
    columns,
    onClickEdit = () => {},
    actions = null,
    reorder = false,
    onOrderChanged = () => {}
}: any) {

  console.log("MMixed List Data", data);

    return <DataTable
        data={data}
        getKey={(el: any) => {
          return el.id;
        }}
        config={{
          columns,
          cells: [
            { title: '', key: 'img' },
            { title: 'Nom', key: 'title' },
            { title: 'Type', key: 'type' }
          ],
          actionsLabel: "",
          actions: actions || [
              { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
          ],
          canReorder: reorder,
          onOrderChanged
        }}
        getCell={(item: ShopModel | Product | User, attr_name: string) => {

          if (attr_name === 'type') {
            if (item instanceof ShopModel) {
              return 'Boutique';
            } else if (item instanceof Product) {
              return 'Produit'
            } else if (item instanceof User) {
              return 'Utilisateur / Influenceur'
            }
            return "";
          }

          if (attr_name === 'title') {
            if (item instanceof ShopModel) {
              return item.name;
            } else if (item instanceof Product) {
              return item.title
            } else if (item instanceof User) {
              return item.full_name
            }
            return "";
          }

          if (attr_name === "img") {

            let url: string | null = item.getImageUrl();
            
            return (
              <ImageContainer>
                {!url &&
                  <ImageIcon fontSize='large' />
                }
                {url &&
                  <Image
                    url={url}
                    style={{ width: '100%' }}
                  />
                }
              </ImageContainer>
            )
        }

          return Object.prototype.hasOwnProperty.call(item, attr_name) ? (item as any)[attr_name] : '';
        }}
    />

}


const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`