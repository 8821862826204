import Constants from "../../Constants";

const { REDUCER: C } = Constants;

export default (state = {}, action) => {
    switch (action.type) {

        case C.SET_AUTH_DATA:
            return {
                ...state,
                access_token: action.authData.access_token,
                userLogged: !!action.authData.access_token
            };
        case C.SET_CURRENT_USER:
            return {
                ...state,
                current_user: action.current_user
            };

        default:
            return state;
    }
}