import React from "react";
import { useNavigate, Link } from 'react-router-dom'
import Helmet from 'react-helmet';
import { Button, Grid, TablePagination } from "@material-ui/core";
import { Edit } from '@material-ui/icons'
import {
    FlexRowSpaceBetween
} from 'Apps/Shared/Layouts/FlexBox'

import Table from 'Apps/Admin/Components/CategoryList';

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'
import { Title } from 'Apps/Admin/Layouts/Components/Page'
import useCategories from "AppCore/react-hooks/useCategories";


function AddLink() {
    return (
        <Button
            color="primary"
            component={Link}
            to="/category-create"
        >
            Créer une catégorie
        </Button>
    )
}

export default () => {

    const navigate = useNavigate();

    const { categories } = useCategories();

    return (
        <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>
            <Helmet title="Categories" />

            <FlexRowSpaceBetween>
                <Title>
                    Categories
                </Title>
                <AddLink />
            </FlexRowSpaceBetween>

            <Divider my={6} />

            <Grid container>
                <Grid item xs={12}>
                    <Table
                        data={categories.map(category => ({...category, names: JSON.parse(category.names)}))}
                        actions={[
                            { key: 'edit', icon: <Edit />, onClick: id => navigate('/categories/'+id) }
                        ]}
                    />
                    <TablePagination
                        rowsPerPageOptions={[10]}
                        rowsPerPage={10}
                        component="div"
                        count={-1}
                        page={0}
                        onChangePage={console.log}
                        onChangeRowsPerPage={console.log}
                        labelDisplayedRows={({ from, to, count }) => `${from}-${to}`}
                    />
                </Grid>
            </Grid>

        </div>
    );
}