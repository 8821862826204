import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';



export default ({ value, onChange, ...props }) => {

    const statusList = ['DRAFT','PUBLISHED','ARCHIVED'];
    const labelList = {
        'DRAFT': 'Brouillon',
        'PUBLISHED' : 'Publié',
        'ARCHIVED' : 'Archivé'
    }
    return (
        <FormControl fullWidth>
            <InputLabel id="status-select-label">Etat</InputLabel>
            <Select
            labelId="status-select-label"
            label="Etat" 
            value={value || ""} 
            onChange={(e) => {
                console.log(e.target.value);
                onChange(e);
            }} displayEmpty {...props}>
                {statusList.map(status => {
                    return (
                        <MenuItem key={`selector-${status}`} value={status}>{labelList[status]}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}