import React from 'react';
import { Grid } from '@material-ui/core';

import CardSection from 'Apps/Admin/Layouts/Components/CardSection';
import Button from 'AppCore/Components/Button';
import { useOpenProductListModal } from 'Apps/Admin/Components/ProductSelector/ModalSelector';
import Table from 'Apps/Admin/Components/ProductList';


const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export default function ProductList({
  productsList,
  update
}: any) {

  const [ changed, setChanged ] = React.useState<boolean>(false);
  const [ products, setProducts ] = React.useState<Array<any>>(productsList.products || []);

  const openProductSelector = useOpenProductListModal();

  const onClickSelectProduct = () => {
    openProductSelector({
      filterIds: products.map(p => p.id),
      onSelectProduct: (product: any, { close }: any) => {
        console.log(product);

        const isInList = (list : any[],item: any) => {
          let alreadyInList = false; 
          list.every(i => {
            if(i?.id === item?.id){
              alreadyInList = true;
              return false;
            }
            return true;
          })
          return alreadyInList
        }
      
        setProducts((products) => {
          if(!isInList(products,product)){
            return [
              product,
              ...products
            ]
          } else {
            return products
          }
        })
        setChanged(true);
        
        if(close !== null){
          close();
        }
      }
    })
  };

  const onClickDelete = (id: number, product: any) => {
    const result = Array.from(products)
    const startIndex = products.findIndex(el => el.id === id);
    if (startIndex < 0) {
      return;
    } 
    result.splice(startIndex, 1)

    setProducts(result);
    setChanged(true);
  }

  const onClickSave = async () => {
    const product_ids = products.map((product: any) => product.id).join(',')
    await update({
      product_ids
    })
    setChanged(false);
  }

  const onOrderChanged = (result: any) => {
    const newProductsOrder = reorder(products, result.source.index, result.destination.index);
    setProducts(newProductsOrder);
    setChanged(true);
  }

  return (
    <CardSection
      title="Liste de produits"
      subheader={"Ajoutez / Supprimez / Reordonnez les produits dans la liste"}
      action={(
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSave}
            disabled={!changed}
            style={{marginRight: '10px'}}
          >Sauvegarder</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onClickSelectProduct}
          >Ajouter un produit</Button>
        </div>
      )}
    >

      <Grid container>
          <Grid item xs={12}>
              <Table
                  data={products}
                  columns={['image', 'id', 'title', 'status']}
                  actions={[
                    { icon_name: 'delete', title: "Supprimer", onClick: onClickDelete }
                  ]}
                  reorder={true}
                  onOrderChanged={onOrderChanged}
              />
          </Grid>
      </Grid>

    </CardSection>
  )
}