import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';


export default ({ label, initialValue = null, options, onChange, ...props }) => {
    return (
        <FormControl fullWidth>
            <InputLabel id={`${label.toLowerCase()}-select-label`}>{label}</InputLabel>
            <Select 
                label={label}
                labelId={`${label.toLowerCase()}-select-label`}
                value={initialValue || ""} 
                onChange={(e) => {onChange(e); }} 
                displayEmpty
                
                {...props}>
                {options.map((option,index) => {
                    return (
                        <MenuItem key={index} value={option.value} >{option.description}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}