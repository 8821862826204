import { combineReducers } from 'redux'

//// COMMON
import Auth from './Auth'
import CurrentShop from './CurrentShop'
import { reducer as DialogReducer} from 'AppCore/Components/Dialog'


export default {
    ADMIN: combineReducers({
        counter: (state = 0, action) => {
            switch (action.type) {
                case 'counter.increment':
                    return state+1;
                case 'counter.decrement':
                    return state-1;
                default:
                    return state;
            }
        },
        dialogs: DialogReducer,

        Auth,

        CurrentShop
    }),

}