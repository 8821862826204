import React  from 'react';

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import { Button } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import View from '../UserList/view'
import { User } from 'AppCore/Types/User';

const DialogList = ({
    filterIds = [],
    onClose,
    onSelect = () => {},
    searchFormData = {}
}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Listes d'utilisateurs</Typography>
            </DialogTitle>
            <DialogContent>

                <View
                    searchFormData={searchFormData}
                    filterIds={filterIds}
                    actions={[
											element => {
													const user = new User();
													user.setRawData(element);
													return (
															<Button key="select" onClick={() => onSelect(user, { close: onClose })}>Selectionner</Button>
													)
											}
                    ]}
                    columns={[ 'avatar', 'full_name', 'email' ]}
                />

            </DialogContent>
        </Dialog>
    );
}

export const useOpenUsersListModal = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({
        onSelect = (shop, props) => {},
        filterIds = []
    }) => openDialogComponent(
        ({ close }) => (
            <DialogList
							onSelect={onSelect}
							filterIds={filterIds}
              onClose={close}
            />
        )
    )
}