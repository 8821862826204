import React from 'react';
import { gql, useMutation } from '@apollo/client';

import View from './index'
import { useNavigate } from 'react-router-dom';
import { useCurrentShop } from 'AppCore/react-hooks/useCurrentShop';

const CREATE_MUTATION = gql`
  mutation CreateProductsList($shop_uuid: String!, $productsList: ProductsListCreate!) {
    createShopProductsList(shop_uuid: $shop_uuid, productsList: $productsList) {
      id,
      uuid, title, image,
      position_in_shop
    }
  }
`;

export default function CreateProductsListPage() {

  const navigate = useNavigate();
  const { currentShop } = useCurrentShop();
  const [createShopProductsList] = useMutation(CREATE_MUTATION);

  return (
    <View
      layout={'create'}
      productsList={{}}
      update={async (data: any) => {
        console.log("data", data);
        await createShopProductsList({
          variables: {
            shop_uuid: currentShop.uuid,
            productsList: data
          }
        });

        navigate('/products-list');
      }}
    />
  )
}