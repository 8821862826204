import { setHostApi, setUserAgent as ApiSetUserAgent, setOnAccessTokenExpiredCallback } from 'Meth/api/request';
import { setAccessTokenGetter } from 'Meth/api/request'
import api from 'AppCore/Api';
import { init as initGraphQlApi, setAccessTokenGetter as setAccessTokenGetterGraphQl } from 'AppCore/Api/GraphQL'
import { logout } from 'AppCore/Actions';
import { getStore } from 'AppCore/Store'
import settings from 'AppCore/settings';

function setUserAgent() {
	const user_agent = "Iwantit/"+settings.app_version+" (iwantit; x.x; Mobile; en)";
	ApiSetUserAgent(user_agent)
}

// Before store ready
export const init = () => {

	setUserAgent();
	setHostApi(settings.default_api_url);
	initGraphQlApi({
		uri: settings.graphql_api_uri
	})

	return {
			storeExtraArguments: { api }
	}
}

let access_token = null;
export const onStoreReady = ({ store }) => {

	const accessTokenGetter = () => {
		const { Auth : { access_token } } = store.getState();
		return access_token || null;
	}

  setAccessTokenGetter(accessTokenGetter);
	setAccessTokenGetterGraphQl(accessTokenGetter)

	const { Auth : { access_token: store_access_token } } = store.getState();
	access_token = store_access_token;

	store.subscribe(() => {
		const { Auth : { access_token: store_access_token } } = store.getState();
		if (access_token !== store_access_token) {
			access_token = store_access_token;
		}
	});

	setOnAccessTokenExpiredCallback(() => {
		const { store } = getStore();
		store.dispatch(logout());
	})
}