import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import CircularProgress from '@material-ui/core/CircularProgress'

import Style from './style.module.css';

import { isVideo, isImage } from './helpers'

import type { MediaToCropType } from './CropMedia'
export type MediaType = {
	src?: string,
	imageFileName?: string,
	ref?: string,
	name?: string,
	width?: number,
	height?: number,
	type: string,

	local?: boolean,
	payload?: any
}
export type MediaTypeStrict = {
	src: string,
	name: string,
	width: number,
	height: number,
	type: string
}

type UploadedImageProps = {
	loading?: boolean,
	visibleArea?: any,
	media: MediaType
}

export const clone = (media: MediaType | MediaToCropType | any = {}): MediaType => {
	const newMedia: MediaType = {
		src: media.src,
		type: media.type,
		width: media.width,
		height: media.height,
	};

	if (media.local) {
		newMedia.local = !!media.local;
	}

	if (media.name && typeof(media.name) === 'string') {
		newMedia.name = media.name;
	}

	return newMedia;
}

export default ({ media, visibleArea, loading = false }: UploadedImageProps) => {

	const mediaRef = useRef<any>(null);
	const [ overlays, setOverlays ] = useState([]);
	const [ mediaLoaded, setMediaLoaded ] = useState(0);

	const style = media.width && media.height ? {
		...(media.width > media.height ? { maxWidth: '100%', maxHeight: '100%' } : { maxWidth: '100%', maxHeight: '100%' })
	} : {};

	const recalculateOverlays = () => {
		if (mediaRef.current === null) {
			return;
		}

		const mediaHeight = mediaRef.current.offsetHeight;
		const mediaWidth = mediaRef.current.offsetWidth;
		const mediaLeft = mediaRef.current.offsetLeft;
		const mediaTop = mediaRef.current.offsetTop;

		if (!visibleArea) {
			return;
		}

		const Areas = [
			{
				top: (mediaTop + 0) + "px", left: (mediaLeft + 0) + "px",
				width: (visibleArea.x*mediaWidth/100) + "px",
				height: mediaHeight + "px"
			},

			// TOP MIDDLE
			{
				top: (mediaTop + 0) + "px",
				left: mediaLeft + (visibleArea.x*mediaWidth/100) + "px",
				width: (visibleArea.width * mediaWidth / 100) + "px",
				height: (visibleArea.y*mediaHeight/100) + "px"
			},

			// BOTTOM MIDDLE
			{
				top: (mediaTop + ((visibleArea.y + visibleArea.height)*mediaHeight/100)) + "px",
				left: mediaLeft + (visibleArea.x*mediaWidth/100) + "px",
				width: (visibleArea.width * mediaWidth / 100) + "px",
				bottom:  "0px"
			},

			{
				top: (mediaTop + 0) + "px",
				right: (mediaLeft) + "px",
				width: ((100 - visibleArea.x - visibleArea.width)*mediaWidth/100) + "px",
				height: mediaHeight + "px"
			},
		]

		// @ts-ignore
		setOverlays(Areas);
	}

	useEffect(() => {
		recalculateOverlays();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ mediaLoaded, visibleArea ])

	return (
		<div
			className={classNames(Style.imgContainer, {[Style.blur]: loading})}
		>
			{overlays.map((overlay, index) => (
				// @ts-ignore
				<div key={index} style={{...overlay, backgroundColor: 'rgba(0, 0, 0, 0.8)', position: 'absolute'}} />
			))}
			{loading &&
				<CircularProgress style={{ marginLeft: "50%", left: "-20px", position: "absolute" }}/>
			}
			{isImage(media.type) && <img
				ref={mediaRef}
				id="image_" alt="uploaded"
				style={style}
				src={media.src}
				onLoadedData={() => setMediaLoaded(mediaLoaded+1)}
			/>}

			{isVideo(media.type) &&
				<video
					ref={mediaRef}
					autoPlay={!loading}
					controls={!loading}
				onLoadedData={() => setMediaLoaded(mediaLoaded+1)}
					loop
					muted
					// @ts-ignore
					alt="uploaded"
					style={style}
					src={media.src}
				/>
			}
		</div>

	)
}