import React from 'react';

import classNames from 'classnames';
import moment from 'moment';

import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import MenuItem from '@material-ui/core/MenuItem';

import * as transformers from './transformers';
import { InputAdornment } from '@material-ui/core';


const applyTransformers = (transformersList, _value, reverseTransform = false) => {
    let value = _value;

    (transformersList || []).forEach(
        _transformer => {

            let _transformer_name = "";
            let _transformer_arg = null;
            if (typeof(_transformer) === 'object') {
                _transformer_name = _transformer.name;
                _transformer_arg = _transformer.value;
            } else {
                _transformer_name = _transformer;
            }

            let transformer_name = _transformer_name;
            if (!transformers['transform_' + transformer_name]) {
                return;
            }

            if (reverseTransform &&
                (!transformers['transform_' + transformer_name].reverse_name || !transformers['transform_' + transformers['transform_' + transformer_name].reverse_name])
            ) {
                return;
            }

            let transformerFct = transformers['transform_' + transformer_name];
            if (reverseTransform) {
                if (!transformers['transform_' + transformer_name].reverse_name) {
                    return;
                }
                const reverse_name = transformers['transform_' + transformer_name].reverse_name;
                if (!transformers['transform_' + reverse_name]) {
                    return;
                }

                transformer_name = reverse_name;
                transformerFct = transformers['transform_' + reverse_name];
            }

            let suffix = null;
            if (transformer_name === 'float' || transformerFct.isFloat) {
                if (value[value.length-1] === ".") {
                    suffix = ".";
                    value = value.substr(0, value.length - 1);
                }
            }
            value = transformerFct(value, _transformer_arg);

            if (suffix !== null) {
                value = value + suffix;
            }
        }
    )

    return value;
}

export default class InputField extends React.PureComponent {

    _onChange = e => {

        const { onChange = () => {} } = this.props;

        let value;
        switch(this.props.type) {
            case 'timestamp':
                value = moment(e.target.value, "YYYY-MM-DD[T]HH:mm");
                value = value.toDate().getTime();
                break;
            case 'number':
                value = parseInt(e.target.value, 10)
                if (!value) {
                    value = 0;
                }
                break;
            case 'switch':
                value = e.target.checked;
                break;
            default:
                value = e.target.value;
                break;
        }

        if (this.props.transformers && this.props.transformers.length && value) {
            value = applyTransformers(this.props.transformers, value);
        }

        if (this.props.onsave_transformers && this.props.onsave_transformers.length && value) {
            value = applyTransformers(this.props.onsave_transformers, value)
        }

        onChange(
            value
        );
    }

    _onFocus = e => {
        const { type, selectOnFocus = false } = this.props;

        if (type !== 'select' && selectOnFocus) {
            e.target.select()
        }
    }

    _onBlur = e => {
        const { value, onBlur = () => {}, transformers : _transformers = [], onChange = () => {} } = this.props;

        if (_transformers.indexOf("float") !== -1) {
            onChange(transformers['transform_float'](value));
        }

        return onBlur(e);
    }

    render = () => {

        const {
            type, InputProps: _InputProps = {}, value, defaultValue = "", style = {}, size, className
        } = this.props;
        const {
            onsave_transformers = [], helperText, hasError, error, classes = {}, disabled, label, placeholder, multiline, values = [],
            endAdornment, startAdornment
        } = this.props;


        const endAdornmentRendered = endAdornment ? (
            <InputAdornment position="end">
                {endAdornment}
            </InputAdornment>
        ) : null

        const startAdornmentRendered = startAdornment ? (
            <InputAdornment position="start" style={{position: 'relative', top: 0}}>
                {startAdornment}
            </InputAdornment>
        ) : null

        const InputProps = {
            ..._InputProps,
            endAdornment: endAdornmentRendered,
            startAdornment: startAdornmentRendered
        }


        if (type === 'timestamp') {

            const controlledValue = value ? moment(value).format("YYYY-MM-DD[T]HH:mm") : ''

            return (
                <TextField
                    helperText={label}
                    variant="outlined"
                    type="datetime-local"
                    value={controlledValue}
                    className={classNames(classes.textField, className)}
                    onBlur={this._onBlur}
                    onFocus={this._onFocus}
                    onChange={this._onChange}
                    size={size}

                    style={style}
                    InputProps={{
                        ...InputProps
                    }}
                />
            );
        }

        if (type === 'switch') {

            const controlledValue = Boolean(value)

            return (
                <FormGroup row style={style} className={className}>
                    <FormControlLabel
                        className={classes.formLabel}
                        control={
                            <Switch
                                checked={controlledValue}
                                onChange={this._onChange}
                                value={value}
                                inputProps={{
                                    'aria-label': 'secondary checkbox'
                                }}
                                disabled={disabled}
                            />
                        }
                        label={label}
                    />
                </FormGroup>

            )
        }

        if (type === 'select') {

            const controlledValue = value || defaultValue || "";

            return (
                <FormControl style={style} className={className}>
                    {label &&
                        <InputLabel shrink style={{left:10, top: 8, zIndex: 2}}>
                            {label}
                        </InputLabel>
                    }
                    <TextField
                        select
                        size={size}
                        disabled={disabled}
                        error={hasError}
                        placeholder={placeholder}
                        value={controlledValue}
                        className={classNames(classes.textField)}
                        onBlur={this._onBlur}
                        onFocus={this._onFocus}
                        onChange={this._onChange}
                        variant="outlined"
                        InputProps={{
                            ...InputProps,
                            style: {
                                ...(label ? {
                                    paddingTop: '20px'
                                } : {})
                            }
                        }}
                    >
                    {values.map( ({ value, label }) => (
                        <MenuItem key={value} value={value}>
                            {label}
                        </MenuItem>
                    ))}
                    </TextField>
                </FormControl>
            )
        }

        let controlledValue = value;

        if (onsave_transformers && onsave_transformers.length && controlledValue) {
            controlledValue = applyTransformers(onsave_transformers, controlledValue, true)
        }

        return (
            <FormControl style={style} className={className}>
                {label &&
                    <InputLabel shrink style={{left:10, top: 8, zIndex: 2}}>
                        {label}
                    </InputLabel>
                }
                <TextField
                    type={type}
                    size={size}
                    variant="outlined"
                    disabled={disabled}
                    error={error || hasError}
                    helperText={helperText}
                    placeholder={placeholder}
                    value={controlledValue}
                    className={classNames(classes.textField)}
                    multiline={multiline !== false}
                    rows={multiline !== false ? multiline : 1}
                    onBlur={this._onBlur}
                    onFocus={this._onFocus}

                    onChange={this._onChange}
                    style={style}
                    InputProps={{
                        ...InputProps,
                        style: {
                            ...(label ? {
                                paddingTop: multiline ? '30px' : '10px'
                            } : {})
                        }
                    }}
                />
            </FormControl>
        )

    }

}