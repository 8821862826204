import React, { useState } from 'react';
import Api from 'AppCore/Api';
import Axios from "axios";

import styled from 'styled-components';
import { Divider as MuiDivider } from '@material-ui/core';

import MediaUploader from 'AppCore/Components/MediaUploader/v2'

const Container = styled.div`
    padding: 10px;
`
const Divider = styled(MuiDivider)`
    margin-top: 30px;
    margin-bottom: 30px;
`


export default function UploadMediaExperiment() {

    const [ file, setFile ] = useState();
    const [ cover, setCover ] = useState();

    return (
        <Container>

            <MediaUploader

                onChangeMedia={setCover}
                media={cover}

                title="Uploadez votre cover 16/9"
                infos={"Uploadez votre logo"}
                btnLabel={"Selectionnez une photo"}
                style={{ width: "250px", height: "300px" }}
                fileType={"image/jpeg"}
                canCrop={true}
                needAspectRatio={16/9}
                onError={e => {
                    switch (e.type) {
                        case 'file_type':
                            alert("Votre fichier doit etre une image");
                            break;
                        default:
                            alert("Une erreur est survenue :  " + e.type);
                            break;
                    }
                }}

                onClickDelete={() => {
                    console.log("?????");
                    setCover(null);
                }}

                uploadMethod={async ({ file, filetype, onProgress }) => {
                    onProgress(10);
                    // const fileResult = await Api.upload({ file });

                    const bodyFormData = new FormData();
                    bodyFormData.append('uploadfile', file);

                    try {
                        const rs = await Axios({
                            headers: {
                                "Content-Type": "multipart/form-data",
                            },
                            method: "POST",
                            data: bodyFormData,
                            url: "/upload", // route name
                            baseURL: "https://api.i-wantit-dev.com",
                            onUploadProgress: progress => {
                                const { total, loaded } = progress;
                                const totalSizeInMB = total / 1000000;
                                const loadedSizeInMB = loaded / 1000000;
                                const uploadPercentage = (loadedSizeInMB / totalSizeInMB) * 100;

                                onProgress(uploadPercentage);
                                console.log("UPLOAD PERCENT", uploadPercentage);
                                console.log("total size in MB ==> ", totalSizeInMB);
                                console.log("uploaded size in MB ==> ", loadedSizeInMB);
                            }
                        });

                        console.log("rsrsrs", rs);

                        const { filename } = rs.data

                        onProgress(100);

                        console.log("file", file);

                        return {
                            downloadURL: "https://api.i-wantit-dev.com/image/" + filename
                        }
                    } catch(e) {
                        console.log(e);
                        console.log(e.response.data)
                        alert(e.response?.data?.error || "Une erreur inconnue est survenue");
                        throw e;
                    }
                }}
            />

            <MediaUploader

                onChangeMedia={setFile}
                media={file}

                title="Uploadez votre logo"
                infos={"Uploadez votre logo"}
                btnLabel={"Selectionnez une photo"}
                style={{ width: "250px", height: "300px" }}
                fileType={"image/jpeg"}
                canCrop={true}
                needAspectRatio={1}
                onError={e => {
                    switch (e.type) {
                        case 'file_type':
                            alert("Votre video doit etre une image");
                            break;
                        default:
                            alert("Une erreur est survenue :  " + e.type);
                            break;
                    }
                }}

                uploadMethod={async ({ file, filetype, onProgress }) => {
                    onProgress(10);
                    const fileResult = await Api.upload({ file });
                    onProgress(100);

                    return fileResult.path;
                }}
            />

            <Divider />

            {file &&
            <div>
                <div>Type: {file.type}</div>
                <div>Width: {file.width}</div>
                <div>Height: {file.height}</div>
                {!file.local && <div>SRC: {file.src}</div>}
            </div>
            }

        </Container>
    )
}