import React from "react";
import { Link } from 'react-router-dom'
import { Image as ImageIcon } from '@material-ui/icons'

import DataTable from 'AppCore/Components/DataTable';
import Image from "AppCore/Components/Image";

import styled from "styled-components";
const ImageContainer = styled.div`
    height: 80px;
    width: 80px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    border-radius: 8px;

    > img {
        width: 100%;
        height: auto;
        border-radius: 8px;
    }
`

export default ({
    data = [],
    onClickEdit = () => {},
    actions = null
}) => {

    return <DataTable
        data={data}
        config={{
            cells: [
                { title: '', key: 'image', width: 90 },
                { title: 'id', key: 'id' },
                { title: 'Email', key: 'email' },
                { title: 'Full Name', key: 'full_name' }
            ],
            actionsLabel: "Modifier/Supprimer",
            actions: actions || [
                { icon_name: 'edit', title: "Edit", onClick: onClickEdit }
            ]
        }}
        getCell={(item, attr_name) => {

            if (attr_name === 'id') {
                return (
                    <Link to={`/user/${item.id}`}>{item.id}</Link>
                )
            }

            if (attr_name === "image") {
                return (
                  <ImageContainer>
                    {!item.avatar &&
                      <ImageIcon fontSize='large' />
                    }
                    {item.avatar &&
                      <Image
                        url={item.avatar}
                        style={{ width: '100%' }}
                      />
                    }
                  </ImageContainer>
                )
            }

            return Object.prototype.hasOwnProperty.call(item, attr_name) ? item[attr_name] : '';
        }}
    />

}