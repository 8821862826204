import { ModelObject } from "./ModelObject"

export class User extends ModelObject {

  getPrimaryKey() {
    return this.id;
  }

  getImageUrl(): string | null {
    if (this.avatar) {
      return this.avatar
    }
    return null;
  }

  id?: number

  full_name?: string

  avatar?: string
  
}