import React from "react";
import { gql, useQuery } from '@apollo/client';
import { Button, Grid } from "@material-ui/core";

import { Divider } from 'Apps/Shared/Layouts/Dashboard/Components/Page'

import { FlexRowSpaceBetween } from "Apps/Shared/Layouts/FlexBox";
import { Title } from "Apps/Admin/Layouts/Components/Page";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ShopsListsList from "Apps/Admin/Components/ShopsListsList";

const GET_ALL = gql`
  query shopsLists($after: String, $first: Int) {
    shopsLists(after: $after, first: $first) {
          pageInfo {
              hasNextPage
              endCursor
          }
          edges {
              cursor
              node {
                id,
                key, title,
                shop_ids,
                shops {
                  id, uuid, name
                }
              }
          }
      }
  }
`;

const DataTable = ({
    data,
    columns,
    actions,
}: {
  data: any,
  columns: any,
  actions : any,
}) => {

    if (!data) {
      return null;
    }
    const {
      shopsLists
    } = data;

    const {
        edges
    } = shopsLists;

    return (
      <Grid container>
          <Grid item xs={12}>
              <ShopsListsList
                data={edges.map((edge: any) => edge.node)}
                columns={columns}
                actions={actions}
              />
          </Grid>
      </Grid>
    )
}

export default function ShopsListsPage({
    actions = [],
    columns = ['key', 'title']
}) {

  const navigate = useNavigate();
  const {
      loading,
      data,
  } = useQuery(GET_ALL, {
      fetchPolicy: 'cache-and-network',
      variables: {
          after: "",
          first: 100
      },
  });

  return (
    <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

      <Helmet title="Listes de boutiques" />

      <FlexRowSpaceBetween>
          <Title>
            Listes de boutiques
          </Title>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={() => {
              navigate('/shops-list-create')
            }}
          >Créer une nouvelle liste</Button>
      </FlexRowSpaceBetween>

      <Divider />

      <div style={{width: '100%', maxHeight: '100%', overflow: 'auto'}}>

          {loading ? <div>Loading...</div> : (
              <DataTable
                  data={data}
                  columns={columns}
                  actions={actions}
              />
          )}
        
      </div>
    </div>
  );
}