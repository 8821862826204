import React from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router';
import ActivityIndicator from 'AppCore/Components/ActivityIndicator';
import DataView from './index';


const GET_QUERY = gql`
    query getShopsList($key: String!) {
      shopsList(key: $key) {
        id, key, title,
        shop_ids,
        shops {
          id, uuid, name, logo
        }
      }
    }
`;

const UPDATE_MUTATION = gql`
  mutation UpdateShopsList($key: String!, $shopsList: ShopsListUpdate!) {
    updateShopsList(key: $key, shopsList: $shopsList) {
      id, key, title, shop_ids, shops { id, uuid, name, logo }
    }
  }
`;

export default function UpdateShopsListPage() {
  const { key } = useParams();
  
  const {
    loading,
    data: { shopsList: data = {} } = {}
  } = useQuery(GET_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      key
    },
  });
  const [ updateShopsList ] = useMutation(UPDATE_MUTATION);
  
  if (loading) {
    return <ActivityIndicator material />;
  }

  return (
    <DataView
      shopsList={data}
      update={async (data) => {
          await updateShopsList({
              variables: {
                  key,
                  shopsList: data
              }
          })
      }}
    />
)
}