import React  from 'react';

import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { useOpenDialogComponent } from 'AppCore/Components/Dialog'
import FpsSectionEditForm from '../../FpsSection/EditForm';
import { FpsSection } from 'AppCore/Types/FpsSection';

const ModalEditFpsSection = ({
    onClose,
    onSaved,
    fpsPageKey,
    fpsSection
}: {
  onClose: any,
  onSaved: any,
  fpsPageKey: string,
  fpsSection?: FpsSection
}) => {

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            open
            fullScreen={fullScreen}
            fullWidth={true}
            maxWidth={"md"}
            onClose={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle disableTypography>
                <Typography variant="h2">Nouvelle section</Typography>
            </DialogTitle>
            <DialogContent>

              <FpsSectionEditForm
                fpsPageKey={fpsPageKey}
                fpsSection={fpsSection}
                onSaved={onSaved}
              />

            </DialogContent>
        </Dialog>
    );
}

export const useOpenModalEditFpsSection = () => {
    const openDialogComponent = useOpenDialogComponent();
    return ({
      fpsPageKey,
      fpsSection,
      onSaved = () => {}
    }: {
      fpsPageKey: string,
      fpsSection?: FpsSection,
      onSaved: (e: any) => any
    }) => openDialogComponent(
        ({ close }: any) => (
            <ModalEditFpsSection
              fpsPageKey={fpsPageKey}
              fpsSection={fpsSection}
              onClose={close}
              onSaved={(fpsSection: any) => {
                onSaved({
                  fpsSection,
                  close
                })
              }}
            />
        )
    )
}